import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { selectUserInfo } from '../slices/authSlice';
import Loader from './Loader';
import { FaRegTimesCircle } from 'react-icons/fa'
import styles from './transfersColumn.module.css'

const TransferPortfolioAcceptComponenet = () => {
    const location = useLocation()
    const { id } = useParams();
    const { data } = location.state || {};
    const [portfolios, setPortfolios] = useState([]);
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
    const [loading, setLoading] = useState(true)
    const userInfo = useSelector(selectUserInfo)
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)

    const [transferAmount, setTransferAmount] = useState('')
  const navigate = useNavigate()

    // console.log(data)



    useEffect(()=>{

      const fetchPortfolios =async()=>{
   
   
       var myHeaders = new Headers();
       myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
   
       var requestOptions = {
           method: 'GET',
           headers: myHeaders,
           redirect: 'follow'
         };
         
         fetch(`${API_ENDPOINT}/api/portfolios`, requestOptions)
           .then(response => response.json())
           .then(result =>{
            //  console.log(result.portfolios)
             setPortfolios(result.portfolios)
   
          
            setLoading(false)
           })
           .catch(error => console.log('error', error));
   
      }
   
      fetchPortfolios()
     }, [])



     useEffect(()=>{

      const fetchEachTransferById=async()=>{

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
    
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          
          fetch(`${API_ENDPOINT}/api/transfers/get-transfer/${id}`, requestOptions)
            .then(response => response.json())
            .then(result =>{
              // console.log(result)
              // setPortfolios(result.portfolios)
              if(result.message === 'Transfer Get Successful'){
                setTransferAmount(result.transfer.amount)
              }
             
           
             setLoading(false)
            })
            .catch(error => console.log('error', error));


      }

      fetchEachTransferById()
     },[])

     const transferedPortfolio = portfolios.filter(portfolio=> portfolio._id == data.portfolioID)

    //  console.log(transferedPortfolio)




     const clickAccept=async()=>{


      if(+transferAmount > +userInfo.user.mainWallet){
        setPopUp(`Insufficient fund, Fund your main wallet to continue`)
        setShowPopupModal(true)
        return
      }

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
  
      var requestOptions = {
          method: 'PATCH',
          headers: myHeaders,
          redirect: 'follow'
        };

        setLoading(true)
        
        fetch(`${API_ENDPOINT}/api/transfers/accept-transfer-portfolio-account/${id}`, requestOptions)
          .then(response => response.json())
          .then(result =>{
            // console.log(result)
            // setPortfolios(result.portfolios)
            setPopUp(`Portfolio Transfer Completed Successfully`)
            setShowPopupModal(true)
         
           setLoading(false)
          })
          .catch(error => console.log('error', error));

     }

     const declinePortfolioTransfer=async()=>{


      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
  
      var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
          redirect: 'follow'
        };
        setLoading(true)
        
        fetch(`${API_ENDPOINT}/api/transfers/transfer-portfolio/${id}`, requestOptions)
          .then(response => response.json())
          .then(result =>{
            // console.log(result)
            // setPortfolios(result.portfolios)
            setPopUp(`Transfer Declined Successfully`)
            setShowPopupModal(true)

            setTimeout(()=> navigate('/dashboard'),3000)
         
           setLoading(false)
          })
          .catch(error => console.log('error', error));


     }

     const handleClosePopup =()=>{
      setShowPopupModal(false)
       setPopUp('')
      }
    

  return (
    <div style={{width:'80%', margin:'70px auto'}} className='bg-green-100 p-2'>
      {loading ? <Loader /> : null}

         {/* popup */}
    { showPopupModal && 
    <div className={styles.popContainer}>
    
    <div className={styles.innerPopContainer}>
    < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
     <p className={styles.popText}>{popUp}</p>
    <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
    </div>
    
    
    </div>
    }
    {/* popup ends */}

      <div>
        {transferedPortfolio.map(portfolio=>(
                        <div key={portfolio._id} className='bg-gray-100 p-2'>

                            <h1 className='text-black text-xl font-bold text-center m-2'>Portfolio details</h1>
                            <h1 className='text-black flex items-center justify-between'> <strong className='flex-1 text-sm'>Portfolio Amount:</strong> <span className='text-lg flex-1 bg-green-300 p-2 mb-1'> ${portfolio.amount}</span></h1>
                            <p className='text-black flex items-center justify-between'> <strong className='flex-1 text-sm'>Portfolio Current Value: </strong> <span className='text-lg flex-1 bg-green-300 p-2 mb-1' >${Math.round(portfolio.balance)}</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio Creation Date:</strong>  <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>{new Date(portfolio.createdAt).toLocaleDateString()} Valid for 9 MONTHS</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio Type:</strong>  <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>{portfolio.type}</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio ROI:</strong>  <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>{portfolio.percentage}</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio Daily Profit:</strong> <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>${Math.round((portfolio.percentage * portfolio.amount)/100)} / DAY</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Amount for portfolio Purchase:</strong> <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>${data.amount}</span></p>

                      <div className='flex items-center gap-2 justify-center m-4'>
                        <button onClick={clickAccept} className='bg-green-900 p-2 text-white hover:bg-green-700'>Accept</button>
                        <button onClick={declinePortfolioTransfer} className='bg-red-700 p-2 text-white hover:bg-red-500'>Decline</button>
                      </div>
                        </div>
                    ))}
      </div>
    </div>
  )
}

export default TransferPortfolioAcceptComponenet
