import React, { useEffect } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player';
import goodSRC from '../assets/goodAnimation.json'
import styles from './transfersColumn.module.css'
import purchaseImage from '../assets/purchasePortfolio.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { selectPortfolioDetails } from '../slices/PortfolioSlice';
import { selectUserInfo} from '../slices/authSlice';

const PaymentSuccessComponent = () => {
    const { data } = useParams();
    console.log('success data from params data:', data)
 
    const location = useLocation();

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
    const userInfo = useSelector(selectUserInfo)

  const portfolioDetails = useSelector(selectPortfolioDetails)

  console.log('Payment success:', portfolioDetails)
  
    
    useEffect(()=>{
// create portfolio on pageload

 if(portfolioDetails){

          const createPortfolio = async()=>{

            var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);


    var formdata = new FormData();
    formdata.append("amount", portfolioDetails.amount);
    formdata.append("duration", 9);
    formdata.append("type", portfolioDetails.selectedOption);
    formdata.append("dailyProfit", portfolioDetails.dailyProfit);
    formdata.append("compoundingProfit", portfolioDetails.compoundingProfit);
    formdata.append("percentage", portfolioDetails.selectedOption === 'compounding' ? portfolioDetails.compoundingProfit : portfolioDetails.dailyProfit);


    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${API_ENDPOINT}/api/portfolios/create`, requestOptions)
      .then(response => response.json())
      .then(result => {
      
     
        console.log(result)
      })
      .catch(error => console.log('error', error));

  }

  createPortfolio()

 }

 

    },[])


  return (
    <div className={styles.transferImagesContainer}>

    <img
            src={purchaseImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
    
            />
  <div className={styles.transferContainer}>
  
    <Player
            src={goodSRC}
            className="player"
            loop
            autoplay
             style={{ height: '100px', width: '100px' }}
            
            />
            <h1 className='text-3xl font-light text-white text-center'>Congratulations!</h1>
            <h1 className='text-3xl font-light text-white text-center'>Payment is Successful and wallet has been created</h1>
            <div style={{textAlign:'center', margin:'20px auto'}}>
            <Link className='bg-sky-100 py-2 px-4 rounded-lg font-bold hover:text-white hover:bg-sky-700 text-center' to='/dashboard'>Dashboard</Link>
            </div>
           
            {/* <img 
            src={logo}
            alt='logo image'
            style={{ height: '100px', width: '100px' }}
            /> */}
        </div>
        </div>
  )
}

export default PaymentSuccessComponent
