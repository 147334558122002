import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import AllWallets from '../components/AllWallets'
import Sidebar from '../components/sidebar/Sidebar'


const Wallets = () => {
  return (
    <div className='portfolio-container'>
          <Sidebar />
    <div className="portfolio-innercontainer">
    
    <DashboardNavbar />
      <AllWallets />
 
    </div>
    </div>
  )
}

export default Wallets
