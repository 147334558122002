import React from 'react'
import NavbarDashboard from '../components/navbarDashboard/NavbarDashboard'
import Sidebar from '../components/sidebar/Sidebar'

import './dashboard.css'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import DashboardHome from '../components/DashboardHome'
import Footer from '../components/footer/Footer'

const Dashboard = () => {
  return (
    <div className='dashboard-container'>

<Sidebar />
<div className="dashboard-innercontainer">

<DashboardNavbar />

    <DashboardHome />
    {/* <Footer /> */}
  </div>
     
    
    </div>
  )
}

export default Dashboard
