import React, { useState } from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import styles from './purchasePortfolioRates.module.css'
import moment from 'moment';
import {useLocation, useNavigate} from 'react-router-dom'
import { IoArrowUndo } from "react-icons/io5";
import ReInvestAmountComponent from '../components/ReInvestAmountComponent';
import Sidebar from '../components/sidebar/Sidebar';


const ReInvestAmount = () => {
  const location = useLocation();
  const {state} = location;
  const navigate = useNavigate()

  const [selectedOption, setSelectedOption] = useState('compounding');



  
 
  return (
    <div className={styles.portfolioContainer}>
    <Sidebar />
  <div className={styles.portfolioInnercontainer}>

   <DashboardNavbar />
      <div className='m-8'>
      <IoArrowUndo onClick={()=>navigate(-1)} className={styles.backButton} />
      <ReInvestAmountComponent />
      </div>
     
    </div>
    </div>
  )
}

export default ReInvestAmount
