import React from 'react'
import './whoWeAreDetails.css'
import corporate1 from '../../assets/corporate1.png'
import { useTranslation } from 'react-i18next'

const WhoWeAreDetails = () => {
  const {t} = useTranslation()

  return (
    <div className='who-we-are-details-container'>
        <div className="wwa-inner-details">
            <p>{t('whoWeArePageParagraphOne')}</p>
            <img src={corporate1} alt="who we are" />
        </div>
    </div>
  )
}

export default WhoWeAreDetails
