
import React, { useContext, useEffect, useState } from 'react'
import profileImage from '../assets/profileImage.png'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'

const ProfileSummary = () => {
  const userInfo = useSelector(selectUserInfo)

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

  const [userFetchedDetails, setUserFetchedDetails] = useState({})

  useEffect(()=>{

    const fetchUser = async()=>{

              var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${API_ENDPOINT}/api/users/currentUser`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setUserFetchedDetails(result)
          // dispatch({type:"USER_SAVED", payload: result});
         
          // console.log(result)
        })
        .catch(error => console.log('error', error));

    }

    fetchUser()
  },[])

  return (
    <div style={{height:'200px', width:'350px'}} className='p-5 bg-green-200 flex gap-5 backdrop-filter backdrop-blur-lg bg-opacity-30'>
    <img
         src={userFetchedDetails?.profilePic?.url ? userFetchedDetails?.profilePic?.url :profileImage}
          style={{objectFit:'cover',width:'100px', height:'100px', borderRadius:'100%'}}
         alt='welcome image'
         quality={100}
 
         />
         <div className='pr-3'>
           <h1 className='text-xl font-bold'>{userFetchedDetails?.name}</h1>
           <p className='text-sm'>{userFetchedDetails?.email}</p>
          {userFetchedDetails?.address && <p className='text-sm'>{userFetchedDetails?.address}, {userFetchedDetails?.city}, {userFetchedDetails?.state}</p>}
           <div className='flex gap-2 items-center justify-around p-5'>
               <div>
                   <h1 className='text-l font-bold'>${userFetchedDetails?.mainWallet}</h1>
                   <h1 style={{fontSize:'12px', textAlign:'center'}}>Wallet</h1>
               </div>
             
           </div>
         </div>
    </div>
  )
}

export default ProfileSummary
