
import React from 'react'
import styles from './loader.module.css'
// import robot from '../assets/robot.png'
import { Player } from '@lottiefiles/react-lottie-player';
import loaderSRC from '../assets/crownloading.json'


const Loader = () => {
  return (
    <div style={{
      position: 'fixed', // Fixed position to cover the screen
      top: 0,
      left: 0,
      zIndex:100,
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
      display: 'flex', // Center the loader content vertically and horizontally
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(250, 250, 250, 0.9)', // Semi-transparent background color
    }}>
      <div className={styles.loaderImage}>

      </div>
          <Player
          src={loaderSRC}
          className="player"
          loop
          autoplay
           style={{ height: '300px', width: '300px' }}
          
          />
          <div  style={{ height: '250px', width: '250px',  }}>
          {/* <img
            style={{ height: '250px', width: '250px', objectFit:'cover' }}
           src={robot} alt='robot' /> */}
          </div>
         
    
  </div>
  )
}

export default Loader
