import React, { useContext, useEffect, useState } from 'react';
import './navbarDashboard.css';
import SearchIcon from '@mui/icons-material/Search';
import LanguageIcon from '@mui/icons-material/Language';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useStateContext } from '../../contexts/ContextProvider';
import { Tooltip } from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';
import useFetch from '../../hooks/useFetch';

const NavbarDashboard = () => {
  const {activeMenu, setActiveMenu, screenSize} = useStateContext();


  const [list, setList] = useState([])
  const [unRead, setUnRead] = useState(null)
  const {user} = useContext(AuthContext)

  let count = 0;

  const { data, loading, error } = useFetch('/messages');
  const serverPublic =  process.env.REACT_APP_PUBLIC_FOLDER;

  useEffect(()=>{

    setList(data)
  },[data]);

  // console.log(list.length)


  useEffect(()=>{

    list.map((item)=>{

      console.log(item.isRead)
      if(item.read === true){
       
        count = count + 1
        // console.log(count)
      
        const newCount = list.length - count
        // console.log(newCount)
        // setUnRead(newCount)
      }
    })

  }, [count])
// console.log(unRead)
  return (
    <div className='navbar-dashboard'>

      <div className="wrapper"   >
         <MenuOpenIcon style={{cursor: 'pointer'}} onClick={()=> setActiveMenu((prevActiveMenu)=> !prevActiveMenu)} />
        {/* <div className="search">
          <input
          type='text'
          placeholder='Search...'
          />
          <SearchIcon />
        </div> */}
        <div className="items">
          <div className="item">
            <LanguageIcon  className='icon'/>
            English
          </div>

          {/* <div className="item">
            {darkMode ? <WbSunnyIcon className='icon' onClick={()=>dispatch({type: 'TOGGLE'})} /> :  <DarkModeOutlinedIcon className='icon' onClick={()=>dispatch({type: 'TOGGLE'})} />}
           
          </div> */}

          <div className="item">
            <FullscreenExitOutlinedIcon className='icon'/>
          </div>

          <div className="item">
          <Tooltip title='Notifications'>
             <NotificationsNoneOutlinedIcon className='icon'/>
          </Tooltip>
            <div className="counter">{unRead}</div>
          </div>

          <div className="item">
            <Tooltip title='Messages'>
               <ChatBubbleOutlineOutlinedIcon className='icon'/>
            </Tooltip>
            <div className="counter">2</div>
          </div>

          <div className="item">
            <FormatListBulletedOutlinedIcon className='icon'/>
          </div>
          <div className="item">
            <Tooltip title='Profile'>
             <img src={user.profilePicture ?  user.profilePicture : 
                 serverPublic + 'profile.png'
                }
             alt='user-profile'
             className='avatar'
             />
            </Tooltip> 
          </div>

        </div>
      </div>
      
    </div>
  )
}

export default NavbarDashboard
