import React from 'react'
import styles from './chooseTransferColumn.module.css'
import { Link } from 'react-router-dom'
import { FaArrowRight } from "react-icons/fa";
import { useTranslation } from 'react-i18next'

const ChooseTransferColumn = () => {
  const {t} = useTranslation()

  return (
    <div className={styles.chooseTransferColumnContainer}>
      
      <Link to='/transferToWallet' className={styles.left}>
    <h1 className='text-center'>{t('chooseTransfersToMain')}</h1>
    <p>{t('chooseTransfersToMainDe')}</p>
    <FaArrowRight className={styles.arrow} />
      </Link>

      <Link to="/transfers" className={styles.right}>
      <h1 className='text-center'>{t('chooseTransfersFromMain')}</h1>
      <p>{t('chooseTransfersFromMainDe')}</p>
      <FaArrowRight className={styles.arrow} />
      </Link>

    </div>
  )
}

export default ChooseTransferColumn
