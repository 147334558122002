import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import './navbar.css'
import menu  from '../../assets/menu.png'
import logo  from '../../assets/crownstreetlogo.png'
import cancel from '../../assets/cancel.png'
import { HiMenuAlt3 } from "react-icons/hi";
import { SiExpertsexchange } from "react-icons/si";
import { IoClose } from "react-icons/io5";
import { BiSolidDownArrow } from 'react-icons/bi'
import { TiArrowSortedUp } from "react-icons/ti";
import Loader from '../Loader'
import { useTranslation } from 'react-i18next'

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openAboutTibio, setOpenAboutTibio] = useState(false)
  const [loading, setLoading]= useState(true)
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  
  const {t} = useTranslation()


  const handleClick = ()=> setClick(!click);
  const closeMenu =()=> setClick(false);

 

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 100) {
        // Adjust the scroll position at which the navbar becomes fixed
        setIsNavbarFixed(true);
      } else {
        setIsNavbarFixed(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const CustomNavLink = ({ to, children }) => {
    const location = useLocation();
  
    // Check if the current path matches the 'to' prop
    const isActive = location.pathname === to;
  
    return (
  
        <Link to={to} className={`navITEM ${isActive ? 'active-link-home' : ''}`}>
          {children}
        </Link>
  
    );
  };

  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);

  return (
    <div style={{position:'sticky'}} className={`nav-container ${isNavbarFixed ? "fixed" : ""}`}>
 {loading ? <Loader /> : null}
<img
        src={logo}
        style={{width:200, height:100, objectFit:'contain'}}
        alt="logo image"
  
      />
      <div className='navItems-container'>
     
      <div className='menu-bar' onClick={handleClick} >
        { click ?
         <IoClose  className='text-3xl text-green-600 font-bold'  /> 
         : 
         <HiMenuAlt3 className='text-2xl text-green-600'  />
         }
        </div>

         <div className={click ? 'navItems-container occured' : 'navItems-inner-container '}>

         <CustomNavLink to='/'>{t('navbarHome')}
          </CustomNavLink>
            
          <CustomNavLink className='navITEM'  to='/claireX'>ClaireX</CustomNavLink>
            <div className='flex items-center justify-center gap-6 '>

<div  onMouseEnter={()=>setOpenAboutTibio(true)} onClick={()=>setOpenAboutTibio(!openAboutTibio)} style={{position:'relative', cursor:'pointer'}} className='nav-crown'  >Crown</div>
  {openAboutTibio ? <TiArrowSortedUp onClick={()=>setOpenAboutTibio(!openAboutTibio)} className='navIcon' /> : <BiSolidDownArrow onClick={()=>setOpenAboutTibio(!openAboutTibio)} className='navIcon' />}

</div>


 {openAboutTibio &&
  <div onMouseLeave={()=>setOpenAboutTibio(false)} className='nav-inner'>
  <CustomNavLink to='/who-we-are'>{t('navbarWhoWeAre')}</CustomNavLink>
  <CustomNavLink  to='/how-we-invest'>{t('navbarHowWeInvest')}</CustomNavLink>
  <CustomNavLink     to='/philosophy'>{t('navbarPhilosophy')}</CustomNavLink>
  <CustomNavLink     to='/borrow'>{t('navbarBorrow')}</CustomNavLink>
  <CustomNavLink     to='/faq'>FAQ</CustomNavLink>

  </div>
    } 
            {/* <NavLink  className='nav'  to='/contact'></NavLink> */}
            <CustomNavLink     to='/contact'>{t('navbarContactUs')}</CustomNavLink>
            <CustomNavLink   to='/login'>{t('navbarLogin')}</CustomNavLink>
         </div>
      </div>
      {/* <div className="logoContainer">
      <Link to='/' style={{textDecoration: 'none', cursor: 'pointer'}}>
       <img style={{width:300, height:100, objectFit:'contain'}} src={logo} alt="company logo" />
        </Link>
        <div className="menu" onClick={handleClick}>
        { click ?  <img src={cancel} alt="menu" />  :
         <img src={menu} alt="menu" />
         }
        </div>
      </div>
     
      <div className="navLinksContainer">
  <div className={click ? "menuLinks" : "menuLinks active"}>
    <Link
      to='/who-we-are'
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      Who We Are
    </Link>
    <Link
      to='/how-we-invest'
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      How we Invest
    </Link>
    <Link
      to='/philosophy'
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      Philosophy
    </Link>
    <Link
      to='/portfolio'
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      Portfolio
    </Link>
    <Link
      to='/borrow'
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      Borrow
    </Link>
    <Link
      to='/login'
      style={{ textDecoration: 'none', color: 'inherit' }}
      className='login'
    >
      Login
    </Link>
  </div>
</div> */}
    </div>
  )
}

export default Navbar
