import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import styles from './transferFromWalletAmount.module.css'
import TransferPortfolioComponent from '../components/TransferPortfolioComponent'
import Sidebar from '../components/sidebar/Sidebar'
import { Link, useNavigate } from 'react-router-dom'
import { IoArrowUndo } from "react-icons/io5";
import { useSelector } from 'react-redux'
import { selectUserInfo } from '../slices/authSlice'
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next'


const TransferPortfolios = () => {
    const navigate = useNavigate()
    const userInfo = useSelector(selectUserInfo)
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

    const [loading, setLoading] = useState(true)
    const [allTransfers, setAllTransfer] = useState([]);

    const {t} = useTranslation()

const columns = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'email', headerName: 'Email', width: 150 },
  {
    field: "createdAt",
    headerName: `${t('latestTransactionTableDate')}`,
    width: 130,
    renderCell: (params) => {
      return (
        <div style={{borderRadius:'10px'}} className=''>
          {new Date(params.row.createdAt).toUTCString()}
        </div>
      );
    },
  },
  // { field: 'name', headerName: `${t('latestTransactionTableName')}`, width: 160 },
  { field: 'amount', headerName: `${t('latestTransactionTableAmount')}`, width: 120 },
  { field: 'previousOwner', headerName: 'Broker', width: 100 },
 
   
];

const columnsTwo = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    // field: "createdAt",
    headerName: 'Email',
    width: 130,
    renderCell: (params) => {
      return (
        <div style={{borderRadius:'10px'}} className=''>
          {userInfo.user.email}
        </div>
      );
    },
  },
  {
    field: "createdAt",
    headerName: `${t('latestTransactionTableDate')}`,
    width: 130,
    renderCell: (params) => {
      return (
        <div style={{borderRadius:'10px'}} className=''>
          {new Date(params.row.createdAt).toUTCString()}
        </div>
      );
    },
  },
  // { field: 'name', headerName: `${t('latestTransactionTableName')}`, width: 160 },
  { field: 'amount', headerName: `${t('latestTransactionTableAmount')}`, width: 120 },
  { field: 'email', headerName: 'Receiver', width: 100 },
 
   
];

  useEffect(()=>{

    const fetchTransfers =async()=>{
 
 
     var myHeaders = new Headers();
     myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
 
     var requestOptions = {
         method: 'GET',
         headers: myHeaders,
         redirect: 'follow'
       };
       
       fetch(`${API_ENDPOINT}/api/transfers`, requestOptions)
         .then(response => response.json())
         .then(result =>{
           console.log(result.transfers)
           setAllTransfer(result.transfers)
          //  setUserPortfolios(result.portfolios)
 
        
          setLoading(false)
         })
         .catch(error => console.log('error', error));
 
    }
 
    fetchTransfers()
   }, [])

   const filteredTransfers = allTransfers.filter(transfer=> transfer.email === userInfo.user.email && transfer.status === 'transferPortfolio')
   const filteredTransfersForPortfolioSender = allTransfers.filter(transfer=> transfer?.previousOwner === userInfo.user.email && transfer.status === 'transfered')
   const filteredTransfersForPortfolioReceiver = allTransfers.filter(transfer=> transfer?.email === userInfo.user.email && transfer.status === 'transfered')

   console.log(filteredTransfers)

  const handleNavigateToAccept=(transfer)=>{
    navigate( `/transferPortfolios/${transfer._id}`,  {state:{ data: transfer }} )

   }

  return (
    <div className={styles.transfersContainer}>
    <Sidebar />
  <div className={styles.transfersInnerContainer}>
    <DashboardNavbar />
    <IoArrowUndo onClick={()=>navigate(-1)} className={styles.backButton} />
        <h1 className='text-xl font-bold ml-5 pl-10 text-gray-300 mb-2'>  Transfer Portfolios</h1>

      {filteredTransfers.length !== 0 ?  <div style={{width:'80%', margin:'20px auto'}} className='bg-green-100 p-2'>
         <h1 className='text-xl font-bold pb-2'>Transfer Portfolio Request</h1>
         {filteredTransfers.map(transfer=>(
          <div key={transfer._id} className='flex items-center justify-between'>
            <p>Portfolio transfer request of ${transfer.amount} </p>
            <div onClick={()=>handleNavigateToAccept(transfer)} className='bg-white p-2 rounded-lg cursor-pointer'>View</div>
          </div>
         ))}
        </div> : null}

        { filteredTransfersForPortfolioSender.length !== 0 ?
           <div  style={{width:'80%', margin:'20px auto'}} className='bg-green-100 p-2'>
           <h1 className='text-xl font-bold'>Portfolios Sold</h1>
           <div className="flex justify-center my-5">
             <DataGrid
                className={styles.datatableTransfer}
                getRowId={(row) => row._id}
                rows={filteredTransfersForPortfolioSender} columns={columnsTwo}
              //   columns={requestColumns.concat(actionColumn)}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                GridLinesVisibility="Vertical"
              />
              
              </div>
                    </div> : null
        }

{ filteredTransfersForPortfolioReceiver.length !== 0 ?
          <div  style={{width:'80%', margin:'20px auto'}} className='bg-green-100 p-2'>
            <h1 className='text-xl font-bold'>Portfolios Acquired</h1>
            <div className="flex justify-center my-5">
            <DataGrid
      className={styles.datatableTransfer}
      getRowId={(row) => row._id}
      rows={filteredTransfersForPortfolioReceiver} columns={columns}
    //   columns={requestColumns.concat(actionColumn)}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      GridLinesVisibility="Vertical"
    />
    
    </div>
          </div>
        : null }

        <TransferPortfolioComponent />
    </div>
    </div>
  )
}

export default TransferPortfolios
