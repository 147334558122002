import React from 'react'
import Sidebar from '../components/sidebar/Sidebar'
import DashboardNavbar from '../components/DashboardNavbar'
import EmptyContainer from '../components/EmptyContainer'
import './chooseTransfer.css'
import { useTranslation } from 'react-i18next'

// {t('fundWalletPop')}
const AwaitingPayment = () => {
  const {t} = useTranslation()

  return (
    <div className='chooseContainer'>
        <Sidebar />
    <div className="chooseInnercontainer">
    
    <DashboardNavbar />

    <div style={{marginTop:'120px'}}>
    <EmptyContainer emptyTitle={t('awaitingPaymentPageEmptyTitle')} emptyDetails={t('awaitingPaymentPageEmptyDetails')} />

    </div>
    
     </div>
    </div>
  )
}

export default AwaitingPayment
