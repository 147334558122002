import React from 'react'
import styles from './topTag.module.css'
// import { useTicker } from "../../../../tibio/client/src/utils/hook";
// import TickerCrypto from "../../../../tibio/client/src/components/TickerCrypto";
import { Player } from '@lottiefiles/react-lottie-player';
import robotAni from '../assets/crownRobotAni.json'
import { useTranslation } from 'react-i18next'

const TopTag = () => {
  // const cryptocurrencies = useTicker()
  const {t} = useTranslation()

  const array = [
    {id: 1,
     testimonial:  `${t('testimonial1')}`,
   },
   {id: 2,
     testimonial:  `${t('testimonial2')}`,
   },
   {id: 3,
     testimonial:  `${t('testimonial3')}`,
   },
   {id: 4,
     testimonial:  `${t('testimonial4')}`,
   },
   {id: 5,
     testimonial: `${t('testimonial5')}`,
   },
   {id: 6,
     testimonial:  `${t('testimonial6')}`,
   },
   {id: 7,
     testimonial:  `${t('testimonial7')}`,
   },
   {id: 8,
     testimonial:  `${t('testimonial8')}`,
   },
   {id: 9,
     testimonial:  `${t('testimonial9')}`,
   },
   {id: 10,
     testimonial: `${t('testimonial10')}`,
   },
   {id: 11,
     testimonial:  `${t('testimonial11')}`,
   },
   {id: 12,
     testimonial:  `${t('testimonial12')}`,
   },
   {id: 13,
     testimonial:  `${t('testimonial13')}`,
   },
   {id: 14,
     testimonial:  `${t('testimonial14')}`,
   },
   {id: 15,
     testimonial:  `${t('testimonial15')}`,
   },
   {id: 16,
     testimonial:  `${t('testimonial16')}`,
   },
   {id: 17,
     testimonial:  `${t('testimonial17')}`,
   },
   {id: 18,
     testimonial:  `${t('testimonial18')}`,
   },
   {id: 19,
     testimonial:  `${t('testimonial19')}`,
   },
   {id: 20,
     testimonial:  `${t('testimonial20')}`,
   },
  ];

  
  return (
    <div className={styles.topTagContainer}>
  <div className={styles.innerContainer}>
{array?.map((crypto)=>(
  <div className='flex items-center justify-center' key={crypto.id}>
    <p className='pr-4 text-white pt-2'>{crypto.testimonial}</p>
    <Player
                  src={robotAni}
                  className="player"
                  loop
                  autoplay
                   style={{ height: '50px', width: '50px' }}
                  
                  />
  </div>
        // <TickerCrypto key={crypto.id} crypto={crypto}/>
      ))}
  

    </div>

    </div>
  )
}

export default TopTag
