import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import styles from './twoFA.module.css'
import {useNavigate} from 'react-router-dom'
import { FaRegTimesCircle } from 'react-icons/fa'
import { Player } from '@lottiefiles/react-lottie-player';
import loaderSRC from '../assets/robotAnimation.json'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'
import purchaseImage from '../assets/techHand.jpg'
import Sidebar from '../components/sidebar/Sidebar'
import { useTranslation } from 'react-i18next'

const TwoFA = () => {

  const navigate  = useNavigate()
const [popUp, setPopUp] = useState('')
const [showPopupModal, setShowPopupModal] = useState(false)

const userInfo = useSelector(selectUserInfo)
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

const {t} = useTranslation()

  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
      navigate('/login')
    }


  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);

  const handleActivateTwoFA=async()=>{

    var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

      var formdata = new FormData();
      formdata.append("two_activated", true);

      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${API_ENDPOINT}/api/users/update-twoFA`, requestOptions)
        .then(response => response.json())
        .then(result => {
          
          setPopUp(`${t('2FAPopup')}`)
          setShowPopupModal(true)
          console.log(result)})
        .catch(error => console.log('error', error));
        
        
          
  }

  return (
    <div className={styles.transferOverallContainer}>
               <Sidebar />
    <div className={styles.transferInner}>
    
    <DashboardNavbar />

      <div style={{margin:'100px auto'}} className='p-8'>
      <div className={styles.transferImagesContainer}>

    <img
            src={purchaseImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
    
            />
            <div className={styles.transferContainer}>
        
        <div style={{width:'85%', margin:'auto'}} className=' my-11 bg-green-200/50 mx-11 p-5  backdrop-filter backdrop-blur-lg bg-opacity-30'>

<div style={{width:'100%', margin:'auto'}} className='flex flex-col gap-3 ' action="">
  <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>{t('2FATitle')}</h1>
   <p className='text-xl text-center'>{t('2FAPara')}</p>
<button onClick={handleActivateTwoFA} className={styles.loginButton} type="submit">{t('2FAButton')}</button>

</div>
</div>

 {/* popup */}
 { showPopupModal && 
   <div className='showContainer'>

        <div className='innerShowContainer'>
        < FaRegTimesCircle onClick={handleClosePopup} className='showClose' />
            <div className='flex gap-2 flex-col'>
            <Player
          src={loaderSRC}
          className="player"
          loop
          autoplay
           style={{ height: '50px', width: '50px' }}
          
          />
            <p className='showText'>{popUp}</p>
            </div>
           
            <button className='showButton' onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}

      </div>
      </div>

      </div>
     </div>
    </div>
  )
}

export default TwoFA
