import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar/Navbar'
import { TickerTape } from "react-ts-tradingview-widgets";
import Footer from '../components/footer/Footer';
import Loader from '../components/Loader';
import ForgetPasswordColumn from '../components/ForgetPasswordColumn';
import TopTag from '../components/TopTag';


const ForgetPassword = () => {
  const [loading, setLoading]= useState(false)
  const customCopyrightStyles = {
    display: 'none', 
    opacity: 0,
    color:'white'
  };

  // useEffect(() => {
   
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 3000); 
  // }, []);

  return (
    <div>
      <TopTag /> 
      {loading ? <Loader /> : null}
     
        <Navbar />   
        <ForgetPasswordColumn />
      
        <Footer />
    </div>
  )
}

export default ForgetPassword
