import React, { useEffect } from 'react'

import styles from './claire.module.css'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import ClaireXColumn from '../components/ClaireXColumn'
import PageColumn from '../components/pageColumn/PageColumn'
import TopTag from '../components/TopTag'
import { useTranslation } from 'react-i18next'

const ClaireX = () => {
  const {t} = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <div className={styles.claireXPageContainer}>
      <TopTag />
   <Navbar />
<div className={styles.claireXPageInnerContainer}>
<PageColumn highlight={t('claireXPageHero')} />
    <ClaireXColumn />
   

 </div>
 <Footer />
</div>
  )
}

export default ClaireX
