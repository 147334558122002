import React from 'react'
import './investmentProgramme.css'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'

const InvestmentProgrammes = () => {
    const {t} = useTranslation()

  return (
    <div className='investment-programmes'>
        <div className="ip-top">
        <h1>{t('investmentProgrammesTitle')}</h1> 
        <h4>{t('investmentProgrammesSubtitle')}</h4>
            <p>{t('investmentProgrammesOneDe')} <strong>{t('investmentProgrammesTwoDe')} </strong>{t('investmentProgrammesThreeDe')} <span>{t('investmentProgrammesFourDe')} </span> .{t('investmentProgrammesFiveDe')} <span>{t('investmentProgrammesSixDe')} </span></p>
        </div>
        <div className="ip-bottom">
            <div className="ip-bottom-each">
                <FlightTakeoffIcon />
                <h6>{t('investmentProgrammesColumnOne')} </h6>
            </div>
            <div className="ip-bottom-each">
                <AddRoadIcon />
                <h6>{t('investmentProgrammesColumnTwo')} </h6>
            </div>
            <div className="ip-bottom-each">
                <BusinessIcon />
                <h6>{t('investmentProgrammesColumnThree')} </h6>
            </div>
            <div className="ip-bottom-each">
                <CategoryIcon />
                <h6>{t('investmentProgrammesColumnFour')} </h6>
            </div>
            <div className="ip-bottom-each">
                <FamilyRestroomIcon />
                <h6>{t('investmentProgrammesColumnFive')} </h6>
            </div>
         
        </div>
        <Link to='/how-we-invest' className='ip-button'>{t('investmentProgrammesColumnReadMore')}</Link>
    </div>
  )
} 

export default InvestmentProgrammes
