import React from 'react'
import styles from './pageColumn.module.css'
import column from '../../assets/column.mp4'
import techHand from '../../assets/techHandTwo.jpg'
const PageColumn = ({highlight}) => {
  return (
    <div className={styles.pageColumnContainer}>
        <div className={styles.pageGradient}></div>
        {/* <video   src={column}
        className={styles.pageColumnVideo}
        type="video/mp4"
        height={400}
        loop
        controls={false}
        muted
        autoPlay /> */}
        <img className={styles.pageColumnVideo} src={techHand} alt="welcome to ai" />

      <h4>{highlight}</h4>
       

    </div>
  )
}

export default PageColumn
