import React, { useState } from 'react'
import './message.css'

const Message = ({eachMessage}) => {
  const [showMessage, setShowMessage] = useState(false)

  // onClick={()=>setShowMessage(true)}
  
  return (
    <>
  
    <div onClick={()=>setShowMessage(true)} className='message-container'> 
      <h6 className="title">{eachMessage.title}</h6>
      <p className="content">{eachMessage.message}</p>
    </div>

    { showMessage && <div onClick={()=>setShowMessage(false)} className='message-modal-container'>
      <div className="inner-message-container">

      <h6 className="title">{eachMessage.title}</h6>
      <p className="content">{eachMessage.message}</p>
      </div>
      
    </div>

    }

    </>
  )
}

export default Message
