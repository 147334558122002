
import React, { useEffect, useState, useContext } from 'react'
import { SiWebmoney } from 'react-icons/si'
import Loader from './Loader';
import RecentTransactionTable from './RecentTransactionTable';
import styles from './dashboardHome.module.css'
import { Link, useNavigate } from 'react-router-dom';
import TradingViewOne from '../components/TradingViewOne'
import { selectUserInfo } from '../slices/authSlice';
import { selectFetchedUserInfo } from '../slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import chartOne from '../assets/chart1.png'
import chartTwo from '../assets/chart2.png'
import chartThree from '../assets/chart3.png'
import { FaArrowUp } from "react-icons/fa";
import { BiMoneyWithdraw } from 'react-icons/bi'
import { BsFillBoxFill } from 'react-icons/bs'
import { BiSolidNetworkChart } from 'react-icons/bi'
import { EconomicCalendar } from "react-ts-tradingview-widgets";
import { useTranslation } from 'react-i18next'
import { FaRegTimesCircle } from 'react-icons/fa'


// total amountwithdrawn displayed and also total times withdrawn implement








const DashboardHome = () => {
  const [loading, setLoading] = useState(true);

  const fetchedUserInfo = useSelector(selectFetchedUserInfo)
  const dispatch = useDispatch()
  const userInfo = useSelector(selectUserInfo)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
  const [fetchedPortfolio, setFetchedPortfolio] = useState([])

    const [allReferrals, setAllReferrals] = useState([])
    const [eachUserWithdrawal, setEachUserWithdrawal] = useState([])
    const [allWithdrawals, setAllWithdrawals] = useState([])
    const [allPortfolios, setAllPortfolios] = useState([])
    const [popUp, setPopUp] = useState('')
  const [showPopupModal, setShowPopupModal] = useState(false)

    const [updateProfileModal, setUpdateProfileModal] = useState(false)

    const {t} = useTranslation()

  const navigate = useNavigate()
    // console.log(fetchedUserInfo)

// console.log(userInfo.user._id)

  useEffect(() => {
    const fetchReferrals = async () => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_ENDPOINT}/api/referrals/${userInfo.user._id}`, requestOptions);
        const result = await response.json();
        // console.log('called again')
        setAllReferrals(result.referrals);
       
        // console.log(result);
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchReferrals()
   
  }, [userInfo.token, API_ENDPOINT]); // Add dependencies as needed
 

  useEffect(() => {
    const fetchUserPortfolio = async () => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_ENDPOINT}/api/portfolios/${userInfo.user._id}`, requestOptions);
        const result = await response.json();
        // console.log('called again')
        setFetchedPortfolio(result.portfolios)
        console.log(result);
      } catch (error) {
        console.log('error', error);
      }
    };

    // Initial fetch
    fetchUserPortfolio();

    // Set up interval for fetching every 10 seconds
    const intervalId = setInterval(fetchUserPortfolio, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [userInfo.token, API_ENDPOINT]); // Add dependencies as needed



  // Calculate the sum of balance for entries with type equal to "daily"
const sumOfDailyBalance = fetchedPortfolio
.filter((entry) => entry.type === "daily")
.reduce((accumulator, entry) => accumulator + entry.balance, 0);

// console.log("Sum of balance for daily type:", sumOfDailyBalance);


const sumOfCompoundingBalance = fetchedPortfolio
.filter((entry) => entry.type === "compounding")
.reduce((accumulator, entry) => accumulator + entry.balance, 0);




useEffect(() => {
  const fetchUserWithdrawal = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    try {
      const response = await fetch(`${API_ENDPOINT}/api/withdrawal`, requestOptions);
      const result = await response.json();

      if (result) {
        setAllWithdrawals(result.withdrawals);
      }

      console.log(result.withdrawals);
    } catch (error) {
      console.error('Error fetching user withdrawals:', error);
    }
  };

  fetchUserWithdrawal();
}, []);

useEffect(() => {
  if (allWithdrawals.length !== 0) {
    const filteredUserWithdrawal = allWithdrawals.filter(detail => detail.user === userInfo.user._id);
    setEachUserWithdrawal(filteredUserWithdrawal, () => {
      console.log(eachUserWithdrawal); // This will now log the updated value
    });
  }


}, [allWithdrawals, userInfo.user]);

const filteredWithdrawalData = eachUserWithdrawal.filter(withdraw => withdraw.withdrawalAuthorizer === true)

console.log(eachUserWithdrawal)
const totalWithdrawal = filteredWithdrawalData.reduce((accumulator, entry) => accumulator + entry.amount, 0);


useEffect(() => {
  const fetchPortfolios = async () => {
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    try {
      const response = await fetch(`${API_ENDPOINT}/api/portfolios/${userInfo.user._id}`, requestOptions);
      const result = await response.json();
      console.log('called again')
      setAllPortfolios(result.portfolios);
     
      console.log(result);
    } catch (error) {
      console.log('error', error);
    }
  };
  fetchPortfolios()
 
}, [userInfo.token, API_ENDPOINT]); 


console.log(fetchedUserInfo)

useEffect(()=>{

  if(fetchedUserInfo.name == "John Doe"){
    console.log('user is:', fetchedUserInfo.name)
    setUpdateProfileModal(true)
    return
  } else {
    console.log('user is:', fetchedUserInfo.name)
    setUpdateProfileModal(false)
    return
  }
},[])

const handleClosePopup =()=>{
  setShowPopupModal(false)
   setPopUp('')
   navigate('/profile')
  }



  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);




  return (
    <div className={styles.dashboardHomeContainer}>
      {/* top */}
      {loading ? <Loader /> : null}


         {/* popup */}
     { updateProfileModal && 
      <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', margin:'auto'}} className={styles.popContainer}>
          
           <div className={styles.innerPopContainer}>
               < FaRegTimesCircle onClick={()=>setUpdateProfileModal(false)} className={styles.popClose} />
                <p className={styles.popText}>{t('updateYourProfileModal')}</p>
               <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
           </div>
         
       
       </div>
           }
      {/* popup ends */}

      <div style={{marginTop:'150px', marginLeft:'100px'}}>
      <Link className={styles.glowOnHover} to='/Wallets'>{t('dashboardHomeWallets')}</Link>

      </div>

<div  className={styles.mainWalletContainer}>

<div style={{width: window.innerWidth > 300 ? '300px' : '200px'}} className='bg-gradient-to-r from-cyan-500/50 to-gray-500/50  p-3 border-2 shadow-md flex flex-col gap-3 rounded-lg'>
          <div className='flex items-center gap-2'>
          <SiWebmoney style={{color:'rgb(58, 135, 224)',fontSize:20}} />
          <h1 style={{fontSize:'18px', textAlign:'center', fontWeight:400}}>{t('dashboardHomeDaily')}</h1> 
          </div>
            <p className='text-2xl font-bold text-sky-800 ml-2' >${Number(fetchedUserInfo?.dailyWalletTotal) > 0 ? Math.round(fetchedUserInfo?.dailyWalletTotal) : 0 }</p>
            <div className='flex items-end justify-between'>
              <div className='flex text-green-600 items-center'>
              <FaArrowUp />
              <p>0.98</p>
              </div>
           
              <img style={{width:'60%', borderRadius:'0%', height:100}} className='bg-sky-200/50' src={chartOne} alt="chart" />
            </div>
        </div>

        <div style={{width: window.innerWidth > 300 ? '300px' : '200px'}} className='bg-gradient-to-r from-green-500/50 to-gray-500/50  p-3 border-2 shadow-md flex flex-col gap-3 rounded-lg'>
          <div className='flex items-center gap-2'>
          <SiWebmoney style={{color:'rgb(58, 135, 224)',fontSize:20}} />
          <h1 style={{fontSize:'18px', textAlign:'center', fontWeight:400}}>{t('dashboardHomeCompounding')}</h1> 
          </div>
            <p className='text-2xl font-bold text-sky-800 ml-2' >${Number(sumOfCompoundingBalance) > 0 ? Math.round(sumOfCompoundingBalance) : 0 }</p>
            <div className='flex items-end justify-between'>
              <div className='flex text-green-600 items-center'>
              <FaArrowUp />
              <p>0.98</p>
              </div>
           
              <img style={{width:'60%', borderRadius:'0%', height:100}} className='bg-sky-200/50' src={chartTwo} alt="chart" />
            </div>
        </div>

        <div style={{width: window.innerWidth > 300 ? '300px' : '200px'}} className='bg-gradient-to-r from-yellow-500/50 to-gray-500/50  p-3 border-2 shadow-md flex flex-col gap-3 rounded-lg'>
          <div className='flex items-center gap-2'>
          <SiWebmoney style={{color:'rgb(58, 135, 224)',fontSize:20}} />
          <h1 style={{fontSize:'18px', textAlign:'center', fontWeight:400}}>{t('dashboardHomeMain')}</h1> 
          </div>
            <p className='text-2xl font-bold text-sky-800 ml-2' >${Number(fetchedUserInfo?.mainWallet) > 0 ? fetchedUserInfo?.mainWallet : 0 }</p>
            <div className='flex items-end justify-between'>
              <div className='flex text-green-600 items-center'>
              <FaArrowUp />
              <p>0.98</p>
              </div>
           
              <img style={{width:'60%', borderRadius:'0%', height:100}} className='bg-sky-200/50' src={chartThree} alt="chart" />
            </div>
        </div>

</div>
<div className={styles.innerContainer}>

<div className={styles.innerLeft}>
       

        <div style={{width:'200px', height:120}} className='bg-sky-200 p-3 border-2 shadow-md flex items-center justify-between gap-8 flex-grow'>
          <div>
          <h1 style={{fontSize:'15px'}}>{t('dashboardHomeTotalWithdrawn')}</h1>    
           <p className='text-xl font-bold' >${Number(totalWithdrawal) > 0 ? totalWithdrawal : 0 }</p>
          </div>
            <BiMoneyWithdraw style={{color:'rgb(58, 135, 224)',fontSize:40}} className='p-2 bg-yellow-100 rounded-full' />
        </div>

        <div style={{width:'200px', height:120}} className='bg-yellow-100 p-3 border-2 shadow-md flex items-center justify-between gap-8 flex-grow'>
          <div>
          <h1 style={{fontSize:'15px'}}>{t('dashboardHomeTotalPortfolios')}</h1>    
           <p className='text-xl font-bold' >{allPortfolios?.length}</p>
          </div>
            <BsFillBoxFill style={{color:'rgb(58, 135, 224)',fontSize:40}} className='p-2 bg-green-100 rounded-full' />
        </div>

        <div style={{width:'200px', height:120}} className='bg-green-100 p-3 border-2 shadow-md flex items-center justify-between gap-8 flex-grow'>
          <div>
          <h1 style={{fontSize:'15px'}}>{t('dashboardHomeTotalReferral')}</h1>    
           <p className='text-xl font-bold' >{allReferrals?.length}</p>
          </div>
            <BiSolidNetworkChart style={{color:'rgb(58, 135, 224)',fontSize:40}} className='p-2 bg-sky-100 rounded-full'/>
        </div>
      </div>

      <div className={styles.innerRight} style={{height:600, width:'65%', margin:'auto' }}>

        <TradingViewOne />

        </div>

      

</div>

<div className='p-8 my-9' style={{ width:'80%', margin:'auto', paddingLeft:'50px',height:'600px' }}>
        <EconomicCalendar colorTheme="dark" height={400} width="100%"></EconomicCalendar>
        </div>
   
    

      <RecentTransactionTable />
    </div>
  )
}

export default DashboardHome
