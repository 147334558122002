import React, { useState } from 'react'
import Loader from '../components/Loader'
import TopTag from '../components/TopTag'
import Navbar from '../components/navbar/Navbar'
import { useTranslation } from 'react-i18next'
import styles from './signup.module.css'
import Footer from '../components/footer/Footer'
import { Link, useNavigate } from 'react-router-dom'
import { FaRegTimesCircle } from 'react-icons/fa'

const SurveyQuestionnaire = () => {
  const [loading, setLoading] = useState(false);
  const [selectedOptionOne, setSelectedOptionOne] = useState('');
  const [selectedOptionTwo, setSelectedOptionTwo] = useState('');
  const [selectedOptionThree, setSelectedOptionThree] = useState('');
  const [selectedOptionFour, setSelectedOptionFour] = useState('');
  const [selectedOptionFive, setSelectedOptionFive] = useState('');
  const [selectedOptionSix, setSelectedOptionSix] = useState('');
  const [selectedOptionSeven, setSelectedOptionSeven] = useState('');
  const [selectedOptionEight, setSelectedOptionEight] = useState('');
  const [selectedOptionNine, setSelectedOptionNine] = useState('');
  const [selectedOptionTen, setSelectedOptionTen] = useState('');
  const [selectedOptionEleven, setSelectedOptionEleven] = useState('');
  const [selectedOptionTwelve, setSelectedOptionTwelve] = useState('');
  const [selectedOptionThirteen, setSelectedOptionThirteen] = useState('');
 
  const navigate = useNavigate()
  const [showPopupModal, setShowPopupModal] = useState(false)

  const [popUp, setPopUp] = useState('')
  const handleCheckboxChangeOne = (event) => {
    setSelectedOptionOne(event.target.value);
    console.log(selectedOptionOne)
  };
  const handleCheckboxChangeTwo = (event) => {
    setSelectedOptionTwo(event.target.value);
    console.log(selectedOptionTwo)
  };
  const handleCheckboxChangeThree = (event) => {
    setSelectedOptionThree(event.target.value);
    console.log(selectedOptionThree)
  };
  const handleCheckboxChangeFour = (event) => {
    setSelectedOptionFour(event.target.value);
    console.log(selectedOptionFour)
  };
  const handleCheckboxChangeFive = (event) => {
    setSelectedOptionFive(event.target.value);
    console.log(selectedOptionFive)
  };
  const handleCheckboxChangeSix = (event) => {
    setSelectedOptionSix(event.target.value);
    console.log(selectedOptionSix)
  };
  const handleCheckboxChangeSeven = (event) => {
    setSelectedOptionSeven(event.target.value);
    console.log(selectedOptionSeven)
  };
  const handleCheckboxChangeEight = (event) => {
    setSelectedOptionEight(event.target.value);
    console.log(selectedOptionEight)
  };
  const handleCheckboxChangeNine = (event) => {
    setSelectedOptionNine(event.target.value);
    console.log(selectedOptionNine)
  };
  const handleCheckboxChangeTen = (event) => {
    setSelectedOptionTen(event.target.value);
    console.log(selectedOptionTen)
  };
  const handleCheckboxChangeEleven = (event) => {
    setSelectedOptionEleven(event.target.value);
    console.log(selectedOptionEleven)
  };
  const handleCheckboxChangeTwelve = (event) => {
    setSelectedOptionTwelve(event.target.value);
    console.log(selectedOptionTwelve)
  };
  const handleCheckboxChangeThirtheen = (event) => {
    setSelectedOptionThirteen(event.target.value);
    console.log(selectedOptionThirteen)
  };


  const {t} = useTranslation()

  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }

    const handleQuestionaireSubmit=(e)=>{
      e.preventDefault();
      setShowPopupModal(true);
      if(selectedOptionEight && selectedOptionEleven && selectedOptionFive&& selectedOptionFour && selectedOptionNine && selectedOptionOne && selectedOptionSeven && selectedOptionSix && selectedOptionTen && selectedOptionThirteen && selectedOptionThree && selectedOptionTwelve && selectedOptionTwo){
        setPopUp(`${t('surveyPopup1')}`)

        const timeout = setTimeout(() => {
          // Redirect to the login page after 3 seconds
          navigate('/login');
        }, 5000);

      }else{
        setShowPopupModal(true)
        setPopUp(`${t('surveyPopup2')}`)
      }
      
    }
  

  return (
    <div>
        {loading ? <Loader /> : null}
      <TopTag />
      <Navbar />

          {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}

      <div style={{margin:'150px auto' , maxWidth:'80%', }} className='w-full p-5'>

        <form className='pr-5' onSubmit={handleQuestionaireSubmit}>
          <h1 style={{fontSize:'30px', fontWeight:'bold', textAlign:'center', backgroundColor:'green', padding:'10px', color:'white', margin:'50px auto'}}>{t('signupSurveyTitle')}</h1>
          <div >


                <div className=''>
                <h2 className='text-xl mb-5'>1. {t('surveyQuestion1')}</h2>
                <div className='flex items-center'>
                <input
                  value="Very Conservative"
                  checked={selectedOptionOne === 'Very Conservative'}
                  onChange={handleCheckboxChangeOne}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer1A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Conservative"
                  checked={selectedOptionOne === 'Conservative'}
                  onChange={handleCheckboxChangeOne} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer1B')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Moderate"
                  checked={selectedOptionOne === 'Moderate'}
                  onChange={handleCheckboxChangeOne} className='w-5 h-5 ' type="checkbox"  name='option3' id='option3' />
                <label className='ml-2 text-black text-lg'  htmlFor="option3">{t('surveyAnswer1C')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Aggressive"
                  checked={selectedOptionOne === 'Aggressive'}
                  onChange={handleCheckboxChangeOne} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer1D')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Very Aggressive"
                  checked={selectedOptionOne === 'Very Aggressive'}
                  onChange={handleCheckboxChangeOne} className='w-5 h-5 ' type="checkbox"  name='option5' id='option5' />
                <label className='ml-2 text-black text-lg'  htmlFor="option5">{t('surveyAnswer1E')}</label>
                </div>


                </div>

                {/* ------- */}

                <div className=''>
                <h2 className='text-xl my-5'>2. {t('surveyQuestion2')}</h2>
                <div className='flex items-center'>
                <input
                  value="Wealth accumulation"
                  checked={selectedOptionTwo === 'Wealth accumulation'}
                  onChange={handleCheckboxChangeTwo}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer2A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Retirement planning"
                  checked={selectedOptionTwo === 'Retirement planning'}
                  onChange={handleCheckboxChangeTwo} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer2B')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Education funding"
                  checked={selectedOptionTwo === 'Education funding'}
                  onChange={handleCheckboxChangeTwo} className='w-5 h-5 ' type="checkbox"  name='option3' id='option3' />
                <label className='ml-2 text-black text-lg'  htmlFor="option3">{t('surveyAnswer2C')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Real estate investment"
                  checked={selectedOptionTwo === 'Real estate investment'}
                  onChange={handleCheckboxChangeTwo} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer2D')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Other"
                  checked={selectedOptionTwo === 'Other'}
                  onChange={handleCheckboxChangeTwo} className='w-5 h-5 ' type="checkbox"  name='option5' id='option5' />
                <label className='ml-2 text-black text-lg'  htmlFor="option5">{t('surveyAnswer2E')}</label>
                </div>


                </div>

                {/* ------ */}
                   {/* ------- */}

                   <div className=''>
                <h2 className='text-xl my-5'>3. {t('surveyQuestion3')}</h2>
                <div className='flex items-center'>
                <input
                  value="Short-term (0-3 years)"
                  checked={selectedOptionThree === 'Short-term (0-3 years)'}
                  onChange={handleCheckboxChangeThree}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer3A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Medium-term (3-7 years)"
                  checked={selectedOptionThree === 'Medium-term (3-7 years)'}
                  onChange={handleCheckboxChangeThree} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer3B')}</label>
                </div>


                <div className='flex items-center'>
                <input 
                  value="Long-term (7+ years)"
                  checked={selectedOptionThree === 'Long-term (7+ years)'}
                  onChange={handleCheckboxChangeThree} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer3C')}</label>
                </div>

               


                </div>

                {/* ------ */}

                    {/* ------- */}

                    <div className=''>
                <h2 className='text-xl my-5'>4. {t('surveyQuestion4')}</h2>
                <div className='flex items-center'>
                <input
                  value="Stocks"
                  checked={selectedOptionFour === 'Stocks'}
                  onChange={handleCheckboxChangeFour}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer4A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Bonds"
                  checked={selectedOptionFour === 'Bonds'}
                  onChange={handleCheckboxChangeFour} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer4B')}</label>
                </div>


                <div className='flex items-center'>
                <input 
                  value="Real Estate"
                  checked={selectedOptionFour === 'Real Estate'}
                  onChange={handleCheckboxChangeFour} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer4C')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Cash"
                  checked={selectedOptionFour === 'Cash'}
                  onChange={handleCheckboxChangeFour} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer4D')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Other"
                  checked={selectedOptionFour === 'Other'}
                  onChange={handleCheckboxChangeFour} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer4E')}</label>
                </div>

               


                </div>

                {/* ------ */}


                    {/* ------- */}

                    <div className=''>
                <h2 className='text-xl my-5'>5. {t('surveyQuestion5')}</h2>
                <div className='flex items-center'>
                <input
                  value="High liquidity"
                  checked={selectedOptionFive === 'High liquidity'}
                  onChange={handleCheckboxChangeFive}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer5A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Moderate liquidity"
                  checked={selectedOptionFive === 'Moderate liquidity'}
                  onChange={handleCheckboxChangeFive} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer5B')}</label>
                </div>


                <div className='flex items-center'>
                <input 
                  value="Low liquidity"
                  checked={selectedOptionFive === 'Low liquidity'}
                  onChange={handleCheckboxChangeFive} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer5C')}</label>
                </div>


                </div>

                {/* ------ */}
                  {/* ------- */}

                  <div className=''>
                <h2 className='text-xl my-5'>6. {t('surveyQuestion6')}</h2>
                <div className='flex items-center'>
                <input
                  value="Beginner"
                  checked={selectedOptionSix === 'Beginner'}
                  onChange={handleCheckboxChangeSix}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer6A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Intermediate"
                  checked={selectedOptionSix === 'Intermediate'}
                  onChange={handleCheckboxChangeSix} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer6B')}</label>
                </div>


                <div className='flex items-center'>
                <input 
                  value="Advanced"
                  checked={selectedOptionSix === 'Advanced'}
                  onChange={handleCheckboxChangeSix} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer6C')}</label>
                </div>


                </div>

                {/* ------ */}
                      {/* ------- */}

                      <div className=''>
                <h2 className='text-xl my-5'>7. {t('surveyQuestion7')}</h2>
                <div className='flex items-center'>
                <input
                  value="High tax bracket"
                  checked={selectedOptionSeven === 'High tax bracket'}
                  onChange={handleCheckboxChangeSeven}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer7A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Medium tax bracket"
                  checked={selectedOptionSeven === 'Medium tax bracket'}
                  onChange={handleCheckboxChangeSeven} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer7B')}</label>
                </div>


                <div className='flex items-center'>
                <input 
                  value="Low tax bracket"
                  checked={selectedOptionSeven === 'Low tax bracket'}
                  onChange={handleCheckboxChangeSeven} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer7C')}</label>
                </div>


                </div>

                {/* ------ */}
                       {/* ------- */}

                       <div className=''>
                <h2 className='text-xl my-5'>8. {t('surveyQuestion8')}</h2>
                <div className='flex items-center'>
                <input
                  value="Active management"
                  checked={selectedOptionEight === 'Active management'}
                  onChange={handleCheckboxChangeEight}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer8A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Passive management"
                  checked={selectedOptionEight === 'Passive management'}
                  onChange={handleCheckboxChangeEight} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer8B')}</label>
                </div>


                <div className='flex items-center'>
                <input 
                  value="Socially responsible investing"
                  checked={selectedOptionEight === 'Socially responsible investing'}
                  onChange={handleCheckboxChangeEight} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer8C')}</label>
                </div>


                </div>

                {/* ------ */}
                       {/* ------- */}

                       <div className=''>
                <h2 className='text-xl my-5'>9. {t('surveyQuestion9')}</h2>
                <div className='flex items-center'>
                <input
                  value="Bullish"
                  checked={selectedOptionNine === 'Bullish'}
                  onChange={handleCheckboxChangeNine}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer9A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Bearish"
                  checked={selectedOptionNine === 'Bearish'}
                  onChange={handleCheckboxChangeNine} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer9B')}</label>
                </div>


                <div className='flex items-center'>
                <input 
                  value="Neutral"
                  checked={selectedOptionNine === 'Neutral'}
                  onChange={handleCheckboxChangeNine} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer9C')}</label>
                </div>


                </div>

                {/* ------ */}
                     {/* ------- */}

                     <div className=''>
                <h2 className='text-xl my-5'>10. {t('surveyQuestion10')}</h2>
                <div className='flex items-center'>
                <input
                  value="Beginner"
                  checked={selectedOptionTen === 'Beginner'}
                  onChange={handleCheckboxChangeTen}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer10A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Intermediate"
                  checked={selectedOptionTen === 'Intermediate'}
                  onChange={handleCheckboxChangeTen} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer10B')}</label>
                </div>


                <div className='flex items-center'>
                <input 
                  value="Advanced"
                  checked={selectedOptionTen === 'Advanced'}
                  onChange={handleCheckboxChangeTen} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer10C')}</label>
                </div>


                </div>

                {/* ------ */}

                 {/* ------- */}

                 <div className=''>
                <h2 className='text-xl my-5'>11. {t('surveyQuestion11')}</h2>
                <div className='flex items-center'>
                <input
                  value="Ethical constraints"
                  checked={selectedOptionEleven === 'Ethical constraints'}
                  onChange={handleCheckboxChangeEleven}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer11A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Religious constraints"
                  checked={selectedOptionEleven === 'Religious constraints'}
                  onChange={handleCheckboxChangeEleven} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer11B')}</label>
                </div>


                <div className='flex items-center'>
                <input 
                  value="Legal constraints"
                  checked={selectedOptionEleven === 'Legal constraints'}
                  onChange={handleCheckboxChangeEleven} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer11C')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="None"
                  checked={selectedOptionEleven === 'None'}
                  onChange={handleCheckboxChangeEleven} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer11D')}</label>
                </div>


                </div>

                {/* ------ */}

                  {/* ------- */}

                  <div className=''>
                <h2 className='text-xl my-5'>12. {t('surveyQuestion12')}</h2>
                <div className='flex items-center'>
                <input
                  value="< 5%"
                  checked={selectedOptionTwelve === '< 5%'}
                  onChange={handleCheckboxChangeTwelve}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer12A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="5-10%"
                  checked={selectedOptionTwelve === '5-10%'}
                  onChange={handleCheckboxChangeTwelve} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer12B')}</label>
                </div>


                <div className='flex items-center'>
                <input 
                  value="10-15%"
                  checked={selectedOptionTwelve === '10-15%'}
                  onChange={handleCheckboxChangeTwelve} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer12C')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value=">15%"
                  checked={selectedOptionTwelve === '>15%'}
                  onChange={handleCheckboxChangeTwelve} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer12D')}</label>
                </div>


                </div>

                {/* ------ */}

                  {/* ------- */}

                  <div className=''>
                <h2 className='text-xl my-5'>13. {t('surveyQuestion13')}</h2>
                <div className='flex items-center'>
                <input
                  value="Individual stocks"
                  checked={selectedOptionThirteen === 'Individual stocks'}
                  onChange={handleCheckboxChangeThirtheen}
                className='w-5 h-5' type="checkbox" name="option1" id="option1" />
                <label className='ml-2 text-black text-lg' htmlFor="option1">{t('surveyAnswer13A')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Mutual funds"
                  checked={selectedOptionThirteen === 'Mutual funds'}
                  onChange={handleCheckboxChangeThirtheen} className='w-5 h-5 ' type="checkbox"  name='option2' id='option2' />
                <label className='ml-2 text-black text-lg'  htmlFor="option2">{t('surveyAnswer13B')}</label>
                </div>


                <div className='flex items-center'>
                <input 
                  value="Exchange-traded funds (ETFs)"
                  checked={selectedOptionThirteen === 'Exchange-traded funds (ETFs)'}
                  onChange={handleCheckboxChangeThirtheen} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer13C')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Real estate investment trusts (REITs)"
                  checked={selectedOptionThirteen === 'Real estate investment trusts (REITs)'}
                  onChange={handleCheckboxChangeThirtheen} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer13D')}</label>
                </div>

                <div className='flex items-center'>
                <input 
                  value="Fixed-income securities"
                  checked={selectedOptionThirteen === 'Fixed-income securities'}
                  onChange={handleCheckboxChangeThirtheen} className='w-5 h-5 ' type="checkbox"  name='option4' id='option4' />
                <label className='ml-2 text-black text-lg'  htmlFor="option4">{t('surveyAnswer13E')}</label>
                </div>


                </div>

                {/* ------ */}

          </div>

          <button 
          style={{margin:'50px'}}
         
         className={styles.glowOnHover} 
       
         >{t('surveySubmitButton')}</button>
         

        </form>

      </div>

      <Footer />
    </div>
  )
}

export default SurveyQuestionnaire
