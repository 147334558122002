import {createSlice} from '@reduxjs/toolkit'


const initialState = {
 
  portfolioDetails: JSON.parse(localStorage.getItem('portfolioDetails')) || {}, // for user object

}

export const portfolioSlice = createSlice({
    name:'portfolio',
    initialState,
    reducers:{
     
        setPortfolioDetails: (state,action)=>{ 
            state.portfolioDetails = action.payload  ;
                  // Save to localStorage
      localStorage.setItem('portfolioDetails', JSON.stringify(action.payload));
        },
    
        clearPortfolioInfo: (state) => {
            state.portfolioDetails = {};
      
            // Remove from localStorage
            localStorage.removeItem('portfolioDetails');
          },
       
    }
});

export const {  
    setPortfolioDetails,
    clearPortfolioInfo
 } = portfolioSlice.actions


 export const selectPortfolioDetails = (state) => state.portfolio.portfolioDetails;
  
  


export default portfolioSlice.reducer;