import React,{useEffect} from 'react'
import Footer from '../components/footer/Footer'
import HowWeInvestDetails from '../components/howWeInvestDetails/HowWeInvestDetails'
import Navbar from '../components/navbar/Navbar'
import PageColumn from '../components/pageColumn/PageColumn'
import './howWeInvest.css'
import LowRisk from '../components/lowRisk/LowRisk'
import TopTag from '../components/TopTag'
import { useTranslation } from 'react-i18next'

const HowWeInvest = () => {
  const {t} = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <>
    <TopTag /> 
     <Navbar />
    <div className='who-we-are-container'>
     
      <PageColumn highlight={t('howWeInvestPageHero')} />
      </div>
        <LowRisk />
      <HowWeInvestDetails />
     
      <Footer />
    </>
  )
}

export default HowWeInvest
