import React from 'react'
import './howWeInvest.css'
import VillaIcon from '@mui/icons-material/Villa';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import coinpng from '../../assets/coin.png'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import CompareIcon from '@mui/icons-material/Compare';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useTranslation } from 'react-i18next'
import hwiImage from '../../assets/corpoImage.jpg'

const HowWeInvest = () => {
  const {t} = useTranslation()
  return (
    <div>
      <div>

        <img style={{width:'100%', height:window.innerWidth < 960 ? '400px' : '650px',objectFit:'cover', borderRadius:'0%'}} src={hwiImage} alt="Our Image" />

      </div>

      <div style={{width:'80%', margin:'50px auto'}}>
      <h1 className='text-center text-2xl font-bold bg-green-900 text-white'> {t('howWeInvestTitle')}</h1>
        <p className='text-xl text-center py-4'>{t('howWeInvestDe')}</p>
      </div>

      <div className="hwi-right">
            <div className="hwi-right-each">
              <h3>{t('realEstate')}</h3>
              <VillaIcon  className="hwi-right-each-icon"/>
            </div>
            <div className="hwi-right-each-two">
              <h3>{t('insurance')}</h3>
              <MedicalInformationIcon  className="hwi-right-each-icon"/>
            </div>
            <div className="hwi-right-each">
              <h3>{t('gold')}</h3>
              <img src={coinpng}  className="hwi-right-each-icon"/>
            </div>
            <div className="hwi-right-each-two">
              <h3>{t('oil')}</h3>
              <LocalGasStationIcon  className="hwi-right-each-icon"/>
            </div>
            <div className="hwi-right-each">
              <h3>{t('technologicalInnovations')}</h3>
              <CompareIcon  className="hwi-right-each-icon"/>
            </div>
            <div className="hwi-right-each-two">
              <h3>{t('stocks')}</h3>
              <ShowChartIcon  className="hwi-right-each-icon"/>
            </div>
            
        </div>

    <div className='how-we-invest-container'>

        {/* <div className="hwi-left">
        <h1> {t('howWeInvestTitle')}</h1>
        <p>{t('howWeInvestDe')}</p>
        </div> */}
        {/* <div className="hwi-right">
            <div className="hwi-right-each">
              <h3>{t('realEstate')}</h3>
              <VillaIcon  className="hwi-right-each-icon"/>
            </div>
            <div className="hwi-right-each">
              <h3>{t('insurance')}</h3>
              <MedicalInformationIcon  className="hwi-right-each-icon"/>
            </div>
            <div className="hwi-right-each">
              <h3>{t('gold')}</h3>
              <img src={coinpng}  className="hwi-right-each-icon"/>
            </div>
            <div className="hwi-right-each">
              <h3>{t('oil')}</h3>
              <LocalGasStationIcon  className="hwi-right-each-icon"/>
            </div>
            <div className="hwi-right-each">
              <h3>{t('technologicalInnovations')}</h3>
              <CompareIcon  className="hwi-right-each-icon"/>
            </div>
            <div className="hwi-right-each">
              <h3>{t('stocks')}</h3>
              <ShowChartIcon  className="hwi-right-each-icon"/>
            </div>
            
        </div>
       */}
     
    </div>
    </div>
  )
}

export default HowWeInvest
