import React from 'react'
import { Link } from 'react-router-dom'
import styles from './borrowDetails.module.css'
import helpingHand from '../../assets/helpingHand.jpg'
import loanChart from '../../assets/loanChart.png'
import loanImage1 from '../../assets/loan.jpg'
import loanImage2 from '../../assets/loan2.png'
import loanImage3 from '../../assets/loan3.jpg'
import { useTranslation } from 'react-i18next'

const BorrowDetails = () => {
  const {t} = useTranslation()

  return (
    <div className={styles.borrowDetailsContainer}>

      <div className={styles.borrowDetailsTop}>
        {/* <div className={styles.borrowDetailsTopLeft}>
        <img style={{width:300, height:400}} src={helpingHand} alt="borrow" />
        </div> */}
       
        <div className={styles.borrowDetailsTopRight}>
          <div style={{float:'right'}}>
          <Link className={styles.applyLoan} to='/login'>{t('borrowActionButton')}</Link>
          </div>
            
             <p className={styles.borrowHighlight}>{t('borrowPageParaOne')}</p>
             <span className='text-gray-500'>{t('borrowPageParaLink')} <a style={{color:'green'}} className='font-bold' href="mailTo:info@crownstreetholdings.com">{t('borrowPageParaContact')}</a></span>
             <div className={styles.loanNumbers}>
                <h6>{t('borrowPageParaTwoA')} <strong>{t('borrowPageParaTwoB')}</strong> {t('borrowPageParaTwoC')}</h6>
                <h6>{t('borrowPageParaThreeA')} <strong>{t('borrowPageParaThreeB')}</strong> {t('borrowPageParaThreeC')}</h6>
             </div>
             <img style={{marginTop:20}} src={loanChart} alt="loan chart" />
        </div>
      </div> 

    <div className={styles.borrowMiddleContainer}>
        <h1>{t('borrowPageTitle')}</h1>
        <p>{t('borrowPageParaFour')}</p>
              <div className={styles.borrowInnerImageContainer}>

                <div className=''>
                <h3 className='text-2xl font-bold mt-10 pl-4'>{t('borrowPageTitleTwo')}</h3>
                <img src={loanImage1} alt="loan image" />
                </div>
                <div className='flex-1 pt-10'>
                 <p className='mt-11 text-xl'>{t('borrowPageParaFive')} </p>
                </div>
               
              </div>
           
              <div className={styles.borrowInnerImageContainerReverse}>

                <div className=''>
                <h3 className='text-2xl font-bold mt-10 pl-4'>{t('borrowPageTitleThree')}</h3>
                <img src={loanImage3} alt="loan image" />
                </div>
                <div className='flex-1 pt-10'>
                <p className='mt-11 text-xl'>{t('borrowPageParaSix')}</p>
                </div>
              
                </div>

                <div className={styles.borrowInnerImageContainer}>

                <div className=''>
                <h3 className='text-2xl font-bold mt-10 pl-4'>{t('borrowPageTitleFour')}</h3>
                <img src={loanImage2} alt="loan image" />
                </div>
                <div className='flex-1 pt-10'>
                <p className='text-xl mt-11 '>{t('borrowPageParaSeven')}</p>
                </div>
             
                </div>
                        


           

            
             
    </div>

    </div>
  )
}

export default BorrowDetails
