import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import WalletSlideColumn from '../components/WalletSlideColumn'
import ChooseTransferColumn from '../components/ChooseTransferColumn'
import Sidebar from '../components/sidebar/Sidebar'
import './chooseTransfer.css'

const ChooseTransfer = () => {
  return (
    <div className='chooseContainer'>
         <Sidebar />
    <div className="chooseInnercontainer">
    
    <DashboardNavbar />
        <WalletSlideColumn />
        <ChooseTransferColumn />

      </div>
    </div>
  )
}

export default ChooseTransfer
