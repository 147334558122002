import React, { useEffect } from 'react'
import { useState } from 'react'
import './howWeBenefitSlide.css'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next'

const HowWeBenefitSlide = () => {

     const [curr, setCurr] = useState(0);
     const {t} = useTranslation()

     const testimonialSlides = [
       {id: 1,
        testimonial:  `${t('testimonial21')}`,
        company : `${t('company1')}`
      },
      {id: 2,
        testimonial:  `${t('testimonial22')}`,
        company : `${t('company2')}`
      },
      {id: 3,
        testimonial:  `${t('testimonial23')}`,
        company : `${t('company3')}`
      },
      {id: 4,
        testimonial:  `${t('testimonial24')}`,
        company : `${t('company4')}`
      },
      {id: 5,
        testimonial:  `${t('testimonial25')}`,
        company : `${t('company5')}`
      },
     ] 
     let autoSlide = true;
     let autoSlideInterval = 5000;

     const prev = () =>
     setCurr((curr) => (curr === 0 ? testimonialSlides.length - 1 : curr - 1))
   const next = () =>
     setCurr((curr) => (curr === testimonialSlides.length - 1 ? 0 : curr + 1))
 
   useEffect(() => {
     if (!autoSlide) return
     const slideInterval = setInterval(next, autoSlideInterval)
     return () => clearInterval(slideInterval)
   }, [])

  return (
    <div className='testimonial-container'>
      <h3>{t('testimonialTitleSlide')}</h3>
      <div className="next-prev">
        <ArrowBackIosIcon onClick={prev} className='test-button' />
        <ArrowForwardIosIcon onClick={next} className='test-button' />
      </div>

      {
        testimonialSlides.map((testimonialData,index)=>(
          <div key={testimonialData.id} className={index === curr ? 'slide happen' : 'slide'}>

        {index === curr && (
          <>
               <p>"{testimonialData.testimonial}"</p>
               <strong>{testimonialData.company}</strong>
            </>
        )}
          </div>
        ))
      }
      
    </div>
  )
}

export default HowWeBenefitSlide
