import React, { useState, useEffect } from 'react'
import styles from './transfersColumn.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import techHandImage from '../assets/transferImg.jpg'
import { IoArrowUndo } from "react-icons/io5";
import {useLocation, useNavigate} from 'react-router-dom'
import Loader from './Loader'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import OtpInput from 'react-otp-input';

const TransferFromWalletAmountComponent = () => {
  const [showModal, setShowModal] = useState(false)
  const location = useLocation();
  const {state} = location;
  const [debitWalletType, setDebitWalletType ] = useState('')
  const [destinationEmail, setDestinationEmail] = useState('')
  const [amount, setAmount] = useState(0)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const userInfo = useSelector(selectUserInfo)
  const [popUp, setPopUp] = useState('')
  const [showPopupModal, setShowPopupModal] = useState(false)
  const [transferOtp, setTransferOtp] = useState('')
  const [transferID, setTransferID] = useState('')
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
  const [showOTPModal, setShowOTPModal] = useState(false)
  const [otp, setOtp] = useState('');

  const {t} = useTranslation()

  console.log(userInfo.user.name)
  console.log(userInfo.user.mainWallet)


  useEffect(()=>{
    setDestinationEmail(state.destinationEmail)
  },[])


  console.log(destinationEmail)

  // ${t('transferToPopupSuccess1')}

  const handleMakeTransfer =(e)=>{
    e.preventDefault()

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

  
    setLoading(true)
    console.log(userInfo.user.mainWallet)

    if(amount === 0){

      setPopUp('Type an amount')
      setShowPopupModal(true)
      setLoading(false)
      return
     }

     if(amount > userInfo.user.mainWallet){

      console.log(amount)
      setPopUp('Insufficient Fund')
      setShowPopupModal(true)
      setLoading(false)
      return
     }

   if(amount){
    var formdata = new FormData();
    formdata.append("amount", amount);
    formdata.append("debitWallet", 'toAnotherUser');
    formdata.append("email", destinationEmail);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${API_ENDPOINT}/api/transfers/initiate-transfer-to-another-account`, requestOptions)
      .then(response => response.json())
      .then(result =>{
        console.log(result)
        if(result.message === 'Transfers initialized' ){
          setLoading(false)
           console.log(result)
            setTransferOtp(result.transfer.transferToken)
            setTransferID(result.transfer._id)
            setShowOTPModal(true)
           const form = e.target;
           form.reset()

         
        }
        else {
          setPopUp(`${t('transferFromMainWalletFail4')}`)
          setShowPopupModal(true)
        }
       setLoading(false)
      })
      .catch(error => console.log('error', error));


  
 
  
   }else{
    setPopUp(`${t('transferFromMainWalletFail5')}`)
    setShowPopupModal(true)
    setLoading(false)
    return
   }
  }


  const handleChangeOtp = async(value) => {
    // console.log(value);
    setOtp(value)
      // Ensure that the latest value is used
      if (value.length === 4) {
        // verifyToken(prevOtp);
       
        setLoading(true)
        // console.log(value)
        if(Number(value) === transferOtp){

          console.log('tokens match')

              var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);


             var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      // body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${API_ENDPOINT}/api/transfers/transfer-to-another-account/${transferID}`, requestOptions)
      .then(response => response.json())
      .then(result =>{
        console.log(result)
        if(result.message === 'Transfers successful' ){
          setLoading(false)
           console.log(result)
           setPopUp(`${t('transferToPopupSuccess3')} ${destinationEmail}`)
           setShowPopupModal(true)
           

           setTimeout(()=> navigate('/dashboard'),3000)
        }
        else {
          setPopUp(`${t('transferFromMainWalletFail4')}`)
          setShowPopupModal(true)
        }
       setLoading(false)
      })
      .catch(error => console.log('error', error));


         
          // console.log(value)
        }else{
          setPopUp(`${t('LoginResponse3A')}`)
          setShowPopupModal(true)
          setLoading(false)
          return
        }
        setLoading(false)

      }
      return value;

  };

  // const handleMakeTransfer =(e)=>{
  //   e.preventDefault()


  
  //   setLoading(true)
  //   console.log(userInfo.user.mainWallet)

  //   if(amount === 0){

  //     setPopUp('Type an amount')
  //     setShowPopupModal(true)
  //     setLoading(false)
  //     return
  //    }

  //    if(amount > userInfo.user.mainWallet){

  //     console.log(amount)
  //     setPopUp('Insufficient Fund')
  //     setShowPopupModal(true)
  //     setLoading(false)
  //     return
  //    }

  //  if(amount){
  //   var formdata = new FormData();
  //   formdata.append("amount", amount);
  //   formdata.append("debitWallet", 'toAnotherUser');
  //   formdata.append("email", destinationEmail);
    
 
  
 
  
  //  }else{
  //   setPopUp(`${t('transferFromMainWalletFail5')}`)
  //   setShowPopupModal(true)
  //   setLoading(false)
  //   return
  //  }
  // }

  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }
  


  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);

  return (
    <>

{loading ? <Loader /> : null}

{/* popup */}
{ showPopupModal && 
<div className={styles.popContainer}>

<div className={styles.innerPopContainer}>
< FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
 <p className={styles.popText}>{popUp}</p>
<button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
</div>


</div>
}
{/* popup ends */}
    
    

    <div className={styles.transferImagesContainer}>

    <img
            src={techHandImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
     
            />
            <div className={styles.transferContainer}>

            <div style={{maxHeight:'max-content'}} className='flex gap-3 my-11 mx-11 p-5 items-center '>
          
            <form onSubmit={handleMakeTransfer} style={{width:'300px', margin:'auto'}} className='flex flex-col gap-3 '>
            <h1 className='text-2xl font-bold pb-6 pt-2 text-center text-white sm:text-4xl'>{t('chooseTransfersFromMain')}</h1>
              {/* <label className={styles.emailText} htmlFor="email">Amount</label> */}
              <input
              onChange={(e)=>setAmount(e.target.value)}
               style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:10, outline:'none'}}
                placeholder={t('purchasePortfolioPageAmount')}
               type="number" name='amount' />
             
              <button className={styles.loginButton} type="submit">{t('transferFromMainWalletButton')}</button>
            
            </form>
          </div>
    
       {/* { showModal &&  <div className={styles.modal}>
         < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
          <div className='flex gap-3 my-11 bg-sky-200 mx-11 p-5 items-center'>
            <div>
            <img
              src={robot}
              style={{ width: 600, height: 400, zIndex:10, objectFit:'contain' }} // optional
              alt='welcome image' 
            />
            </div>
            <form style={{width:'300px'}} className='flex flex-col gap-3 ' action="">
              <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>Transfers</h1>
              <label className={styles.emailText} htmlFor="email">Amount</label>
              <input style={{backgroundColor:'white', height:'40px', width:'100%'}} type="email" name='email' />
             
              <button className={styles.loginButton} type="submit">Transfer</button>
            
            </form>
          </div>
    
          </div> } */}
              </div>


      {showOTPModal && <div className={styles.modal}>

<div style={{marginTop:200}} className='flex flex-col gap-3 my-11 bg-sky-100/50 mx-11 p-7 items-center backdrop-filter backdrop-blur-lg bg-opacity-30'>


    <h2 className='text-2xl mb-7 text-center font-bold text-white'>{t('forgetPasswordVerifyTokenTitle')}</h2>
    <OtpInput
      containerStyle={{ margin: 20 }}
      inputStyle={{ width: 60, height: 60, borderRadius: 10, margin: 10, backgroundColor:'white' }}
      shouldAutoFocus
      value={otp}
      inputType="tel"
      onChange={handleChangeOtp}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} />}
      // renderInput={(props, index) => (
      //   <input
      //     {...props}
      //     onFocus={(e) => {
      //       // Move cursor to the end on focus
      //       e.target.setSelectionRange(e.target.value.length, e.target.value.length);
      //     }}
      //   />
      // )}
    />

    </div>

</div> 
    }
            
          
        </div>
        </>
  )
}

export default TransferFromWalletAmountComponent
