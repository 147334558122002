import React from 'react'
import './howWeInvestDetails.css'
import diversity from '../../assets/diversity.png'
import esg from '../../assets/esg.jpg'
import esg3 from '../../assets/esg3.png'
import esg4 from '../../assets/esg4.png'
import diversify from '../../assets/diversify.png'
import global from '../../assets/global.png'
import diversificationChart from '../../assets/diversificationChart.png'
import { useTranslation } from 'react-i18next'

const HowWeInvestDetails = () => {
    const {t} = useTranslation()
     
  return (
    <div className='how-we-invest-details'>
        <div className="hwid-inner-container">
            <img src={diversity} alt="diversity and inclusion" />
            <div className="hwid-inner-content">
                <h2>{t('howWeInvestPageDiversityTitle')}</h2>
                <p>{t('howWeInvestPageDiversityParaOne')}</p>
            </div>
        </div>
        <div className="hwid-inner-container-further">
            <p>{t('howWeInvestPageDiversityParaTwo')}</p>
            <img src={diversificationChart} alt="chart" />
        </div>


        <div className="hwid-inner-container">
            <img src={esg} alt="diversity and inclusion" />
            <div className="hwid-inner-content">
                <h2>{t('howWeInvestPageValueAddedTitle')}</h2>
                <p>{t('howWeInvestPageValueAddedParaOne')}</p>
            </div>
        </div>
        <div className="hwid-inner-container-further">
            <p>{t('howWeInvestPageValueAddedParaTwo')} </p>

              <img src={esg4} alt="" />

       <p> {t('howWeInvestPageValueAddedParaThree')} </p>
        </div>
        <div className="esg-tech">

        <img src={esg3} alt="esg picture" />

    <p>{t('howWeInvestPageValueAddedParaFour')}</p>

        </div>

        <div className="diversify-container">
        <img src={diversify} alt="diversify" />
            <div className="diversify-inner-content">
                <h2>{t('howWeInvestPageIntraTitle')}</h2>
                <p>{t('howWeInvestPageIntraPara')}</p>
                <p><span>{t('howWeInvestPageIntraSubTitle')}</span>: {t('howWeInvestPageIntraParaOne')}</p>
                <p><span>{t('howWeInvestPageIntraSubTitleTwo')}</span>: {t('howWeInvestPageIntraParaTwo')}</p>
                <p><span>{t('howWeInvestPageIntraSubTitleThree')}</span>: {t('howWeInvestPageIntraParaThree')}</p>
                <p><span>{t('howWeInvestPageIntraSubTitleFour')}</span>: {t('howWeInvestPageIntraParaFour')}</p>
        
            </div>
            
        </div>
        <div className="diversify-quote">
                <h1>"</h1>
                <p>{t('howWeInvestPageIntraQuote')} <span>Benjamin Graham</span></p>
                <h1>"</h1>
            </div>

            <div className="capital-investment-container">

            <img src={global} alt="diversity and inclusion" />
            <div className="capital-investment-inner-content">
                <h2>{t('howWeInvestPageGlobalTitle')}</h2>
                <p>{t('howWeInvestPageGlobalParaOne')}</p>
            </div>
        
            </div>

                <div className="gci-more">

                      <p>{t('howWeInvestPageGlobalTwo')}</p>
               

                </div>
          
     
     </div> 
  )
}

export default HowWeInvestDetails
