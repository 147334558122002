import React, { useState } from 'react'
import styles from './dashboardWithdrawal.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import passwordImage from '../assets/password.jpg'
import { Player } from '@lottiefiles/react-lottie-player';
import loaderSRC from '../assets/robotAnimation.json'
import inputSRC from '../assets/loadingAnimation.json'
import Loader from './Loader';
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import { selectUserInfo} from '../slices/authSlice';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const UpdatePasswordComponent = () => {
  
    const [newPassword, setNewPassword] = useState('')
    const [oldPassword, setOldPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [emailSpinner, setEmailSpinner] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isPasswordSecure, setIsPasswordSecure] = useState(true)
  const [isNewPasswordSecure, setIsNewPasswordSecure] = useState(true)
  const [isConfirmPasswordSecure, setIsConfirmPasswordSecure] = useState(true)
  const [popUp, setPopUp] = useState('')
  const [updateTrue, setUpdateTrue] = useState(false)
  const [showPopupModal, setShowPopupModal] = useState(false)
  const userInfo = useSelector(selectUserInfo)
  const navigate = useNavigate()

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

  const {t} = useTranslation()

  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
     if(updateTrue){
      navigate('/login')
     }
    }
  


    const handleSubmitPassword =(e)=>{
        e.preventDefault()
        // setShowCurrentPasswordModal(true)
        if(newPassword !== confirmPassword){

            setPopUp(`${t('updatePasswordPopup')}`)
            setShowPopupModal(true)
            return
        }
        if(!newPassword || !oldPassword || !confirmPassword){
          setPopUp(`${t('updatePasswordPopup2')}`)
          setShowPopupModal(true)
          return
        }

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

        var formdata = new FormData();
        formdata.append("oldPassword", oldPassword);
        formdata.append("newPassword", newPassword);
  
        var requestOptions = {
          method: 'PUT',
          headers: myHeaders,
          body: formdata,
          redirect: 'follow'
        };

        setLoading(true)
  
        fetch(`${API_ENDPOINT}/api/auth/update-password`, requestOptions)
          .then(response => response.json())
          .then(result => {
            setLoading(false)
          if(result.message === 'Password updated successfully'){
            setPopUp(`${t('updatePasswordPopup3')}`)
            setShowPopupModal(true)
           setUpdateTrue(true);
           
          } else if(result.error){
            setPopUp(`${result.error}`)
              setShowPopupModal(true)
          }
            console.log(result)
          })
          .catch(error =>{
            setLoading(false)
            if(error){
              setPopUp(`${error.error}`)
              setShowPopupModal(true)
            }
            console.log('error', error)});


      }

  return (
    <div style={{width:'100%',display:'flex', alignItems:'center', justifyContent:'center', padding:100}}>
       {/* popup */}
    { showPopupModal && 
   <div className='popContainer'>
       
        <div className='innerPopContainer'>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className='popClose' />
            <div className='flex gap-2 flex-col'>
            <Player
          src={loaderSRC}
          className="player"
          loop
          autoplay
           style={{ height: '50px', width: '50px' }}
          
          />
            <p className='popText'>{popUp}</p>
            </div>
           
            <button className='popButton' onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
  {loading ? <Loader /> : null}
    <div className={styles.downlineTransferContainer}>

      <div className={styles.left}>
        <img  src={passwordImage} alt="image" />
      </div>

    <form className={styles.right}>
          {/* <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>Withdrawal</h1> */}
          {/* <label className={styles.emailText} htmlFor="email">Enter Amount</label> */}
          <div className='relative'>
          <input
          value={oldPassword}
          onChange={(e)=>setOldPassword(e.target.value)}
          style={{ height:'50px', width:'100%',outline:'none'}}
           placeholder={t('updatePasswordOldPassword')} 
           className='bg-sky-100 mt-2 p-2'
           type={isPasswordSecure ? "password" : 'name'} name='oldPassword' />
              {isPasswordSecure ? <FaRegEye
            onClick={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray',  fontSize:'20px', cursor:'pointer'}}/>  
                               : <FaRegEyeSlash 
                               onClick={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray', fontSize:'20px', cursor:'pointer'}}/>
             }
          </div>
        
        <div className='relative'>
        <input 
          value={newPassword}
           onChange={(e)=>setNewPassword(e.target.value)}
          style={{ height:'50px', width:'100%',outline:'none'}} 
          placeholder={t('updatePasswordNewPassword')} 
          className='bg-sky-100 mt-2 p-2' type={isNewPasswordSecure ? "password" : 'name'}
          name='newPassword' />
           {isNewPasswordSecure ? <FaRegEye
            onClick={() => { isNewPasswordSecure ? setIsNewPasswordSecure(false) : setIsNewPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray',  fontSize:'20px', cursor:'pointer'}}/>  
                               : <FaRegEyeSlash 
                               onClick={() => { isNewPasswordSecure ? setIsNewPasswordSecure(false) : setIsNewPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray', fontSize:'20px', cursor:'pointer'}}/>
             }
        </div>
        
        <div style={{position:'relative'}} className='relative'>
        <input 
          value={confirmPassword}
           onChange={(e)=>setConfirmPassword(e.target.value)}
          style={{ height:'50px', width:'100%',outline:'none'}} 
          placeholder={t('updatePasswordConfirmPassword')}  className='bg-sky-100 mt-2 p-2 mb-5'
           type={isConfirmPasswordSecure ? "password" : 'name'} 
           name='confirmPassword' />

          {/* {isConfirmPasswordSecure ? <FaRegEye
            onClick={() => { isConfirmPasswordSecure ? setIsConfirmPasswordSecure(false) : setIsConfirmPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray',  fontSize:'20px', cursor:'pointer'}}/>  
                               : <FaRegEyeSlash 
                               onClick={() => { isConfirmPasswordSecure ? setIsConfirmPasswordSecure(false) : setIsConfirmPasswordSecure(true) }}
                             style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray', fontSize:'20px', cursor:'pointer'}}/>
             } */}
        </div>
        
         
          <button onClick={handleSubmitPassword} className={styles.loginButton} type="submit">{t('updatePasswordButton')}</button>
        </form>

    </div>
     


   {/* { showModal &&  <div className={styles.modal}>
     < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
      <div style={{width:'300px'}} className='flex gap-3 my-11 bg-sky-200 mx-11 p-5 items-center justify-center'>
    
        <form style={{width:'300px'}} className='flex flex-col gap-3 items-center justify-center m-auto' action="">
          <label className={styles.emailText} htmlFor="email">Enter Wallet</label>
          <input style={{backgroundColor:'white', height:'40px', width:'100%'}} type="email" name='email' />
          <button className={styles.loginButton} type="submit">Submit</button>
       

        </form>
      </div>

      </div> } */}
     
    </div>
  )
}

export default UpdatePasswordComponent
