import React from 'react'
import DashboardSidebar from '../components/DashboardSidebar'
import DashboardNavbar from '../components/DashboardNavbar'
import WalletSlideColumn from '../components/WalletSlideColumn'
import TransferToWalletColumn from '../components/TransferToWalletColumn'
import { IoArrowUndo } from "react-icons/io5";
import { useNavigate} from 'react-router-dom'
import styles from './transferFromWalletAmount.module.css'
import Sidebar from '../components/sidebar/Sidebar'
import { useTranslation } from 'react-i18next'

const TransferToWallet = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  return (
    <div className={styles.transfersContainer}>
    <Sidebar />
<div className={styles.transfersInnerContainer}>

<DashboardNavbar />
      <IoArrowUndo onClick={()=>navigate(-1)} className={styles.backButton} />
        <h1 className='text-xl font-bold ml-5 pl-10 text-gray-300 mb-2'>{t('chooseTransfersToMain')}</h1>

      {/* <WalletSlideColumn /> */}
      <TransferToWalletColumn />
      </div>
     
    </div>
  )
}

export default TransferToWallet
