import React from 'react'
import './portfolio.css'
import Sidebar from '../components/sidebar/Sidebar'
import DashboardNavbar from '../components/DashboardNavbar'
import PurchasePortfoliosColumn from '../components/PurchasePortfoliosColumn'
import { IoArrowUndo } from "react-icons/io5";
import { useNavigate} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Portfolio = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  return (
    <div className='portfolio-container'>

    <Sidebar />
    <div className="portfolio-innercontainer">
    
    <DashboardNavbar />
    <IoArrowUndo onClick={()=>navigate(-1)} className='backButton' />
        <h1 className='text-xl font-bold ml-5 pl-10 text-gray-300 mb-2'>{t('purchasePortfolioPageTitle')}</h1>


      <PurchasePortfoliosColumn />
        
      </div>
         
        
        </div>
  )
}

export default Portfolio
