import React from 'react'
import './ourMission.css'
import { useTranslation } from 'react-i18next'

const OurMission = () => {
  const {t} = useTranslation()

  return (
    <div className='our-mission-container'>
      <h1>{t('whoWeArePageMissionTitle')}</h1>
      <p>{t('whoWeArePageMissionContent')}</p>
    </div>
  )
}

export default OurMission
