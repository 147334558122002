import React,{useState} from 'react'
import styles from  './contact.module.css'
import { FaTelegram } from "react-icons/fa";
import { FaRegTimesCircle } from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import techHand from '../assets/techHand.jpg'
import { Player } from '@lottiefiles/react-lottie-player';
import robotAnimation from '../assets/crownRobotAni.json'
import { useTranslation } from 'react-i18next'

const ContactUsColumn = () => {

   
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)
    const [email, setEmail] = useState('')
    const navigate = useNavigate()
    const [removeEnterEmail, setRemoveEnterEmail] = useState(true)
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)


    const {t} = useTranslation()


    const handleClosePopup =()=>{
      setShowPopupModal(false)
       setPopUp('')
      }
     


    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

  
   
  
 
    console.log(email)
    const handleContactForm=(e)=>{
        e.preventDefault()

        if(!message && !email){
          setShowPopupModal(true)
          setPopUp(`${t('contactPagePopupFail')}`)
          return
        }
        setLoading(true)
        var formdata = new FormData();
        formdata.append("email", email);
        formdata.append("message", message);
        
        var requestOptions = {
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        
        fetch(`${API_ENDPOINT}/api/contactMessage/send-message`, requestOptions)
          .then(response => response.json())
          .then(result =>{   
              setLoading(false)
               console.log(result)
               setShowPopupModal(true)
               setPopUp(`${t('contactPagePopupSuccess')}`)
               const form = e.target;
               form.reset()
               setLoading(false)
            
          })
          .catch(error => console.log('error', error));
    
    }

  return (
    <div className={styles.imageContainer}  >
         {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
    {/* {loading ? <Loader /> : null} */}
          {/* <video className={styles.heroImage} autoPlay loop muted>
          <source src={videoFile} type='video/mp4' />
         </video> */}
         <img
        src={techHand}
        alt='welcome image'
        quality={100}
       className={styles.heroImage}

        />
      <div className={styles.text}>
      

        <div className={styles.contactInnerContainer}>

        {/* <div className='flex gap-3 bg-sky-100 p-4 w-fit text-black items-center m-auto cursor-pointer mt-8'>
        <FaTelegram className='font-bold text-3xl' />
        <p>Live chat with telegram bot</p>
        </div> */}
      
    <div className={styles.chatContainer}>

  <div className=''>
  <Player
          src={robotAnimation}
          className="player"
          loop
          autoplay
           style={{ height: '300px', width: '300px' }}
          
          />
  </div>
  <form
  onSubmit={handleContactForm}
   style={{width:'300px'}} className='flex flex-col gap-3'>
    {/* <h1 className='text-2xl font-bold pb-6 pt-2 text-center'>Send a Message</h1> */}
  <div className={styles.inputContainer}>

    <input
    // onKeyUp={debouncedAutoSubmitEmail}
    placeholder={t('contactPageEmail')}
    onFocus={()=> { setRemoveEnterEmail(false)}}
    onChange={(e)=>setEmail(e.target.value)}
     style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10, marginBottom:'20px'}}
      type="email" name='email'
      className={styles.emailInput}
      />
 <textarea
           onFocus={()=> { setRemoveEnterEmail(false)}}
            onChange={(e)=>setMessage(e.target.value)}
           style={{backgroundColor:'white', height:'100px', width:'100%', outline:'none', paddingLeft:10}} type="text" name='password'
            />
   {removeEnterEmail &&  <label className={styles.emailText} htmlFor="email">{t('contactPageMessage')}</label>}
      </div>
  <button
   
   className={styles.glowOnHover} type="submit">{t('contactPageButton')}</button>


  </form>
</div>

</div>

{/* { showModal &&  <div

  className={styles.modal}>
     < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
      <div className='flex gap-3 my-11 bg-sky-200/50 mx-11 p-5 items-center backdrop-filter backdrop-blur-lg bg-opacity-30'>
        <div className=''>
        <img
        src={robot}
         style={{width:600, height:400, objectFit:'contain'}}
         className={styles.loginImage}
        alt='welcome image'
        quality={100}
       

        />
        </div>
        <form
        
          style={{width:'300px'}} className='flex flex-col gap-3' >

          <label className={styles.emailText} htmlFor="email">Type Your Message</label>
          <textarea
           onFocus={()=> setError('')}
            onChange={(e)=>setPassword(e.target.value)}
           style={{backgroundColor:'white', height:'100px', width:'100%', outline:'none', paddingLeft:10}} type="text" name='password'
            />

          <button className={styles.btn} type="submit">Submit</button>
         
          { error && <p className='px-3 py2 font-bold bg-red-500 text-white w-fit rounded-md  text-xs'>{error}</p>}

        </form>
      </div>

      </div> } */}

      
      </div>

    

  </div>
  )
}

export default ContactUsColumn
