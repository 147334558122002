import React from 'react'
import DashboardSidebar from '../components/DashboardSidebar'
import DashboardNavbar from '../components/DashboardNavbar'
import WalletSlideColumn from '../components/WalletSlideColumn'
import TransferToWalletAmountComponent from '../components/TransferToWalletAmountComponent'
import { IoArrowUndo } from "react-icons/io5";
import {useLocation, useNavigate} from 'react-router-dom'
import styles from './transferFromWalletAmount.module.css'
import Sidebar from '../components/sidebar/Sidebar'

const TransferToWallet = () => {
    const navigate = useNavigate()
  return (
    <div className={styles.transfersContainer}>
    <Sidebar />
  <div className={styles.transfersInnerContainer}>

<DashboardNavbar />
      <IoArrowUndo onClick={()=>navigate(-1)} className={styles.backButton} />
      <TransferToWalletAmountComponent />
  </div>
    </div>
  )
}

export default TransferToWallet
