import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import PaymentSuccessComponent from '../components/PaymentSuccessComponent'
import styles from './transferFromWalletAmount.module.css'
import Sidebar from '../components/sidebar/Sidebar'

const PaymentSuccess = () => {
  return (
    <div className={styles.transfersContainer}>
    <Sidebar />
<div className={styles.transfersInnerContainer}>

<DashboardNavbar />
      <PaymentSuccessComponent />
      </div>
    </div>
  )
}

export default PaymentSuccess
