import i18n from 'i18next';
import Backend from 'i18next-http-backend';  //public/locales/{lang}/translation
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next'

// const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // backend: {
    //   // Specify the path to your server-side translations
    //   loadPath: `${API_ENDPOINT}/locales/{{lng}}/{{ns}}.json`,
    // },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
    },
  })
  .catch((err) => console.error('i18n initialization error:', err));

  export default i18n