import homeImage from '../assets/building.jpg'
import { Link, useNavigate } from 'react-router-dom'
import React, { useState, useContext, useEffect } from 'react'
import Navbar from '../components/navbar/Navbar'
import styles from './signup.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
// import { AuthContext } from '../context/AuthContext';
import { TickerTape } from "react-ts-tradingview-widgets";

import axios from 'axios'
import { Player } from '@lottiefiles/react-lottie-player';
import goodSRC from '../assets/goodAnimation.json'
import Footer from '../components/footer/Footer'
import Loader from '../components/Loader'
import aiAnimation from '../assets/crownRobotAni.json'
import TopTag from '../components/TopTag'
import { useTranslation } from 'react-i18next'

// implement referral     







// implement referral link 







const SignupWithReferral = () => {
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const [showPopupModal, setShowPopupModal] = useState(false)
  const [showPopupTwoModal, setShowPopupTwoModal] = useState(false)
  // const { err, dispatch} = useContext(AuthContext);
  const [popUp, setPopUp] = useState('')
  const [popUpTwo, setPopUpTwo] = useState('')
  const [removeEnterEmail, setRemoveEnterEmail] = useState(true)
  const [loading, setLoading] = useState(true)

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
  const [refCode, setRefCode] = useState('')

  const refUrlString = window.location.href ;
  const refUrl = new URL(refUrlString);
  const pathname = refUrl.pathname; // This will give you '/6564ad9ec0f4500e63ff1b61/9921'

  // To extract the two parts separately:
  const parts = pathname.split('/').filter(Boolean); // Split the pathname into parts
  const firstPart = parts[0]; // '6564ad9ec0f4500e63ff1b61'
  const secondPart = parts[1]; // '9921'


  const {t} = useTranslation()




  useEffect(()=>{
    setRefCode(parts[2])
  },[])
console.log(parts[2])



  const handleShowPasswordForm =async(e)=>{
    e.preventDefault()

    if(!email) {
      setError(`${t('signUpPopupFail3')}`)
      setPopUp(`${t('signUpPopupFail4')}`)
      setShowPopupModal(true)
      return
    }



  var formdata = new FormData();
  formdata.append("email", email);
  
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  setLoading(true)
  fetch(`${API_ENDPOINT}/api/auth/verify-email`, requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result.message)
      if(result.message === 'Email is available'){
        navigate('/signupWithReferralPassword',{state:{email:email, refCode:refCode}})
        setShowModal(true)
      }else if(result.message === 'Email already taken!'){
        setError(`${t('signUpPopupFail2')}`)
        setPopUp(`${t('signUpPopupFail')}`)
        setShowPopupModal(true)
        setLoading(false)
        return
      }
      console.log(result)})
    .catch(error => console.log('error', error));
    
      setLoading(false)
    console.log('email & password :',email, password)
  }




  // const handleSignup =async(e)=>{
  //   e.preventDefault()
  //   if(!password) {
  //     setError('Fill in your Password')
  //     return
  //   }

  //   var formdata = new FormData();
  //     formdata.append("email", email);
  //     formdata.append("password", password);

  //     var requestOptions = {
  //       method: 'POST',
  //       body: formdata,
  //       redirect: 'follow'
  //     };

  //     fetch("http://localhost:5000/api/auth/register", requestOptions)
  //       .then(response => response.json())
  //       .then(result => {
  //         console.log(result.message)
  //         if(result.message === 'Registration successful'){

  //           setShowModal(false)
  //           const form = e.target;
  //           form.reset()
  //           setShowPopupTwoModal(true)
  //           setPopUpTwo('Go to your provided email address to confirm your email, also make sure to refresh your email and also check your spam mails.')
  //           console.log(result)

  //         } else {
  //           setPopUp('Error registering user, Please check your internet and try again.')
  //         }
         
  //       })
  //       .catch(error => console.log('error', error));
   
  // //   try {



  // // const res =    await fetch('http://localhost:5000/api/auth/register', {
  // //       method: "POST",
  // //       headers: {
  // //         "Content-Type": 'application/json'
  // //       },
  // //       body: JSON.stringify({
  // //         email,
  // //         password
  // //       })
  // //     })

  // //     console.log('res--->', res)

  // //     if(res.ok){
  // //       const {user} = await res.json()
  // //       console.log(user)
       
  // //     }else{
  // //       console.log("User registration failed")
  // //     }
  // //   } catch (error) {
  // //     console.log("Error during registration", error)
  // //   }
  // //   console.log('email & password :',email, password)
  // }




const handleClosePopup =()=>{
  setShowPopupModal(false)
   setPopUp('')
  }

  const handleClosePopupTwo =()=>{
    setShowPopupTwoModal(false)
     setPopUpTwo('')
     navigate('/login')
    }
    useEffect(() => {
   
      setTimeout(() => {
        setLoading(false);
      }, 3000); 
    }, []);
  return (
    <div>
      <TopTag />
      {loading ? <Loader /> : null}
      {/* <TopTag /> */}
{/* <TickerTape   colorTheme="dark"></TickerTape> */}

      <Navbar />

         {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}


           {/* popup 2*/}
           { showPopupTwoModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={handleClosePopupTwo} className={styles.popClose} />
            <h1 className='p-2 bg-sky-800 text-white font-semibold text-2xl my-7'>{t('signUpRegistrationSuccessful')}</h1>
            <Player
          src={goodSRC}
          className="player"
          loop
          autoplay
           style={{ height: '50px', width: '50px' }}
          
          />
             <p className={styles.popText}>{popUpTwo}</p>
            <button className={styles.popButton} onClick={handleClosePopupTwo}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup 2 ends */}
      <div className={styles.imageContainer}  >
      <img
        src={homeImage}
        alt='welcome image'
       className={styles.heroImage}

        />
 
<div className={styles.loginContainer}>
      
      <div style={{width: window.innerWidth < 500 ? '100%' : '400px', margin:'auto', height:'300px'}} className='flex gap-3 my-2 mx-11 p-5 items-center'>
        <div>
       
          <Player
          src={aiAnimation}
          className="player"
          loop
          autoplay
           style={{ height: '100px', width: '100px' }}
          
          />
        </div>
        <form style={{width:window.innerWidth < 500 ? '80%' : '60%'}} className='flex flex-col gap-3 ' action="">
          <h1 className='text-4xl font-bold pb-6 pt-2 text-center text-white'>{t('signUpPageTitle')}</h1>
        
          <div className={styles.inputContainer}>
     
          <input 
          onFocus={()=>{setError(''); setRemoveEnterEmail(false)}}
          onChange={(e)=>setEmail(e.target.value)}
          className={styles.emailInput}
          placeholder={t('LoginPageEmail')}
          style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10}}
           type="email" name='email' />
        {/* {removeEnterEmail && <label className={styles.emailText} htmlFor="email">Enter Email_</label>} */}
          </div>
      
        <button 
         onClick={handleShowPasswordForm}
          className={styles.glowOnHover}
           type="submit">{t('LoginPageButton')}</button>
            {error && <p className='px-3 py2 font-bold bg-red-500/50 text-white w-fit rounded-md text-xs'>{error}</p>}
        <Link to='/login' className='text-sm text-white'>{t('signUpPageLink')} <strong className=''>{t('signUpPageLink2')}</strong></Link>
        </form>
      </div>

      </div>

    </div>
    <Footer />
    </div>
  )
}

export default SignupWithReferral
