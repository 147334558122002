import React from 'react'
import './qualityIndicators.css'
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import PreviewSharpIcon from '@mui/icons-material/PreviewSharp';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import { useTranslation } from 'react-i18next'

const QualityIndicators = () => {
  const {t} = useTranslation()
  return (
    <div className='quality-indicators-container'>
        <h2>{t('qualityIndicatorTitle')}</h2>
        <div className="quality-indicators-inner">

        <div className="each-quality-indicator">
        <h5>{t('qualityIndicatorOne')}</h5>
        <TravelExploreIcon className='quality-indicator-icon' />
        <p>{t('qualityIndicatorOneDe')}</p>
      </div>

      <div className="each-quality-indicator">
        <h5>{t('qualityIndicatorTwo')}</h5>
        <PreviewSharpIcon className='quality-indicator-icon' />
        <p>{t('qualityIndicatorTwoDe')}</p>
      </div>

      <div className="each-quality-indicator">
        <h5>{t('qualityIndicatorThree')}</h5>
        <SpellcheckIcon className='quality-indicator-icon' />
        <p>{t('qualityIndicatorThreeDe')}</p>
      </div>

        </div>
     
    </div>
  )
}

export default QualityIndicators
