import  {  useEffect, useState, Suspense } from 'react'
import './App.css';
import {BrowserRouter, Routes, Route, Navigate, useParams, Link, useNavigate } from 'react-router-dom'
import WhoWeAre from './pages/WhoWeAre'
import HowWeInvest from './pages/HowWeInvest'
import Philosophy from './pages/Philosophy'
import Portfolio from './pages/Portfolio'
import Login from './pages/Login'
import LoginPassword from './pages/LoginPassword'
import Home from './pages/Home'
import SignUp from './pages/SignUp';
import SignupPassword from './pages/SignupPassword';
import Dashboard from './pagesDashboard/Dashboard'
import Profile from './pages/Profile';
import Withdrawal from './pages/Withdrawal';
import WithdrawalHistory from './pages/WithdrawalHistory';
import Notification from './pagesDashboard/Notification';
import Borrow from './pages/Borrow';
import Loan from './pagesDashboard/Loan';
import { selectUserInfo } from './slices/authSlice';
import { useSelector } from 'react-redux'
import { Player } from '@lottiefiles/react-lottie-player';
import goodSRC from './assets/goodAnimation.json'
import logo from './assets/crownstreetlogo.png'
import PurchasePortfolioRates from './pages/PurchasePortfolioRates';
import ActivePortfolios from './pages/ActivePortfolios'
import ExpiredPortfolios from './pages/ExpiredPortfolios'
import AccountActivities from  './pages/AccountActivities'
import ChooseTransfer from './pages/ChooseTransfer';
import TransferToWallet from './pages/TransferToWallet';
import TransferToWalletAmount from './pages/TransferToWalletAmount';
import TransferToDownline from './pages/TransferToDownline';
import TransferFromWalletAmount from './pages/TransferFromWalletAmount';
import TransferToDownlineAmount from './pages/TransferToDownlineAmount';
import Transfers from './pages/Transfers'
import Referral from './pages/Referral'
import ReferralBonus from './pages/ReferralBonus'
import TwoFA from './pages/TwoFA'
import UpdatePassword from './pages/UpdatePassword'
import Wallets from './pages/Wallets';
import FundWallet from './pages/FundWallet';
import FundWalletSuccess from './pages/FundWalletSuccess';
import FundWalletFailure from './pages/FundWalletFailure';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentFailure from './pages/PaymentFailure';
import ReInvest from './pages/ReInvest';
import ReInvestAmount from './pages/ReInvestAmount';
import ForgetPassword from './pages/ForgetPassword';
import SignupWithReferralPassword from './pages/SignupWithReferralPassword';
import ForgetPasswordUpdate from './pages/ForgetPasswordUpdate';
import SignupWithReferral from './pages/SignupWithReferral';
import Contact from './pages/Contact';
import { FaRegTimesCircle } from 'react-icons/fa'
import robotAnimation from './assets/crownRobotAni.json'
import ClaireX from './pages/ClaireX';
import FAQ from './pages/FAQ';
import AwaitingPayment from './pages/AwaitingPayment';
import i18n from './i18n';
import LocaleContext from './LocaleContext';
import Loader from './components/Loader';
import SurveySignup from './pages/SurveySIgnup';
import SurveyQuestionnaire from './pages/SurveyQuestionnaire';
import TransferPortfolios from './pages/TransferPortfolios';
import TransferPortfolioAccept from './pages/TransferPortfolioAccept';

function App() {

  // const portfolioData = [
  //   {
  //     id: 1,
  //     folio: 'Basic Fund',
  //     min: '200',
  //     dailyPercentage:'1.42',
  //     compondingPercentage:'1.62',
  //     category: 'Beginners Investment Portfolio'
      
  //   },
  //   {
  //     id: 2,
  //     folio: 'Mutual Equity',
  //     min: '5000',
  //     dailyPercentage:'1.80',
  //     compondingPercentage:'2.32',
  //     category: 'Basic Growth Investment Portfolio'
     
  //   },
  //   {
  //     id: 3,
  //     folio: 'Annuity Portfolio',
  //     min: '50000',
  //     dailyPercentage:'2.30',
  //     compondingPercentage:'2.62',
  //     category: 'Basic Capital Investment Portfolio'
  //   },
  //   {
  //     id: 4,
  //     folio: 'Vanguard Capital',
  //     min: '250000',
  //     dailyPercentage:'2.33',
  //     compondingPercentage:'3.5',
  //     category: 'Double Capital Investment Portfolio'
    
  //   },
  //   {
  //     id: 5,
  //     folio: 'Provident Fund',
  //     min: '500000',
  //     dailyPercentage:'2.77',
  //     compondingPercentage:'3.80',
  //     category: 'Legacy Development Investment Portfolio'
     
  //   },
  //   {
  //     id: 6,
  //     folio: 'Dividend Trust',
  //     min: '1000000',
  //     dailyPercentage:'3.02',
  //     compondingPercentage:'4.12',
  //     category: 'One Million Hedge'
    
  //   },
  //   {
  //     id: 7,
  //     folio: 'Hedge Fund',
  //     min: '5000000',
  //     dailyPercentage:'7.8',
  //     compondingPercentage:'12.02',
  //     category: 'Five Million Hedge'
    
  //   },
  // ]

  // const ScrollToTop = () => {
  //   const history = useNavigate();
  
  //   useEffect(() => {
  //     const unlisten = history.listen(() => {
  //       window.scrollTo(0, 0); // Scrolls to the top of the page
  //     });
  //     return () => {
  //       unlisten();
  //     };
  //   }, [history]);
  
  //   return null;
  // };

  const [isLoading, setIsLoading] = useState(true);
  // const {user} = useContext(AuthContext)
  const userInfo = useSelector(selectUserInfo)

  // const ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
  const ENDPOINT = 'https://api-panel.crownstreetholdings.com'


  const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const [lastActivity, setLastActivity] = useState(new Date());
  
    useEffect(() => {
      const resetTimer = () => {
        setLastActivity(new Date());
      };
  
      const checkIdleTime = () => {
        const currentTime = new Date();
        const idleTime = currentTime - lastActivity;
  
        // Set the idle time threshold to 3 minutes (180000 milliseconds)
        const idleThreshold = 180000;
  
        if (idleTime > idleThreshold) {
          // Redirect to the login page if idle time exceeds the threshold
          navigate('/login');
        }
      };
  
      // Set up event listeners to reset the timer on user activity
      const events = ['mousedown', 'mousemove', 'keydown', 'scroll'];
      events.forEach((event) => {
        window.addEventListener(event, resetTimer);
      });
  
      // Check idle time every 1 minute
      const timer = setInterval(checkIdleTime, 60000);
  
      // Cleanup event listeners and timer on component unmount
      return () => {
        events.forEach((event) => {
          window.removeEventListener(event, resetTimer);
        });
        clearInterval(timer);
      };
    }, [lastActivity, navigate]);
  
    if (!userInfo?.user) {
      return <Navigate to='/login' />;
    }
  
    return children;
  };



    const VerifyOTPComponent = () => {

      const { userId, otp } = useParams();
      const [showVerify, setShowVerify] = useState(false)

      console.log(userId, otp)

      useEffect(()=>{

        const verifyOTPFunction=async()=>{


      const urlString = window.location.href ;
        const url = new URL(urlString);
        const pathname = url.pathname; // This will give you '/6564ad9ec0f4500e63ff1b61/9921'

        // To extract the two parts separately:
        const parts = pathname.split('/').filter(Boolean); // Split the pathname into parts
        const firstPart = parts[0]; // '6564ad9ec0f4500e63ff1b61'
        const secondPart = parts[1]; // '9921'
        console.log(parts)
          console.log(parts)
        console.log(firstPart, secondPart);

        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        fetch(`${ENDPOINT}/api/auth/verify-otp/${firstPart}/${secondPart}`, requestOptions)
          .then(response => response.json())
          .then(result =>{
            if(result?.user){
              setShowVerify(true)
            } else{
              alert('Error verifying, check your network and try again later')
              console.log('error verifying, click on the link again')
            }
            console.log(result)
          })
          .catch(error => console.log('error', error));

        }

        verifyOTPFunction()
      }, [])
      
    return (
      <>
      { showVerify &&
          <div style={{width:'100vw',height:'100vh'}} className='flex flex-col gap-2 items-center justify-center'>
  
          <Player
                  src={goodSRC}
                  className="player"
                  loop
                  autoplay
                   style={{ height: '100px', width: '100px' }}
                  
                  />
                  <h1 className='text-3xl font-light'>Congratulations!</h1>
                  <h1 className='text-3xl font-light '>You're Verified</h1>
                  <Link className='bg-sky-100 py-2 px-4 rounded-lg font-bold hover:text-white hover:bg-sky-700' to='/login'>Login</Link>
                  <img 
                  src={logo}
                  alt='logo image'
                  style={{ height: '100px', width: '100px' }}
                  />
              </div>
      }
      </>
    
    )
  }

  useEffect(() => {
    const currentUrl = window.location.href;
    const isVerificationLink = /^https:\/\/crownstreetholdings\.com\/\d+\/\d+$/i.test(currentUrl);

    if (isVerificationLink) {
      // Extract user ID and OTP from the URL
      console.log('verification...')
      const [, userId, otp] = currentUrl.match(/^https:\/\/crownstreetholdings\.com\/(\d+)\/(\d+)$/i);
      window.location.replace(`/${userId}/${otp}`);
    }
  }, []);

 

  // ai pop

  // const [showClaire, setShowClaire] = useState(true)

  // const AIPopUp=()=>{

   

  //   return(
  //     <div style={{
  //       // Adjust the gradient direction and colors as needed
  //     }} className='claire-container'>
  //     <div  className='claire-innerContainer p-2 bg-green-800 flex flex-col items-center gap-4 text-base-content backdrop-filter backdrop-blur-lg bg-opacity-30'>
  //       <FaRegTimesCircle onClick={()=>setShowClaire(false)} className='text-white text-3xl font-bold cursor-pointer' />
  //       <Player
  //                 src={robotAnimation}
  //                 className="player"
  //                 loop
  //                 autoplay
  //                  style={{ height: '150px', width: '150px' }}
                  
  //                 />
  //                 <h1 style={{width:window.innerWidth > 500 ? '70%' : '100%'}} className='text-2xl text-white font-extrabold text-center'>Hello, I am ClaireX the Official CrownStreet Holdings Trading and Investment AI</h1>
  //                 <p style={{width: window.innerWidth > 500 ? '80%' : '100%'}} className='text-white text-xl'>Are you ready to transform the way you trade and make your investments work smarter for you? Meet Claire X, I am an Artificial Intelligence designed to help you trade effectively and effortlessly.</p>
  //     </div>
  //     </div>
  //   )
  // }

  // translate
  const [locale,setLocale] = useState(i18n.language)


  return (
    <div style={{overflowX: 'hidden',  position: 'relative'}} className="App" >
      <LocaleContext.Provider value={{locale, setLocale}}>
        <Suspense fallback={<Loader />}>
        <BrowserRouter>
       {/* { showClaire ? <AIPopUp /> : null} */}
        <Routes>
        

          <Route exact path='/' element={<Home />} />
          <Route path='/who-we-are' element={<WhoWeAre />} />
          <Route path='/how-we-invest' element={<HowWeInvest />} />
          <Route path='/philosophy' element={<Philosophy />} />

          <Route path='/forgotPassword'  element={<ForgetPassword />} />
      <Route path='/createNewPassword'  element={<ForgetPasswordUpdate />} />
          <Route path='/borrow' element={<Borrow />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/claireX' element={<ClaireX />} />
          <Route path='/faq' element={<FAQ />} />
          
          <Route path='/signup' element={<SignUp />} />
          <Route path='/survey' element={<SurveySignup />} />
          <Route path='/survey/questionaire' element={<SurveyQuestionnaire />} />
          <Route path='/signupPassword'  element={<SignupPassword />} />
          <Route path='/register/referral/:referralID'  element={<SignupWithReferral />} />
      <Route path='/signupWithReferralPassword'  element={<SignupWithReferralPassword />} />
          <Route path="/:userId/:otp" element={ <VerifyOTPComponent />}/>
            <Route path='/login' element={<Login />} />
            <Route path='/loginPassword'  element={<LoginPassword />} />
            <Route path='/profile' element={ <ProtectedRoute>
                                        <Profile />
                                        </ProtectedRoute>} />
        
   
          <Route path='/dashboard' element={
          <ProtectedRoute> 
            <Dashboard />
          </ProtectedRoute>} />

          <Route path='/portfolios'  element={<ProtectedRoute>
                                              <Portfolio  /> 
                                              </ProtectedRoute>} />
        <Route path='/portfoliosRates'  element={ <ProtectedRoute>
                                                  <PurchasePortfolioRates  />
                                                  </ProtectedRoute>} />
      <Route path='/awaitingPayment'  element={<ProtectedRoute>
                                                  <AwaitingPayment />
                                                  </ProtectedRoute>} />
     <Route path='/activePortfolios'  element={<ProtectedRoute>
                                                  <ActivePortfolios />
                                                  </ProtectedRoute>} />
      <Route path='/expiredPortfolios'  element={ <ProtectedRoute>
                                                    <ExpiredPortfolios />
                                                    </ProtectedRoute>} />
        <Route path='/withdrawal'  element={<ProtectedRoute>
                                                <Withdrawal />
                                                </ProtectedRoute>} />
      <Route path='/withdrawalHistory'  element={ <ProtectedRoute>
                                                  <WithdrawalHistory />
                                                  </ProtectedRoute>} />
    
       <Route path='/accountActivities'  element={ <ProtectedRoute>
                                                    <AccountActivities />
                                                    </ProtectedRoute>} />
        <Route path='/transfers'  element={<ProtectedRoute>
                                            <Transfers />
                                            </ProtectedRoute>} />
      <Route path='/transferFromWalletAmount'  element={<ProtectedRoute>
                                            <TransferFromWalletAmount />
                                            </ProtectedRoute>} />
      <Route path='/transferPortfolios'  element={<ProtectedRoute>
                                            <TransferPortfolios />
                                            </ProtectedRoute>} />
       <Route path='/transferPortfolios/:id'  element={<ProtectedRoute>
                                            <TransferPortfolioAccept />
                                            </ProtectedRoute>} />

         <Route path='chooseTransfers'  element={<ProtectedRoute>
                                            <ChooseTransfer />
                                            </ProtectedRoute>} />

         <Route path='transferToWallet'  element={<ProtectedRoute>
                                            <TransferToWallet />
                                            </ProtectedRoute>} />
         <Route path='transferToWalletAmount'  element={<ProtectedRoute>
                                            <TransferToWalletAmount />
                                            </ProtectedRoute>} />
         <Route path='transferToDownline'  element={<ProtectedRoute>
                                            <TransferToDownline />
                                            </ProtectedRoute>} />
         <Route path='transferToDownlineAmount'  element={<ProtectedRoute>
                                            <TransferToDownlineAmount />
                                            </ProtectedRoute>} />
        

            <Route path='fundWallet'  element={<ProtectedRoute>
                                            <FundWallet />
                                            </ProtectedRoute>} />
          <Route path='paymentSuccess'  element={<ProtectedRoute>
                                            <PaymentSuccess />
                                            </ProtectedRoute>} />
          <Route path='paymentFailure'  element={<ProtectedRoute>
                                            <PaymentFailure />
                                            </ProtectedRoute>} />
          <Route path='fundWalletSuccess'  element={<ProtectedRoute>
                                            <FundWalletSuccess />
                                            </ProtectedRoute>} />
          <Route path='fundWalletFailure'  element={<ProtectedRoute>
                                            <FundWalletFailure />
                                            </ProtectedRoute>} />
         <Route path='/reInvest'  element={<ProtectedRoute>
                                            <ReInvest />
                                            </ProtectedRoute>} />
      <Route path='/reInvestAmount'  element={<ProtectedRoute>
                                            <ReInvestAmount />
                                            </ProtectedRoute>} />
         
         <Route path='/referal' element={<ProtectedRoute>
                                        <Referral />
                                        </ProtectedRoute>} /> 
      <Route path='/referralBonus' element={ <ProtectedRoute>
                                              <ReferralBonus />
                                              </ProtectedRoute>} /> 
              <Route path='/twoFA' element={ <ProtectedRoute>
                                          <TwoFA />
                                        </ProtectedRoute>} /> 
      <Route path='/updatePassword' element={ <ProtectedRoute>
                                                <UpdatePassword />
                                                </ProtectedRoute>} /> 
       <Route path='/wallets' element={ <ProtectedRoute>
                                                <Wallets />
                                                </ProtectedRoute>} /> 
          <Route path='/notification' element={<ProtectedRoute>
                 <Notification />
             </ProtectedRoute>} />
             <Route path='/loan' element={<ProtectedRoute>
                 <Loan />
             </ProtectedRoute>} />
           
        </Routes>

        
        
        </BrowserRouter>
        </Suspense>
             </LocaleContext.Provider>
    </div>
  );
}

export default App;
