import { createContext, useEffect, useReducer } from "react";



const INITIAL_STATE ={
    portfolio: JSON.parse(localStorage.getItem('portfolio')) || null,
    loading: false,
    error: null
};

export const PortfolioContext = createContext(INITIAL_STATE);


const PortfolioReducer = (state, action)=>{
    switch (action.type){
        case "PORTFOLIO_START":
            return{
                portfolio:null,
                loading:true,
                error:null,
            };
            case "PORTFOLIO_SUCCESS":
                return{
                    portfolio:action.payload,
                    loading:false,
                    error:null,
                };
             case "PORTFOLIO_FAIL":
                 return{
                        portfolio:null,
                        loading:false,
                        error:action.payload,
                    };
        
         default:
             return state;
    }
};

export const PortfolioContextProvider = ({children})=>{
    const [state, dispatch] = useReducer(PortfolioReducer, INITIAL_STATE);
    useEffect(()=>{
        localStorage.setItem('portfolio', JSON.stringify(state.portfolio))
    },[state.portfolio])
    return (
        <PortfolioContext.Provider
        value={{
            portfolio: state.portfolio,
            loading: state.loading,
            error: state.error,
            dispatch
        }}
        >
            {children}
        </PortfolioContext.Provider>
    )
}