import React, { useState } from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import styles from './purchasePortfolioRates.module.css'
import moment from 'moment';
import {useLocation, useNavigate} from 'react-router-dom'
import { IoArrowUndo } from "react-icons/io5";
import PurchasePortfolioAmountColumn from '../components/PurchasePortfolioAmountColumn';
import Sidebar from '../components/sidebar/Sidebar';

const PurchasePortfolioRates = () => {
  const location = useLocation();
  const {state} = location;
  const navigate = useNavigate()

  const [selectedOption, setSelectedOption] = useState('compounding');

  const handleCheckboxChange = (event) => {
    setSelectedOption(event.target.value);
    console.log(selectedOption)
  };

  console.log(location)
  console.log(state)
 console.log(selectedOption)
 console.log(state.amount)
 console.log(state.dailyROI)
 console.log(state.compoundingROI)
 
  return (
    <div className={styles.portfolioContainer}>
          <Sidebar />
    <div className={styles.portfolioInnercontainer}>
    
    <DashboardNavbar />
      <IoArrowUndo onClick={()=>navigate(-1)} className={styles.backButton} />
      <PurchasePortfolioAmountColumn />
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default PurchasePortfolioRates
