import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import TransfersColumnComponent from '../components/TransfersColumnComponent'
import WalletSlideColumn from '../components/WalletSlideColumn'
import { IoArrowUndo } from "react-icons/io5";
import {useLocation, useNavigate} from 'react-router-dom'
import styles from './transferFromWalletAmount.module.css'
import Sidebar from '../components/sidebar/Sidebar'
import { useTranslation } from 'react-i18next'

const Transfers = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()


  return (
    <div className={styles.transfersContainer}>
    <Sidebar />
  <div className={styles.transfersInnerContainer}>
 
  <DashboardNavbar />
      <IoArrowUndo onClick={()=>navigate(-1)} className={styles.backButton} />
        <h1 className='text-xl font-bold ml-5 pl-10 text-gray-300 mb-2'>  {t('chooseTransfersFromMain')}</h1>


        {/* <WalletSlideColumn /> */}
{/* 
        <div style={{width:'350px'}} className='bg-sky-50 p-4 shadow-md m-auto'>
            <h1 className='text-center'>Wallet</h1>
            <h1 className='text-center text-xl font-semibold'>$5,000</h1>

        </div> */}
        <TransfersColumnComponent />
      </div>
     
    </div>
  )
}

export default Transfers
