import React, { useEffect, useState } from 'react'
import styles from './loan.module.css'
import Sidebar from '../components/sidebar/Sidebar'
import NavbarDashboard from '../components/DashboardNavbar'
import EmptyContainer from '../components/EmptyContainer'
import { selectUserInfo } from '../slices/authSlice';
import {  useSelector } from 'react-redux'
import withdrawalImage from '../assets/withdrawal.jpg'
import { FaRegTimesCircle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

const Loan = () => {
  const userInfo = useSelector(selectUserInfo)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
  const [fetchedPortfolio, setFetchedPortfolio] = useState([])
  const [certificateID, setCertificateID] = useState('')
  const [dateIssued, setDateIssued] = useState('')
  const [amount, setAmount] = useState('')
  const [popUp, setPopUp] = useState('')
  const [showPopupModal, setShowPopupModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const {t} = useTranslation()


  useEffect(() => {
    const fetchUserPortfolio = async () => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_ENDPOINT}/api/portfolios/${userInfo.user._id}`, requestOptions);
        const result = await response.json();
        // console.log('called again')
        setFetchedPortfolio(result.portfolios)
        // console.log(result);
      } catch (error) {
        console.log('error', error);
      }
    };

    // Initial fetch
    fetchUserPortfolio();

    // Set up interval for fetching every 10 seconds
    const intervalId = setInterval(fetchUserPortfolio, 10000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [userInfo.token, API_ENDPOINT]); // Add dependencies as needed

  // console.log(fetchedPortfolio)


  const handleLoan=async(e)=>{
    e.preventDefault()

    if(certificateID && amount && dateIssued){


      setLoading(true)

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
 
 
       var formdata = new FormData();
       formdata.append("amount", amount);
       formdata.append("certificateID", certificateID);
       formdata.append("dateIssued", dateIssued);
       
       var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: formdata,
         redirect: 'follow'
       };
       
       fetch(`${API_ENDPOINT}/api/loan/createLoan`, requestOptions)
         .then(response => response.json())
         .then(result =>{
            //  console.log(result)
             if(result.message === 'Loan Created'){
                setPopUp(`${t('loanPagePopup1')}`)
              setShowPopupModal(true)
              const form = e.target;
                form.reset()
                setLoading(false)
                return
             } else {
               setPopUp(`${t('loanPagePopup2')}`)
               setShowPopupModal(true)
               setLoading(false)
               return
             }
            
        
         })
         .catch(error => console.log('error', error));



    } else {
      setPopUp(`${t('loanPagePopup3')}`)
      setShowPopupModal(true)
      return
    }


  }


  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }
  

  const ApplyLoanComponent=()=>{
    return(
      <div style={{margin:'100px auto', width:'80vw'}} className='flex flex-col items-center justify-center bg-green-50 p-3'>

         {/* popup */}
    { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
          <div className={styles.downlineTransferContainer}>

<div className={styles.left}>
  <img  src={withdrawalImage} alt="image" />
</div>

<form onSubmit={handleLoan} className={styles.right}>
    <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>{t('loanPageRequest')}</h1>
    <input 
    value={certificateID}
    onChange={(e)=>setCertificateID(e.target.value)}
    style={{ height:'50px', width:'100%',outline:'none'}} placeholder={t('loanPageInvestmentID')} className='bg-sky-100 mt-2 p-2' type="text" name='certificateID' />
    <input
    value={dateIssued}
    onChange={(e)=>setDateIssued(e.target.value)}
    style={{ height:'50px', width:'100%',outline:'none'}} placeholder={t('loanPageDateIssued')} className='bg-sky-100 mt-2 p-2' type="text" name='dateIssued' />

    <input
    value={amount}
    onChange={(e)=>setAmount(e.target.value)}
    style={{ height:'50px', width:'100%',outline:'none'}} placeholder={t('loanPageAmount')} className='bg-sky-100 mt-2 p-2' type="number" name='amount' />
    <p className='text-sm p-2 text-green-500 text-center'>{t('loanPagePara')}</p>
    <button  className={styles.glowOnHover} type="submit">{t('loanPageButton')}</button>
  </form>

</div>
      </div>
    )
  }

  return (
    <div className={styles.loanContainer}>
    <Sidebar />
    <div className={styles.loanInnerContainer}>
      <NavbarDashboard />
    {fetchedPortfolio.length > 0 ?
      <ApplyLoanComponent />
    : <EmptyContainer emptyTitle={t('loanPageEmptyTitle')} emptyDetails={t('loanPageEmptyDetails')} />}
      

    </div>
  
  </div>
  )
}

export default Loan
