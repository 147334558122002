import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar/Navbar'
import ContactUsColumn from '../components/ContactUsColumn'
import { TickerTape } from "react-ts-tradingview-widgets";
import Footer from '../components/footer/Footer';
import Loader from '../components/Loader';
import TopTag from '../components/TopTag';


const ContactUspage = () => {

  const customCopyrightStyles = {
    display: 'none', 
    opacity: 0,
    color:'white'
  };



  return (
    <div>
      <TopTag /> 
     
      {/* <TickerTape copyrightStyles={customCopyrightStyles}  colorTheme="dark"></TickerTape> */}
     
        <Navbar />
        <ContactUsColumn />
        <Footer />
    </div>
  )
}

export default ContactUspage
