import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import FundWalletFailureComponent from '../components/FundWalletFailureComponent'
import styles from './transferFromWalletAmount.module.css'
import Sidebar from '../components/sidebar/Sidebar'

const FundWalletFailure = () => {
  return (
    <div className={styles.transfersContainer}>
    <Sidebar />
<div className={styles.transfersInnerContainer}>

<DashboardNavbar />
      <FundWalletFailureComponent />
     
     </div>
    </div>
  )
}

export default FundWalletFailure
