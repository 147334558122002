import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Message from '../components/message/Message'
import NavbarDashboard from '../components/navbarDashboard/NavbarDashboard'
import Sidebar from '../components/sidebar/Sidebar'
import './dashboard.css'
import useFetch from '../hooks/useFetch'
import { AuthContext } from '../contexts/AuthContext'
import axios from 'axios'


const Notification = () => {

  const [list, setList] = useState([])
  const [clickedMessage, setClickedMessage] = useState(null)
  const {user} = useContext(AuthContext)

  const { data, loading, error } = useFetch('/messages');

  const serverPublic =  process.env.REACT_APP_PUBLIC_FOLDER;



  useEffect(()=>{

    setList(data)
  },[data]);

  const [notifs,setNotifs] = useState()

async function setRead(id){
  for (var i in list) {
     if (list[i]._id === id) {
      console.log(list[i].read)
        list[i].read = true;
        setClickedMessage(list[i].read = true)
        console.log(clickedMessage)
        //  await axios.put(`/messages/${id}`, clickedMessage)  
        break;
     }
   }
  //  console.log(list)
  // setNotifs(notifs)
  

  await axios.put(`/messages/${id}`, clickedMessage)
   console.log(list)  
}


  
  console.log(list)
  return (
    <div className='dashboard-container'>
    <Sidebar />
      <div className="dashboard-innercontainer">
        <NavbarDashboard />
        <h2 style={{background:'rgba(128, 128, 128, 0.101)', padding:'10px'}}>Messages</h2>
        <div className="widgets" style={{height:'100vh'}}>
          <div className="notification-container">
          <img  src={user.profilePicture ?  user.profilePicture : 
                 serverPublic + 'profile.png'
                } alt='profile image'/>
          <div className='notification-user'>
            <h5>{user.name}</h5>
            <p>Message</p>
          </div>
          </div>
          <div style={{maxWidth: '1000px'}}>

          {list.map((eachMessage)=>(
            <div className={eachMessage.read === true ? 'read' : 'unread'} onClick={()=>setRead(eachMessage._id)} key={eachMessage._id}>

             <Message    eachMessage={eachMessage} />

            </div>

          
         ))}

          </div>
   
        </div>

      </div>
    
    </div>
  )
}

export default Notification
