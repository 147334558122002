import React, { useEffect, useState } from 'react'
import './footer.css'
import  video3 from '../../assets/video3.mp4'
import { Link } from 'react-router-dom'
import { Player } from '@lottiefiles/react-lottie-player';
import moneyAnimation from '../../assets/moneyAni.json'
import footerLogo from '../../assets/crownstreetmen.png'
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next'


const Footer = () => {
  const [locale,setLocale] = useState(i18n.language)
  const {t} = useTranslation()
  // {t('footerAddress')}

  // console.log('Available keys:', Object.keys(t('translation')));
  
  useEffect(() => {
    // Function to fetch user's location based on IP address
    const fetchUserLocation = async () => {
      try {
        const response = await fetch('https://ipinfo.io/json');
        const data = await response.json();

        // Extract country code from the response
        const countryCode = data.country;

        console.log(countryCode)
        // Map country code to language code (you may need to adjust this mapping)
        const languageCode = mapCountryCodeToLanguage(countryCode);

        // Set the language based on the detected country
        if (languageCode) {
          i18n.changeLanguage(languageCode);
          setLocale(languageCode);
        }
      } catch (error) {
        console.error('Error fetching user location:', error);
      }
    };

    fetchUserLocation();
  }, []);

  // Rest of your code...

  // Helper function to map country code to language code
  const mapCountryCodeToLanguage = (countryCode) => {
    // Example: Map specific country codes to language codes
    switch (countryCode) {
        case 'NG':
        case 'US':
        return 'en'; // English for the United States
      case 'FR':
      case 'BE':
      case 'CH':
      case 'LU':
      case 'MC':
        return 'fr'; 
      case 'AR':
      case 'BO':
      case 'CL':
      case 'CO':
      case 'CR':
      case 'CU':
      case 'DO':
      case 'EC':
      case 'SV':
      case 'GQ':
      case 'GT':
      case 'HN':
      case 'MX':
      case 'NI':
      case 'PA':
      case 'PY':
      case 'PE':
      case 'ES':
      case 'UY':
      case 'VE':
            return 'es'; 
      case 'CN':
        return 'mn';
      case 'TR':
        return 'tk';
      case 'IT':
      case 'SM':
      case 'VA':
      case 'CH':
      case 'HR':
      case 'SI':
        return 'it';
      default:
        return 'en'; // Return an empty string or default language code
    }
  };

  i18n.on('languageChanged', (lng)=> setLocale(i18n.language));

  const handleChange=(e)=>{
    i18n.changeLanguage(e.target.value)  //gives you any of the languages available
  }

  return (
    <div className='footer-container'>
     
          <Player
                  src={moneyAnimation}
                  className="player"
                  loop
                  autoplay
                   style={{ height: '400px', width: '500px' }}
                  
                  />
        <div className="footer-bottom">

      
            <div className="footer-bottom-inner">
                 <img  style={{width:70,height:70}} src={footerLogo} alt="investment managers" />
                  <p>{t('footerTitle')}</p>
            </div>
            <div className="footer-bottom-inner">
                 <h3>{t('footerAboutUs')}</h3>
                 <Link to='/who-we-are'>{t('footerWhoWeAre')}</Link>
                 <Link to='/how-we-invest'>{t('footerHowWeInvest')}</Link>
                 <Link to='/philosophy'>{t('footerPhilosophy')}</Link>
                 <Link to='/claireX'>{t('footerClaire')}</Link>
            </div>
            <div className="footer-bottom-inner">
                 <h3>{t('footerContactDe')}</h3>
                 <h6>{t('footerEmail')}</h6>
                 <a className='text-white' href='mailTo:info@crownstreetholdings.com'>info@crownstreetholdings.com</a>
                 <h6>{t('footerPhone')}</h6>
                 <p>+1 (302) 223-9939</p>
            </div>
            <div className="footer-bottom-inner">
                 <h3>{t('footerAddress')}</h3>
                 <h6>Kansas</h6>
                 <p>1000 N West kansas street,DE 19801, USA</p>
               
                 {/* <a href='#'>View on Google Map</a> */}
            </div>
            <div className="footer-bottom-inner-translate">
            {/* <label>Locale Change</label> */}
            <select value={locale} onChange={handleChange}>
              <option value='en'>English</option>
              <option value='es'>Spanish</option>
              <option value='fr'>French</option>
              <option value='mn'>Chinese</option>
              <option value='tk'>Turkey</option>
              <option value='it'>Italian</option>
            </select>
          </div>
        </div>
        <div className='footer-p'>
        <p >© 2024 CrownStreet Holdings.</p>
        </div>
       
    </div>
  )
}

export default Footer
