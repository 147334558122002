import React from 'react'
import Sidebar from '../components/sidebar/Sidebar'
import DashboardNavbar from '../components/DashboardNavbar'
import styles from './transferFromWalletAmount.module.css'
import { IoArrowUndo } from "react-icons/io5";
import TransferPortfolioAcceptComponenet from '../components/TransferPortfolioAcceptComponenet';
import { useNavigate } from 'react-router-dom';

const TransferPortfolioAccept = () => {
    const navigate = useNavigate()
  return (
    <div className={styles.transfersContainer}>
    <Sidebar />
  <div className={styles.transfersInnerContainer}>
    <DashboardNavbar />
    <IoArrowUndo onClick={()=>navigate(-1)} className={styles.backButton} />
   

        <TransferPortfolioAcceptComponenet />
    </div>
    </div>
  )
}

export default TransferPortfolioAccept
