import React from 'react'
import './awards.css'
import award1 from '../../assets/award1.png'
import award2 from '../../assets/award2.png'
import award3 from '../../assets/award3.png'
import { useTranslation } from 'react-i18next'

const Awards = () => {
  const {t} = useTranslation()

  return (
    <div className="awards-container">
 
    <h3>{t('internationalRecogTitle')}</h3>
 
    <div className='awards-inner-container'>
      <div className="each-awards">
        <img src={award1} alt="award" />
        <p>{t('internationalRecogOne')}</p>
      </div>

      <div className="each-awards">
        <img src={award2} alt="award" />
        <p>{t('internationalRecogTwo')}</p>
      </div>
      <div className="each-awards">
        <img src={award3} alt="award" />
        <p>{t('internationalRecogThree')}</p>
      </div>
    </div>

    </div>
  )
}

export default Awards
