import BorrowDetails from '../components/borrowDetails/BorrowDetails'
import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'
import PageColumn from '../components/pageColumn/PageColumn'
import './borrow.css'
import TopTag from '../components/TopTag'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react';


const Borrow = () => {
  const {t} = useTranslation()


  useEffect(() => {
    
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
    <>
    <TopTag />
    <Navbar />
    <div className="borrow-container">
    <PageColumn highlight={t('borrowPageHeroTitle')}  />
    </div>
    <div className="borrow-inner-container">
      <BorrowDetails />
    </div>
 
    <Footer />
  </>
  )
}

export default Borrow
