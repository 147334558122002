import {createSlice} from '@reduxjs/toolkit'


const initialState = {
 
  fundWalletAmount: JSON.parse(localStorage.getItem('fundWalletAmount')) || {}, // for user object

}

export const fundWalletSlice = createSlice({
    name:'fundWallet',
    initialState,
    reducers:{
     
        setFundWalletAmount: (state,action)=>{ 
            state.fundWalletAmount = action.payload  ;
                  // Save to localStorage
      localStorage.setItem('fundWalletAmount', JSON.stringify(action.payload));
        },
    
        clearFundWalletAmount: (state) => {
            state.fundWalletAmount = {};
      
            // Remove from localStorage
            localStorage.removeItem('fundWalletAmount');
          },
       
    }
});

export const {  
    setFundWalletAmount,
    clearFundWalletAmount
 } = fundWalletSlice.actions


 export const selectFundwalletAmount = (state) => state.fundWallet.fundWalletAmount;
  
  


export default fundWalletSlice.reducer;