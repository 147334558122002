import homeImage from '../assets/loginImage.jpg'
import { Link, useNavigate } from 'react-router-dom'
import React, { useState, useContext, useEffect } from 'react'
import Navbar from '../components/navbar/Navbar'
import styles from './signup.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
// import { AuthContext } from '../context/AuthContext';
import { TickerTape } from "react-ts-tradingview-widgets";
// import TopTag from '../components/TopTag'
import axios from 'axios'
import { Player } from '@lottiefiles/react-lottie-player';
import goodSRC from '../assets/goodAnimation.json'
import Footer from '../components/footer/Footer'
// import Loader from '../components/Loader'
import inputSRC from '../assets/loadingAnimation.json'
import { CgProfile } from 'react-icons/cg'
import TopTag from '../components/TopTag'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next'
import { FaRegEye } from "react-icons/fa";
import { FaRegEyeSlash } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";
import { RiVerifiedBadgeFill } from "react-icons/ri";

const SurveySignup = () => {
  const [showModal, setShowModal] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const [showPopupModal, setShowPopupModal] = useState(false)
  const [showPopupTwoModal, setShowPopupTwoModal] = useState(false)
  // const { err, dispatch} = useContext(AuthContext);
  const [popUp, setPopUp] = useState('')
  const [popUpTwo, setPopUpTwo] = useState('')
  const [emailSpinner, setEmailSpinner] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isPasswordSecure, setIsPasswordSecure] = useState(true)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
  const [removeEnterEmail, setRemoveEnterEmail] = useState(true)
  const [confirmPassword, setConfirmPassword] = useState('')

  const [showCharacterLength, setShowCharacterLength] = useState(false)
  const [showPasswordRequirements, setShowPasswordRequirements] = useState(false)
  const [showUppercase, setShowUppercase] = useState(false)
  const [showNumber, setShowNumber] = useState(false)

  const {t} = useTranslation()




  const handleSubmitSurveyAccount =async(e)=>{
    e.preventDefault()

    if(!password) {
        setError(`${t('forgetPasswordPasswordPopup')}`)
        return
      }
      if( password !== confirmPassword) {
        setError(`${t('signUpPasswordPopupFail2')}`)
        return
      }
  
      if(!/[0-9]/.test(password) || !/[A-Z]/.test(password) || password.length < 8){
        setShowPopupModal(true)
        setPopUp(`${t('signUpPasswordPopupFail3')}`)
        return;
      }

    
    if(!email && !password) {
      setError(`${t('signUpPopupFail3')}`)
      setPopUp(`${t('signUpPopupFail4')}`)
      setShowPopupModal(true)
      return
    }
 

  var formdata = new FormData();
  formdata.append("email", email);
  formdata.append("password", password);
  
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  setLoading(true)
  fetch(`${API_ENDPOINT}/api/auth/registerSurvey`, requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result.message)
      if(result.message === "Email already exist continue with the registration"){
         // setError(`${t('signUpPopupFail2')}`)
        // setPopUp(`${t('signUpPopupFail')}`)

       
        setPopUp(`${t('signupSurveyPopup1')}`)
        setShowPopupModal(true)
        setLoading(false)

        const form = e.target;
        form.reset()
        setShowModal(true)
      }else if(result.messageOne === 'Registration successful'){
      
        navigate('/survey/questionaire')
     
        return
      }
      console.log(result)})
    .catch(error => console.log('error', error));
    
      setLoading(false)
    console.log('email & password :',email, password)
  }



  const showCharacters =()=>{

    setShowPasswordRequirements(true)
   
  }
  
  const onKeyDownPasswordHandler=()=>{
  
    if(password.length >=  8){
      setShowCharacterLength(true)
    }else if(password.length < 8){
      setShowCharacterLength(false)
    }
  
    if(/[A-Z]/.test(password)){
      setShowUppercase(true)
    }else{
      setShowUppercase(false)
    }
  
    if(/[0-9]/.test(password)){
      setShowNumber(true)
    }else{
      setShowNumber(false)
    }
  }


const handleClosePopup =()=>{
  setShowPopupModal(false)
   setPopUp('')
  }

  const handleClosePopupTwo =()=>{
    setShowPopupTwoModal(false)
     setPopUpTwo('')
     navigate('/login')
    }
    // useEffect(() => {
   
    //   setTimeout(() => {
    //     setLoading(false);
    //   }, 3000); 
    // }, []);
  return (
    <div>
      {loading ? <Loader /> : null}
      <TopTag />
{/* <TickerTape   colorTheme="dark"></TickerTape> */}

      <Navbar />

         {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}


           {/* popup 2*/}
           { showPopupTwoModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={handleClosePopupTwo} className={styles.popClose} />
            <h1 className='p-2 bg-sky-800 text-white font-semibold text-2xl my-7'>{t('signUpRegistrationSuccessful')}</h1>
            <Player
          src={goodSRC}
          className="player"
          loop
          autoplay
           style={{ height: '50px', width: '50px' }}
          
          />
             <p className={styles.popText}>{popUpTwo}</p>
            <button className={styles.popButton} onClick={handleClosePopupTwo}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup 2 ends */}
      <div className={styles.imageContainer}  >
      <img
        src={homeImage}
        alt='welcome image'
       className={styles.heroImage}

        />

<div className={styles.loginContainer}>
      
<div style={{width: window.innerWidth < 500 ? '100%' : '400px', margin:'auto', height:'380px'}} className='flex gap-5 my-2 mx-11 p-5 items-center  bg-green-200 text-base-content backdrop-filter backdrop-blur-lg bg-opacity-30'>
  
  <div style={{height:'100%', width:'30%'}} className='bg-gray-200 flex items-center justify-center text-base-content backdrop-filter backdrop-blur-lg bg-opacity-30'>
  <CgProfile style={{color:'rgb(3, 88, 21)', fontSize:48}} />
  </div>
        <form 
        onSubmit={handleSubmitSurveyAccount}
        style={{width:window.innerWidth < 500 ? '80%' : '60%'}} className='flex flex-col gap-3 py-7' action="">
          {/* <h1 className='text-4xl font-bold pb-6 pt-2 text-center text-white'>{t('signUpPageTitle')}</h1> */}
          <h1 className='text-4xl font-bold pb-6 pt-2 text-center text-white'> {t('signupSurveyTitle')}</h1>
        
          <div className={styles.inputContainer}>
     
          <input 
          onFocus={()=>{setError('')}}
          onChange={(e)=>{setEmail(e.target.value); setEmailSpinner(true)}}
          className={styles.emailInput}
          placeholder={t('LoginPageEmail')}
          style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10}}
           type="email" name='email' />
        {/* {removeEnterEmail && <label className={styles.emailText} htmlFor="email">Enter Email_</label>} */}
        {emailSpinner && <div>
            <Player
          src={inputSRC}
          className="player"
          loop
          autoplay
           style={{ height: '40px', width: '40px', position:'absolute',bottom:'0px', right:'5px' }}
          
          /> 
            </div>}
          </div>
      
          <div className={styles.inputContainer}>
 
 <input 
  onFocus={()=>{setError(''); setRemoveEnterEmail(false); showCharacters()}}
  onKeyUp={onKeyDownPasswordHandler}
  className={styles.emailInput}
   onChange={(e)=>setPassword(e.target.value)}
   placeholder={t('signUpPasswordPassword')}
  style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10}}
  type={isPasswordSecure ? "password" : 'name'}  name='password' />

{isPasswordSecure ? <FaRegEye
   onClick={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
                    style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray',  fontSize:'20px', cursor:'pointer'}}/>  
                      : <FaRegEyeSlash 
                      onClick={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
                    style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray', fontSize:'20px', cursor:'pointer'}}/>
    }

 {/* {removeEnterEmail && <label className={styles.emailText} htmlFor="email">Enter Password</label>} */}


 </div>

{showPasswordRequirements && <div className='flex flex-col gap-0'> <div className='flex gap-1 items-center justify-center -mt-2'>
   <div className='flex gap-3 items-center justify-center'>
  { showCharacterLength ? <RiVerifiedBadgeFill size={18} color='white' />
   : <TiCancel  size={18} color='red' />}
   </div>
    <p style={{fontSize:'10px'}} className=' text-white text-center pt-2'>{t('signUpPasswordDisplayOne')}</p>
 </div>

 <div className='flex gap-1 items-center justify-center -mt-4'>
   <div className='flex gap-3 items-center justify-center'>
  { showUppercase ? <RiVerifiedBadgeFill size={18} color='white' />
   : <TiCancel  size={18} color='red' />}
   </div>
    <p style={{fontSize:'10px'}} className=' text-white text-center pt-2'>{t('signUpPasswordDisplayTwo')}</p>
 </div>

 <div className='flex gap-1 items-center justify-center -mt-4'>
   <div className='flex gap-3 items-center justify-center'>
  { showNumber ? <RiVerifiedBadgeFill size={18} color='white' />
   : <TiCancel  size={18} color='red' />}
   </div>
    <p style={{fontSize:'10px'}} className=' text-white text-center pt-2'>{t('signUpPasswordDisplayThree')}</p>
 </div>

 
 </div> }

 <div className={styles.inputContainer}>

<input 
onFocus={()=>{setError(''); setRemoveEnterEmail(false); setShowPasswordRequirements(false)}}
className={styles.emailInput}
onChange={(e)=>setConfirmPassword(e.target.value)}
placeholder={t('signUpPasswordConfirm')}
style={{backgroundColor:'white', height:'40px', width:'100%', outline:'none', paddingLeft:10}}
type={isPasswordSecure ? "password" : 'name'}  name='password' />

{isPasswordSecure ? <FaRegEye
onClick={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
          style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray',  fontSize:'20px', cursor:'pointer'}}/>  
            : <FaRegEyeSlash 
            onClick={() => { isPasswordSecure ? setIsPasswordSecure(false) : setIsPasswordSecure(true) }}
          style={{position:'absolute', right:10, bottom:10, zIndex:10,color:'lightgray', fontSize:'20px', cursor:'pointer'}}/>
}


</div>
        
            {error && <p className='px-3 py2 font-bold bg-red-500/50 text-white w-fit rounded-md text-xs'>{error}</p>}
       
            <button 
         
         className={styles.glowOnHover} 
       
         >{t('signupSurveyContinueButton')}</button>
        </form>
      </div>

      </div>

    </div>
    <Footer />
    </div>
  )
}

export default SurveySignup
