import React from 'react'
import './lowRisk.css'
import lowRisk from '../../assets/lowRisk.jpg'
import lowRisk2 from '../../assets/lowRisk2.jpg'
import lowRisk3 from '../../assets/goldbar.jpg'
import { useTranslation } from 'react-i18next'

const LowRisk = () => {
  const {t} = useTranslation()

  return (
    <div className='lowRisk-container'>
        <h1 className='text-2xl font-bold'>{t('howWeInvestPageTitle')}</h1>
        <div className="low-risk-images">
            <img src={lowRisk} alt="images" />
            <img src={lowRisk2} alt="images" />
            <img src={lowRisk3} alt="images" />
        </div>
        <p>{t('howWeInvestPageParagraph')}</p>
        <p>{t('howWeInvestPageParagraphTwo')}</p>
    </div>
  )
}

export default LowRisk
