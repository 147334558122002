import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import ProfileSummary from '../components/ProfileSummary'
import UpdatePasswordComponent from '../components/UpdatePasswordComponent'
import Sidebar from '../components/sidebar/Sidebar'


const page = () => {
  return (
    <div className="portfolio-container">
          <Sidebar />
    <div className="portfolio-innercontainer">
    
    <DashboardNavbar />
      <div className='flex gap-10 m-6 md:flex flex-col items-center justify-center'>
      {/* <ProfileSummary /> */}
      <div>
        <UpdatePasswordComponent />
      </div>
      </div>
      </div>
    </div>
  )
}

export default page
