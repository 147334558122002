import React from 'react'
import { BsReception4 } from 'react-icons/bs'
import { TbTrendingUp3 } from 'react-icons/tb'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

const EachActivePortfolio = ({...portfolio}) => {
  // console.log(portfolio)
  const {t} = useTranslation()

  // {t('activePortfolioPageTitle')}

  console.log(portfolio)
  return (
    <div style={{width:'350px'}} className='shadow-md m-7  bg-gray-50'>
      <div className='flex gap-1 flex-col p-5'>
        {/* <BsReception4 style={{width:'50px', height:'50px', borderRadius:'100%'}} className='bg-sky-800 p-2 text-white' /> */}
        <h1 className='text-green-800'>{ portfolio.type === 'hybrid' ? 'Active Hybrid' : t('EachActivePortfolioActive')}</h1>
        <div className='flex gap-2'>
       
        <TbTrendingUp3 style={{width:'30px', height:'30px',}} className='text-white bg-green-800 rounded-full p-2 text-2xl font-bold' />
        <h1 className='text-l font-bold'>{t('dashboardSidebarPortfolio')}</h1>
        </div>
 
    </div>
   { portfolio.type === 'hybrid' && <div className='flex  p-2 mx-4 items-center justify-center gap-2'>
    <div className='flex-1 m-auto bg-green-300 p-2 flex flex-col items-center'>
      <h3  style={{fontSize:10}}>Daily Profit</h3>
      <p className='text-2xl font-bold '>${Math.round(portfolio?.hybridDaily)}</p>
    </div>
    <div className='flex-1 ml-2 bg-gray-300 p-2 flex flex-col items-center'>
      <h3  style={{fontSize:10}}>Compounding Profit</h3>
      <p  className='text-2xl font-bold'>${Math.round(portfolio?.hybridCompounding)}</p>
    </div>
    </div> }
    <div className='flex  p-2 mx-4 items-center justify-center gap-2'>
    <div className='flex-1 m-auto bg-sky-300 p-2 flex flex-col items-center'>
      <h3  style={{fontSize:10}}>{t('purchasePortfolioPageAmount')}</h3>
      <p className='text-2xl font-bold '>${portfolio?.amount}</p>
    </div>
    <div className='flex-1 ml-2 bg-green-300 p-2 flex flex-col items-center'>
      <h3  style={{fontSize:10}}>Total Profit</h3>
      {/* <h3  style={{fontSize:10}}>{t('EachActivePortfolioProfit')}</h3> */}
      <p  className='text-2xl font-bold'>${Math.round(portfolio?.balance)}</p>
    </div>
    </div>
   

    <div className='flex border-2 border-gray-200 p-2 m-5'>

      <div className='flex-1 flex border-r-2 border-gray-200 items-center gap-2 '>
        <h2 className='text-sm font-light text-center'>{t('EachActivePortfolioDuration')}</h2>
        <p className='text-sm font-bold text-center'>90 {t('EachActivePortfolioDays')}</p>
      </div>

      <div className='flex-1 flex items-center gap-2 pl-2 justify-center'>
        <h2 className='text-sm font-light text-center'>{t('EachActivePortfolioInvestmentDays')}</h2>
        <p className='text-sm font-bold text-center'>{moment().diff(moment(portfolio.createdAt), 'days')} {t('EachActivePortfolioDays')}</p>
      </div>

    </div>

    <div className='bg-sky-100 p-2 w-full flex gap-2'>

<div className='flex-1 flex gap-2 items-center border-r-2 border-gray-200'>
 
  <div className='flex flex-col'>
    <p style={{fontSize:10}} className='font-bold'>{portfolio?.dailyProfit}%</p>
    <BsReception4 style={{width:'30px', height:'30px'}} className=' p-2 text-yellow-300' />
  </div>
  <h2 style={{fontSize:10}} className=''>{t('EachActivePortfolioDailyROI')}</h2>
</div>

<div className='flex-1 flex gap-2 items-center border-r-2 border-gray-200'>
 
  <div className='flex flex-col'>
    <p style={{fontSize:10}} className='font-bold'>{portfolio.compoundingProfit}%</p>
    <BsReception4 style={{width:'30px', height:'30px'}} className=' p-2 text-green-300' />
  </div>
  <h2 style={{fontSize:10}} className=''>{t('EachActivePortfolioCompoundingROI')}</h2>
</div>

<div className='flex-1 flex gap-2 items-center'>
 
  <div className='flex flex-col'>
    <p style={{fontSize:10}} className='font-bold'>{portfolio.type === 'hybrid' ? "Hybrid" : portfolio?.type}</p>
    <BsReception4 style={{width:'30px', height:'30px'}} className=' p-2 text-orange-300' />
  </div>
  <h2 style={{fontSize:10}} className=''>{t('EachActivePortfolioInvestmentType')}</h2>
</div>
    </div>
 
  </div>
  )
}

export default EachActivePortfolio
