import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import FundWalletColumn from '../components/FundWalletColumn'
import { IoArrowUndo } from "react-icons/io5";
import { useNavigate} from 'react-router-dom'
import styles from './transferFromWalletAmount.module.css'
import Sidebar from '../components/sidebar/Sidebar'
import { useTranslation } from 'react-i18next'


const FundWallet = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()

  return (
    <div className={styles.transfersContainer}>
        <Sidebar />
    <div className={styles.transfersInnerContainer}>
    
    <DashboardNavbar />
      <div className='m-8'>
      <IoArrowUndo onClick={()=>navigate(-1)} className={styles.backButton} />
        <h1 className='text-xl font-bold ml-5 pl-10 text-gray-300 mb-2'>{t('walletsPageFundWallet')}</h1>
      <FundWalletColumn />
      </div>

</div>
    
    </div>
  )
}

export default FundWallet
