import React, { useEffect, useState } from 'react'
import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'
import OurMission from '../components/ourMission/OurMission'
import PageColumn from '../components/pageColumn/PageColumn'
import QualityIndicators from '../components/qualityIndicators/QualityIndicators'
import WhoWeAreDetails from '../components/whoWeAreDetails/WhoWeAreDetails'
import styles from './whoWeAre.module.css'
import awardIm from '../assets/award2022.png'
import assetManagement from '../assets/assetManage.jpg'
import privateWealth from '../assets/privateWealth.jpg'
import security from '../assets/land.jpg'
import howWeBenefit from '../assets/howWeBenefit.png'
import HowWeBenefitSlide from '../components/howWeBenefitSlide/HowWeBenefitSlide'
import globalReach from '../assets/globalReach.jpg'
import reInvent from '../assets/officePeople2.jpg'
import TopTag from '../components/TopTag'
import { useTranslation } from 'react-i18next'

const WhoWeAre = () => {
  const [hover, setHover] = useState(false); // initial false

  const {t} = useTranslation()

  const onHover = (e) => {
    e.preventDefault();
    setHover(true); // turn true
    console.log("hovered");
  };

  const onHoverOver = (e) => {
    e.preventDefault(); // turn false
    setHover(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  return (
     <>
      <TopTag />
      <Navbar />
      <div className={styles.whoAeAreContainer}>
      <PageColumn highlight={t('whoWeArePageHero')} />
    

      </div>
      <WhoWeAreDetails />
      <OurMission />
      <QualityIndicators />

      <div className={styles.wwaAward}>
      <img src={awardIm} alt="image" />
       <h5>{t('whoWeArePageMissionBelow')}</h5>
      </div>

      <div className={styles.wwaOurServices}>
        <h2 className='text-2xl p-2 bg-green-200 w-max mt-10 mb-3'>{t('whoWeArePageOurServices')}</h2>
          <div className={styles.wwaOurServicesInner}>

          <div className={styles.wwaOurServicesEach}>
          <img src={assetManagement} alt="image" />
          <h4>{t('whoWeArePageOurServicesOneTitle')}</h4>
          <p>{t('whoWeArePageOurServicesOne')}</p>
        </div>

        <div className={styles.wwaOurServicesEach}>
          <img src={privateWealth} alt="image" />
          <h4>{t('whoWeArePageOurServicesTwoTitle')}</h4>
          <p>{t('whoWeArePageOurServicesTwo')}</p>
        </div>

        <div className={styles.wwaOurServicesEach}>
          <img src={security} alt="image" />
          <h4>{t('whoWeArePageOurServicesThreeTitle')}</h4>
          <p>{t('whoWeArePageOurServicesThree')}</p>
        </div>

          </div>
       

      </div>

      <div className={styles.howWeBenefit}>
        <img src={howWeBenefit} alt="how we benefit" />
        <HowWeBenefitSlide />
      </div>
      <div className={styles.globalInfluence}>
        <div className={styles.giImage}>
        <img
         onMouseEnter={(e) => onHover(e)}
        //  onMouseLeave={(e) => onHoverOver(e)}
        src={globalReach} alt="" />
     {hover &&   <span>
           <h1>{t('whoWeArePageBelowServicesPicture')}</h1>
        </span>
        }
        </div>
       
     
        <div className={styles.giContent}>
        <h2 className='text-2xl p-2 bg-green-200 w-max mt-10 mb-3'>{t('whoWeArePageBelowServicesPictureBelow')}</h2>
        <p>{t('whoWeArePageBelowServicesPictureParagraph')}</p>

        </div>
     
      </div>
      <div className={styles.reinvent}>
        <img src={reInvent} alt="image" />
        <div>

        <h2>{t('whoWeArePageBelowServicesPictureBottomTitle')}</h2>
        <p>{t('whoWeArePageBelowServicesPictureBottomContent')}</p>

        </div>

      </div>
      <Footer />
      
  
    </>
  )
} 

export default WhoWeAre
