import React, { useEffect, useState } from 'react'
import { FaWallet } from "react-icons/fa";
import './walletSlideColum.css'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {setFetchedUserInfo, selectFetchedUserInfo } from '../slices/userSlice'


const WalletSlideColumn = () => {

  const {t} = useTranslation()

  const fetchedUserInfo = useSelector(selectFetchedUserInfo)

  console.log(fetchedUserInfo)

  const walletsArray = [
    {
        id:1,
        name: `${t('walletSlidesDaily')}`,
        balance: `${fetchedUserInfo.dailyWalletTotal}`,
        // <FaWallet className='text-4xl text-sky-800' />
    },
    {
        id:2,
        name: `${t('walletSlidesCompounding')}`,
        balance: `${Math.round(fetchedUserInfo.compoundingWalletTotal)}`,
        // <FaWallet className='text-4xl text-sky-800' />
    },
    {
        id:3,
        name: `${t('walletSlidesDownline')}`,
        balance: `${fetchedUserInfo.downlineCommissionWalletTotal}`,
        // <FaWallet className='text-4xl text-sky-800' />
    },
    {
        id:4,
        name: `${t('walletSlidesReferral')}`,
        balance: `${fetchedUserInfo.referralCommissionWalletTotal}`,
        // <FaWallet className='text-4xl text-sky-800' />
    },
    {
        id:5,
        name: `${t('walletSlidesBonus')}`,
        balance:`${fetchedUserInfo.bonusWalletTotal}`,
        // <FaWallet className='text-4xl text-sky-800' />
    },
    {
        id:6,
        name: `${t('dashboardHomeMain')}`,
        balance: `${fetchedUserInfo.mainWallet}`,
        // <FaWallet className='text-4xl text-sky-800' />
    },
]

    const [curr, setCurr] = useState(0);

    let autoSlide = true;
    let autoSlideInterval = 5000;

    const prev = () =>
    setCurr((curr) => (curr === 0 ? walletsArray.length - 1 : curr - 1))
  const next = () =>
    setCurr((curr) => (curr === walletsArray.length - 1 ? 0 : curr + 1))

  useEffect(() => {
    if (!autoSlide) return
    const slideInterval = setInterval(next, autoSlideInterval)
    return () => clearInterval(slideInterval)
  }, [])




  return (
    <div className='walletSlide-container'>
     
    {/* <div className="next-prev">
      <ArrowBackIosIcon onClick={prev} className='test-button' />
      <ArrowForwardIosIcon onClick={next} className='test-button' />
    </div> */}

    {
      walletsArray.map((walletData,index)=>(
        <div key={walletData.id} className={index === curr ? 'walletSlide activate' : 'walletSlide'}>

      {index === curr && (
        <>
             <p>{walletData.name}</p>
            <h3>${walletData.balance}</h3>
            <FaWallet className='text-3xl text-sky-800 text-center mx-auto p-2 bg-green-400 rounded-full' />
          </>
      )}
        </div>
      ))
    }
    
  </div>
  )
}

export default WalletSlideColumn
