import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import PaymentFailureComponent from '../components/PaymentFailureComponent'
import Sidebar from '../components/sidebar/Sidebar'
import styles from './transferFromWalletAmount.module.css'

const PaymentFailure = () => {
  return (
    <div className={styles.transfersContainer}>
    <Sidebar />
<div className={styles.transfersInnerContainer}>

<DashboardNavbar />
      <PaymentFailureComponent />
     </div>
    </div>
  )
}

export default PaymentFailure
