import React, { useState,useEffect } from 'react'
import styles from './transfersColumn.module.css'
import moment from 'moment';
import robot from '../assets/crownstreetmen.png'
import {useLocation, useNavigate} from 'react-router-dom'
import { IoArrowUndo } from "react-icons/io5";
import purchaseImage from '../assets/horsePurchase.png'
import { useSelector, useDispatch } from 'react-redux'
import { selectPortfolioDetails, setPortfolioDetails} from '../slices/PortfolioSlice';
import { selectUserInfo} from '../slices/authSlice';
import { Player } from '@lottiefiles/react-lottie-player';
import robotAnimation from '../assets/crownRobotAni.json'
import { useTranslation } from 'react-i18next'

const ReInvestAmountComponent = () => {
    const location = useLocation();
    const {state} = location;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const reduxedPortfolioDetails = useSelector(selectPortfolioDetails) 
  
    const [selectedOption, setSelectedOption] = useState('compounding');

    const [count, setCount] = useState(0)
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
    const userInfo = useSelector(selectUserInfo)

    const {t} = useTranslation()

    useEffect(()=>{
        const fetchCount = async()=>{

            var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${API_ENDPOINT}/api/count`, requestOptions)
      .then(response => response.json())
      .then(result => {
       setCount(result.count)
     
        console.log(result)
      })
      .catch(error => console.log('error', error));

  }

  fetchCount()
    },[])

    const handleCheckboxChange = (event) => {
        setSelectedOption(event.target.value);
        console.log(selectedOption)
      };

      const portfolioDetails = {
        selectedOption,
        amount:state.amount,
        dailyProfit: state.dailyROI,
        compoundingProfit: state.compoundingROI,
      }
      const handlePurchaseSubmit=async(e)=>{
        e.preventDefault()

        dispatch(setPortfolioDetails(portfolioDetails))
        // call the plisio payment function
        // plisio(Number(state.amount), count, userInfo.user.email)
      }

      // console.log(reduxedPortfolioDetails)
      // console.log(count)

              // Example data

              const [curr, setCurr] = useState(0);

              let autoSlide = true;
              let autoSlideInterval = 5000;
         
           
      const sliderData = [
        {
          day: state.tomorrow,
          projection: state.tomorrowProjection,
          compoundingProjection: state.tomorrowCompoundingProjection,
          bgColor: 'white',
        },
        {
          day: state.nextTomorrow,
          projection: state.nextTomorrowProjection,
          compoundingProjection: state.nextTomorrowCompoundingProjection,
          bgColor: 'green-50',
        },
        {
          day: state.nextNextTomorrow,
          projection: state.nextNextTomorrowProjection,
          compoundingProjection: state.nextNextTomorrowCompoundingProjection,
          bgColor: 'green-100',
        },
        {
          day: state.fourthDay,
          projection: state.fourthDayProjection,
          compoundingProjection: state.fourthDayCompoundingProjection,
          bgColor: 'green-200',
        },
        {
          day: state.fifthDay,
          projection: state.fifthDayProjection,
          compoundingProjection: state.fifthDayCompoundingProjection,
          bgColor: 'green-300',
        },
        
      ];
      const prev = () =>
      setCurr((curr) => (curr === 0 ? sliderData.length - 1 : curr - 1))
      const next = () =>
      setCurr((curr) => (curr === sliderData.length - 1 ? 0 : curr + 1))
      
      useEffect(() => {
      if (!autoSlide) return
      const slideInterval = setInterval(next, autoSlideInterval)
      return () => clearInterval(slideInterval)
      }, [])
        

      // {t('loanPageAmount')}

  return (
    <div className={styles.transferImagesContainer}>

    <img
            src={purchaseImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
    
            />
  <div style={{margin:'20px auto'}} className={styles.transferContainer}>

<div style={{width:'80vw', margin:'auto', display:'flex', alignItems:'center', justifyContent:'center'}} className='flex gap-3  items-center'>
<div style={{display:window.innerWidth > 500 ? 'block' : 'none'}}>
{/* <img
src={robot}
className={styles.loginImage}
style={{ width: 300, height: 200, zIndex:10, objectFit:'contain' }} // optional
alt='welcome image'
/> */}
              <Player
                  src={robotAnimation}
                  className="player"
                  loop
                  autoplay
                   style={{ height: '200px', width: '200px' }}
                  
                  />
</div>
<form onSubmit={handlePurchaseSubmit} style={{width:window.innerWidth > 500 ? '400px' : '250px'}} className='flex flex-col gap-3 '>
<h1 className='text-2xl font-bold pb-6 pt-12 text-center text-white'>{t('purchaseAmountPageROI')}</h1>
  <p className='text-sky-300 text-center'>{t('purchaseAmountPagePara')}</p>
  <label className={styles.emailText} htmlFor="email">{t('purchaseAmountPageDaily')}</label>
  <input disabled value={state.dailyROI} style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:20, fontWeight:'bold', textAlign:'center'}} type="number" name='roi' />
  <label className={styles.emailText} htmlFor="email">{t('purchaseAmountPageCompounding')}</label>
  <input disabled value={state.compoundingROI} style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:20, fontWeight:'bold', textAlign:'center'}} type="number" name='roi' />
 
 <div className='flex items-center justify-center gap-10'>

 <div className='flex items-center'>
  <input
   value="compounding"
   checked={selectedOption === 'compounding'}
   onChange={handleCheckboxChange}
  className='w-5 h-5' type="checkbox" name="compounding" id="compounding" />
  <label className='ml-2 text-white' htmlFor="compounding">{t('purchaseAmountPageCompoundingCheck')}</label>
  </div>

  <div className='flex items-center'>
  <input 
   value="daily"
   checked={selectedOption === 'daily'}
   onChange={handleCheckboxChange} className='w-5 h-5 ' type="checkbox"  name='daily' id='daily' />
  <label className='ml-2 text-white'  htmlFor="daily">{t('purchaseAmountPageDailyCheck')}</label>
  </div>

 
 </div>
  <button className={styles.loginButton} type="submit">{t('purchaseAmountPageButton')}</button>

</form>
</div>

<div style={{margin:'20px auto'}} className='flex flex-col items-center'>
    <h1 style={{width:'280px',margin:'auto'}} className='text-xl font-bold text-sky-300 text-center'>{t('purchaseAmountPageProjection')}</h1>
  
    <div style={{margin:'auto'}} className={styles.testimonialContainer}>
     
     {/* <div className="next-prev">
       <ArrowBackIosIcon onClick={prev} className='test-button' />
       <ArrowForwardIosIcon onClick={next} className='test-button' />
     </div> */}

     {
       sliderData.map((item,index)=>(
         <div key={index} className={index === curr ? `${styles.slide} ${styles.activate}` : `${styles.slide}`}>

       {index === curr && (
         <div key={index} className={`${styles.sliderItem} bg-${item.bgColor}`}>
         <p>{item.day}</p>
         <p>{selectedOption === 'daily' ? item.projection : item.compoundingProjection}% {t('reInvestAmountOnROI')}</p>
       </div>
       )}
         </div>
       ))
     }
     
   </div>


    {/* <div style={{width:'80%'}} className='flex justify-between items-center bg-white p-2 m-1 shadow-md'>
    <p>{state.tomorrow}</p>
    <p> {selectedOption === 'daily' ? state.tomorrowProjection : state.tomorrowCompoundingProjection}% on ROI</p>
    </div>

    <div style={{width:'80%'}} className='flex justify-between items-center bg-yellow-50 p-2 m-1 shadow-md'>
    <p>{state.nextTomorrow}</p>
    <p> {selectedOption === 'daily' ? state.nextTomorrowProjection : state.nextTomorrowCompoundingProjection}% on ROI</p>
    </div>

    <div style={{width:'80%'}} className='flex justify-between items-center bg-purple-50 p-2 m-1 shadow-md'>
    <p>{state.nextNextTomorrow}</p>
    <p> {selectedOption === 'daily' ? state.nextNextTomorrowProjection : state.nextNextTomorrowCompoundingProjection}% on ROI</p>
    </div>

    <div style={{width:'80%'}} className='flex justify-between items-center bg-purple-50 p-2 m-1 shadow-md'>
    <p>{state.fourthDay}</p>
    <p> {selectedOption === 'daily' ? state.fourthDayProjection : state.fourthDayCompoundingProjection}% on ROI</p>
    </div>

    <div style={{width:'80%'}} className='flex justify-between items-center bg-purple-50 p-2 m-1 shadow-md'>
    <p>{state.fifthDay}</p>
    <p> {selectedOption === 'daily' ? state.fifthDayProjection : state.fifthDayCompoundingProjection}% on ROI</p>
    </div> */}
      
  
</div>

</div>
</div>
  )
}

export default ReInvestAmountComponent
