import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import EmptyContainer from '../components/EmptyContainer'
import Sidebar from '../components/sidebar/Sidebar'
import { useTranslation } from 'react-i18next'

const ExpiredPortfolios = () => {
  const {t} = useTranslation()

  return (
    <div className='portfolio-container'>
         <Sidebar />
    <div className="portfolio-innercontainer">
    
    <DashboardNavbar />
      <EmptyContainer  emptyTitle={t('expiredPortfolioPageEmptyTitle')} emptyDetails={t('expiredPortfolioPageEmptyDetails')} />
     </div>
    </div>
  )
}

export default ExpiredPortfolios
