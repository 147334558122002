import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import FundWalletSuccessComponent from '../components/FundWalletSuccessComponent'
import Sidebar from '../components/sidebar/Sidebar'
import styles from './transferFromWalletAmount.module.css'

const FundWalletSuccess = () => {
  return (
    <div className={styles.transfersContainer}>
    <Sidebar />
<div className={styles.transfersInnerContainer}>

<DashboardNavbar />
      <FundWalletSuccessComponent />
     </div>
    </div>
  )
}

export default FundWalletSuccess
