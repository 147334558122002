import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import WithdrawalHistoryColumn from '../components/WithdrawalHistoryColumn'
import Sidebar from '../components/sidebar/Sidebar'
import styles from './withdrawalHistory.module.css'

const page = () => {
  return (
    <div className={styles.withdrawalContainer}>
             <Sidebar />
    <div className={styles.withdrawalInnercontainer}>
    
    <DashboardNavbar />
      <WithdrawalHistoryColumn />
      </div>
    </div>
  )
}

export default page
