import React,{useState, useEffect} from 'react'
import EachActivePortfolio from './EachActivePortfolio'
import { selectUserInfo, setUserInfo } from '../slices/authSlice';
import {setFetchedUserInfo, selectFetchedUserInfo } from '../slices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import EmptyContainer from './EmptyContainer'
import { useTranslation } from 'react-i18next'

const ActivePortfolioComponents = () => {
  const userInfo = useSelector(selectUserInfo)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
 
    const [allPortfolios, setAllPortfolios] = useState([])

    const {t} = useTranslation()

    // console.log(allPortfolios[0].dailyProfit)

console.log(userInfo.user._id)

  useEffect(() => {
    const fetchReferrals = async () => {
      var myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${userInfo.token}`);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      try {
        const response = await fetch(`${API_ENDPOINT}/api/portfolios/${userInfo.user._id}`, requestOptions);
        const result = await response.json();
        console.log('called again')
        setAllPortfolios(result.portfolios);
       
        console.log(result);
      } catch (error) {
        console.log('error', error);
      }
    };
    fetchReferrals()
   
  }, [userInfo.token, API_ENDPOINT]); // Add dependencies as needed

  console.log(allPortfolios)
  console.log(userInfo.user.portfolios)

  // const commonPortfolios = allPortfolios.filter(portfolio => userInfo.user.portfolios.some(userPortfolio => userPortfolio === portfolio._id));
  // console.log(commonPortfolios)
  return (
    <div>
       <h1 className='text-xl m-5 font-bold'>{t('activePortfolioPageTitle')}</h1>
       
       <div className='flex flex-wrap items-center gap-10 justify-center'>
     
        {allPortfolios.length ? allPortfolios
        .map(portfolio=>(
             <EachActivePortfolio key={portfolio._id} {...portfolio} />
        ) ) : 
        <EmptyContainer emptyTitle={t('activePortfolioPageEmptyTitle')} emptyDetails={t('activePortfolioPageEmptyDetails')} />
        }
      
     

        </div>
    </div>
  )
}

export default ActivePortfolioComponents
