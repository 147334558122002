import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import DashboardWithdrawal from '../components/DashboardWithdrawal'
import Sidebar from '../components/sidebar/Sidebar'
import './withdrawal.css'

const page = () => {
  return (
    <div className='withdrawal-container'>
          <Sidebar />
    <div className="withdrawal-innercontainer">
    
    <DashboardNavbar />
      <DashboardWithdrawal />
     </div>
    </div>
  )
}

export default page
