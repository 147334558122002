import React from 'react'
import './socialinvestmentprogramme.css'
import socialEnviron1 from '../../assets/grow.jpg'
import socialEnviron2 from '../../assets/clipart.jpg'
import socialEnviron3 from '../../assets/environ.jpg'
import { useTranslation } from 'react-i18next'
import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: socialEnviron1 },
  { url: socialEnviron2 },
  { url: socialEnviron3 },
 
];

const SocialInvestmentProgramme = () => { 
  const {t} = useTranslation()
  return (
    <div className='social-investment-container'>
      <h1>{t('socialInvestment')}</h1>
      <div className="si-content">

      <SimpleImageSlider
        width={'80%'}
        height={504}
        images={images}
        showBullets={true}
        showNavs={true}
        slideDuration={0.5}
        autoPlay={true}
        autoPlayDelay={30} 
      />

      {/* <div className="si-left">
        <img src={socialEnviron1} className='si-image1' alt="" />
        <img src={socialEnviron2} className='si-image2' alt="" />
        <img src={socialEnviron3} className='si-image3' alt="" />

      
      </div> */}
      <div className="si-right">
        <p>{t('socialInvestmentDeOne')}</p>
        <h6>{t('socialInvestmentSub')}</h6>
        <p>{t('socialInvestmentDeTwo')}</p>
      </div>

      </div>
     
    </div>
  )
}

export default SocialInvestmentProgramme
