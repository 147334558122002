import React from 'react'
import styles from './claireXColumn.module.css'
import aiImage from '../assets/ai.jpg'
import aiBenefits from '../assets/aiBenefits.jpg'
import aiBenefits2 from '../assets/aiBenefits2.jpg'
import robotAnimation from '../assets/crownRobotAni.json'
import { Player } from '@lottiefiles/react-lottie-player';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import { useTranslation } from 'react-i18next'

const ClaireXColumn = () => {
    const {t} = useTranslation()
  return (
    <div className={styles.container}>

      <div className={styles.aiImageContainer}>
            <div className='flex flex-col items-center gap-2'>
            <Player
                  src={robotAnimation}
                  className="player"
                  loop
                  autoplay
                   style={{ height: '150px', width: '150px' }}
                  
                  />
                   <p>{t('claireXPageParagraphOne')}</p>
            </div>
           
            <div>
            <img src={aiImage} alt="ai image" />
            </div>
           
      </div>

      <h1 style={{marginTop:'100px'}} className='p-4 text-2xl font-bold mt-4'>{t('claireXPageKeyFeaturesTitle')}</h1>
       <div className={styles.keyFeatures}>
            <div className={styles.eachKeyFeature}>
                <FlightTakeoffIcon className='text-green-800 rounded-full bg-white p-1 text-2xl w-3 h-3'/>
                <h6>{t('claireXPageKeyFeaturesOne')}</h6>
            </div>
            <div className={styles.eachKeyFeature}>
                <AddRoadIcon className='text-green-800 rounded-full bg-white p-1 text-2xl w-3 h-3'/>
                <h6>{t('claireXPageKeyFeaturesTwo')}</h6>
            </div>
            <div className={styles.eachKeyFeature}>
                <BusinessIcon className='text-green-800 rounded-full bg-white p-1 text-2xl w-3 h-3'/>
                <h6>{t('claireXPageKeyFeaturesThree')}</h6>
            </div>
            <div className={styles.eachKeyFeature}>
                <CategoryIcon className='text-green-800 rounded-full bg-white p-1 text-2xl w-3 h-3'/>
                <h6>{t('claireXPageKeyFeaturesFour')}</h6>
            </div>
            <div className={styles.eachKeyFeature}>
                <FamilyRestroomIcon className='text-green-800 rounded-full bg-white p-1 text-2xl w-3 h-3'/>
                <h6>{t('claireXPageKeyFeaturesFive')}</h6>
            </div>
         
        </div>

        <div style={{width:'70%'}} className='bg-green-200 p-3 mt-20 m-auto'>
            <p  style={{ lineHeight:2}}  className='text-2xl'>{t('claireXPageParagraphTwo')}</p>
        </div>

        <h1 style={{marginTop:'100px'}} className='p-4 text-2xl font-bold mt-4'>{t('claireXPageParagraphTitle')}</h1>

        <div className={styles.aiBenefits}>
            <img src={aiBenefits} alt="benefits of Claire" />

            <div>
                <p className='bg-green-100 p-2'>{t('claireXPageMiddleOne')}</p>
                <p className='bg-green-200 p-2'>{t('claireXPageMiddleTwo')}</p>
                <p className='bg-green-300 p-2'>{t('claireXPageMiddleThree')}</p>
                <p className='bg-green-400 p-2'>{t('claireXPageMiddleFour')}</p>
                <p className='bg-green-300 p-2'>{t('claireXPageMiddleFive')}</p>
                <p className='bg-green-200 p-2'>{t('claireXPageMiddleSix')}</p>
                <p className='bg-green-100 p-2'>{t('claireXPageMiddleSeven')}</p>
                <p className='bg-green-50 p-2'>{t('claireXPageMiddleEight')}</p>
            </div>
        </div>

        <div className={styles.babyRobotContainer}>
          <p className='text-green-600 text-2xl m-11'>{t('claireXPageBottom')}</p>
                    <img src={aiBenefits2} alt="crown street" />
          </div>
        
          <p className='text-green-600 text-4xl m-11 text-center font-bold'>{t('claireXPageBottomQuote')}</p>
    </div>
  )
}

export default ClaireXColumn
