import React, { useEffect, useState } from 'react'
import styles from './transfersToWalletColumn.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import {useLocation, useNavigate} from 'react-router-dom'
import techHandImage from '../assets/transferImg.jpg'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectUserInfo } from '../slices/authSlice'

const TransferToWalletColumn = () => {
    const [showModal, setShowModal] = useState(false)
    const [selectedOption, setSelectedOption] = useState('');
    const navigate = useNavigate()
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)
    const [userPortfolios, setUserPortfolios] = useState([])
    const {t} = useTranslation()
    const [loading, setLoading] = useState(true)
  const userInfo = useSelector(selectUserInfo)
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

    const handleSelectChange = (event) => {
      setSelectedOption(event.target.value);
      console.log(selectedOption)
    };
  
    const handleSubmit = (event) => {
      event.preventDefault();
      // Handle form submission with the selectedOption value
      console.log('Selected Option:', selectedOption);
     if(selectedOption){
      navigate('/transferToWalletAmount',{state:{selectedOption: selectedOption}})
     } else {
      setPopUp(`${t('transferToPopupFail1')}`)
      setShowPopupModal(true)
     }
    
    };

    useEffect(()=>{

      const fetchUserPortfolios =async()=>{
   
   
       var myHeaders = new Headers();
       myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
   
       var requestOptions = {
           method: 'GET',
           headers: myHeaders,
           redirect: 'follow'
         };
         
         fetch(`${API_ENDPOINT}/api/portfolios/${userInfo.user._id}`, requestOptions)
           .then(response => response.json())
           .then(result =>{
             console.log(result.portfolios)
             setUserPortfolios(result.portfolios)
   
           //   if(result.message === 'Transfers initialized' ){
           //     setLoading(false)
           //      console.log(result)
           //       setTransferOtp(result.transfer.transferToken)
           //       setTransferID(result.transfer._id)
           //       setShowOTPModal(true)
           //      const form = e.target;
           //      form.reset()
     
              
           //   }
           //   else {
           //     setPopUp(`${t('transferFromMainWalletFail4')}`)
           //     setShowPopupModal(true)
           //   }
            setLoading(false)
           })
           .catch(error => console.log('error', error));
   
      }
   
      fetchUserPortfolios()
     }, [])
    
    const handleClosePopup =()=>{
      setShowPopupModal(false)
       setPopUp('')
      }
    

  return (
    <div className={styles.transferImagesContainer}>

         {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
   <img
        src={techHandImage}
        alt='welcome image'
        quality={100}
       className={styles.transferHeroImage}

        />
        <div className={styles.transferContainer}>

        {/* <div style={{maxHeight:'max-content'}} className='flex gap-3 my-11 bg-sky-100/50 mx-11 p-5 items-center  backdrop-filter backdrop-blur-lg bg-opacity-50'> */}
        <div style={{maxHeight:'max-content'}} className='flex gap-3 my-11 mx-11 p-5 items-center '>
       
        <form style={{width:window.innerWidth > 300 ? '300px' : '220px', margin:'auto'}} className='flex flex-col gap-3 ' action="">
  <h1 className='text-2xl font-bold pb-6 pt-2 text-center text-white sm:text-4xl'>{t('chooseTransfersToMain')}</h1>
  {/* <label className={styles.emailText} htmlFor="wallets">Choose Wallet</label> */}
  {/* <input style={{backgroundColor:'white', height:'40px', width:'100%'}} type="email" name='email' /> */}
  <div style={{height:40, backgroundColor:'white'}}>

  <select style={{width:'80%'}}  className='outline-none'  value={selectedOption} onChange={handleSelectChange}>
  <option value="">{t('transferSelectWallet')}/Portfolio...</option>
    {/* <option className='p-3' value="daily">{t('walletSlidesDaily')}</option> */}
    {/* <option value="compoundingProfitWallet">Compounding Profit Wallet</option> */}
    <option value="referralCommission">{t('walletSlidesReferral')}</option>
    <option value="bonusWallet">{t('walletSlidesBonus')}</option>
    {userPortfolios.map(portfolio=>(
                              <option key={portfolio._id} value={portfolio._id}>Portfolio 1 ({portfolio.type === 'hybrid' ? Math.round(portfolio.hybridDaily)  : Math.round(portfolio.balance)})</option>
                        ))}
  </select>
 
  </div>
 
<button onClick={handleSubmit}  className={styles.loginButton} type="submit">{t('purchasePortfolioPageButton')}</button>

</form>
</div>

{/* { showModal &&  <div className={styles.modal}>
< FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
<div className='flex gap-3 my-11 bg-sky-200 mx-11 p-5 items-center'>
<div>
<img
  src={robot}
  style={{ width: 600, height: 400, zIndex:10, objectFit:'contain' }} // optional
  alt='welcome image' 
/>
</div>
<form style={{width:'300px'}} className='flex flex-col gap-3 ' action="">
  <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>Transfer to Main Wallet</h1>
  <label className={styles.emailText} htmlFor="email">Amount</label>
  <input style={{backgroundColor:'white', height:'40px', width:'100%'}} type="email" name='email' />
 
  <button className={styles.loginButton} type="submit">Transfer to Main Wallet</button>

</form>
</div>

</div> } */}
</div>

</div>
  )
}

export default TransferToWalletColumn
