import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import TransferFromWalletAmountComponent from '../components/TransferFromWalletAmountComponent'
import styles from './transferFromWalletAmount.module.css'
import { IoArrowUndo } from "react-icons/io5";
import { useNavigate} from 'react-router-dom'
import Sidebar from '../components/sidebar/Sidebar'

const TransferFromWalletAmount = () => {
    const navigate = useNavigate()

  return (
    <div className={styles.transfersContainer}>
        <Sidebar />
    <div className={styles.transfersInnerContainer}>
    
    <DashboardNavbar />
      <IoArrowUndo onClick={()=>navigate(-1)} className={styles.backButton} />
      
        <TransferFromWalletAmountComponent />
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default TransferFromWalletAmount
