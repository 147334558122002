import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import AccountActivitiesColumn from '../components/AccountActivitiesColumn'
import Sidebar from '../components/sidebar/Sidebar'
import './portfolio.css'

const page = () => {
  return (
    <div className="portfolio-container">
         <Sidebar />
    <div className="portfolio-innercontainer">
    
    <DashboardNavbar />
      <AccountActivitiesColumn />
      </div>
      
    </div>
  )
}

export default page
