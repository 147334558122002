import React, { useEffect, useState } from 'react'
import './home.css'
import Awards from '../components/awards/Awards'
import Footer from '../components/footer/Footer'
import HeroColumn from '../components/hero/HeroColumn'
import HowWeInvest from '../components/howWeInvest/HowWeInvest'
import InvestmentProgrammes from '../components/investmentProgrammes/InvestmentProgrammes'
import Navbar from '../components/navbar/Navbar'
import SocialInvestmentProgramme from '../components/socialinvestmentprogramme/SocialInvestmentProgramme'
import WhyUs from '../components/whyUs/WhyUs'
import QualityIndicators from '../components/qualityIndicators/QualityIndicators'
import News from '../components/News'
import CryptoBinance from '../components/CryptoBinance'
import { MarketOverview } from "react-ts-tradingview-widgets";
import TopTag from '../components/TopTag'
import { Player } from '@lottiefiles/react-lottie-player';
import { FaRegTimesCircle } from 'react-icons/fa'
import robotAnimation from '../assets/crownRobotAni.json'
import { useTranslation } from 'react-i18next'
import ConferenceOneComponent from '../components/ConferenceOneComponent'
import { MarketData } from "react-ts-tradingview-widgets";
import InvestorsReview from '../components/InvestorsReview'


const Home = () => {

  const [showClaire, setShowClaire] = useState(true)
  const {t} = useTranslation()

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const AIPopUp=()=>{

   

    return(
      <div style={{
        // Adjust the gradient direction and colors as needed
      }} className='claire-container'>
      <div  className='claire-innerContainer p-2 bg-green-800 flex flex-col items-center gap-4 text-base-content backdrop-filter backdrop-blur-lg bg-opacity-30'>
        <FaRegTimesCircle onClick={()=>setShowClaire(false)} className='text-white text-3xl font-bold cursor-pointer' />
        <Player
                  src={robotAnimation}
                  className="player"
                  loop
                  autoplay
                   style={{ height: '150px', width: '150px' }}
                  
                  />
                  <h1 style={{width:window.innerWidth > 500 ? '70%' : '100%'}} className='text-xl sm:text-2xl text-white font-extrabold text-center'>{t('claireHome')}</h1>
                  <p style={{width: window.innerWidth > 500 ? '80%' : '100%'}} className='text-white text-sm sm:text-xl'>{t('claireHomeDe')}</p>
      </div>
      </div>
    )
  }

  return (
    <div style={{width: '100vw', overflowX: 'hidden'}}>
      { showClaire ? <AIPopUp /> : null}
      <TopTag />
       <Navbar />
       <div className="home-inner-container">

       <HeroColumn />
       <WhyUs />
       <QualityIndicators />
       <HowWeInvest />
       <ConferenceOneComponent />
       <InvestmentProgrammes />
       <Awards />
       <SocialInvestmentProgramme />
       <div className='p-8 my-9'>
        {/* <CryptoBinance /> */}


      <MarketData colorTheme="dark" width="100%" height={400}></MarketData>
     
        </div>

      {/* <InvestorsReview /> */}

        <div style={{width:'80%', margin:'100px auto'}} className='flex-1'>
        <MarketOverview colorTheme="light" height={400} width="100%" showFloatingTooltip></MarketOverview>
         </div>
       <News />
     
       </div>
       
       <Footer />
    </div>
  )
}

export default Home
