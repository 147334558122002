import React from 'react'
// import TopTag from '../components/TopTag'
import Navbar from '../components/navbar/Navbar'
import ForgetPasswordUpdatePasswordComponent from '../components/ForgetPasswordUpdatePasswordComponent'
import Footer from '../components/footer/Footer'
import TopTag from '../components/TopTag'

const ForgetPasswordUpdate = () => {
  return (
    <div>
      <TopTag />
      <Navbar />
      <ForgetPasswordUpdatePasswordComponent />
      <Footer />
    </div>
  )
}

export default ForgetPasswordUpdate
