
import React, { useContext, useEffect, useRef, useState } from 'react'
import ProfileSummary from './ProfileSummary'
import profileImg from '../assets/profileImage.png'
import { FaCamera } from "react-icons/fa";
import './profileDetails.css'
// import { AuthContext } from '../context/AuthContext';
// import { UserContext } from '../context/UserContext';
import { FaRegTimesCircle } from 'react-icons/fa'
import { Player } from '@lottiefiles/react-lottie-player';
import loaderSRC from '../assets/robotAnimation.json'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'
import Loader from './Loader';
import { useTranslation } from 'react-i18next'

const ProfileDetails = () => {
    const [showModal, setShowModal] = useState(false)
    const cameraRef = useRef(null)
    const [profileImage, setProfileImage] = useState('')
    const [fullname, setFullname] = useState('')
    const [address, setAddress] = useState('')
    const [city, setCity] = useState('')
    const [stateOrigin, setStateOrigin] = useState('')
    const [phone, setPhone] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [userFetchedDetails, setUserFetchedDetails] = useState({})
    const [validID, setValidID] = useState('')

  const [loading, setLoading] = useState(false)

    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)

    const userInfo = useSelector(selectUserInfo)
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'


    const {t} = useTranslation()


  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }
  


    const handleCameraUpload =()=>{
      cameraRef.current.click()
    }

    const handleImageChange = (e) => {
      const file = e.target.files[0];
    console.log(file)
    console.log(e.target.value)
      if (file instanceof Blob) {
        setProfileImage(file);
      } else {
        // Handle the case where the selected file is not a valid Blob or File
        console.error('Invalid file selected');
        // You might want to provide feedback to the user about the invalid file
      }
    };

    const handleValidIDChange = (e) => {
      const file = e.target.files[0];
    console.log(file)
    console.log(e.target.value)
      if (file instanceof Blob) {
        setValidID(file);
      } else {
        // Handle the case where the selected file is not a valid Blob or File
        console.error('Invalid file selected');
        // You might want to provide feedback to the user about the invalid file
      }
    };

    const convertBase64 =(file)=>{
      return new Promise((resolve,reject)=>{
        const fileReader = new FileReader();
        fileReader?.readAsDataURL(file);

        fileReader.onload=()=>{
          resolve(fileReader.result)
        };
        fileReader.onerror=(error)=>{
          reject(error);
        }
      })
     }

    const handleFormSubmit =async(e)=>{
      e.preventDefault()

 

     const base64 = profileImage ? await convertBase64(profileImage) : null
     const validIDBase64 =validID ?  await convertBase64(validID) : null

    //  console.log(base64)
      var myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
console.log(fullname, postalCode, phone, address, city, stateOrigin)

if(!fullname || !phone ||  !address || !city || !stateOrigin || !postalCode ){
  setPopUp(`${t('profilePopupFail2')}`)
  setShowPopupModal(true)
  return
}
var formdata = new FormData();
formdata.append("name", fullname);
formdata.append("phone", phone);
formdata.append("address", address);
formdata.append("city", city);
formdata.append("state", stateOrigin);
formdata.append("postal_code", postalCode);
if(base64){
  formdata.append("profilePic", base64 );
}
if(validIDBase64){
  formdata.append("validID", validIDBase64 );
}


var requestOptions = {
  method: 'PATCH',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};

setLoading(true)

fetch(`${API_ENDPOINT}/api/users/updateUser`, requestOptions)
  .then(response => response.json())
  .then(result => {
    setLoading(false)
    if(result.message === 'Profile Updated Successfully'){
      setPopUp(`${t('profilePopupSuccess')}`)
    setShowPopupModal(true)
    console.log('updated profile',result)
    }else{
      setPopUp(`${t('profilePopupFail1')}`)
      setShowPopupModal(true)
      console.log('updated profile',result)
    }
    
  })
  .catch(error => console.log('error', error));



    }

    useEffect(()=>{

      const fetchUser = async()=>{

                var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(`${API_ENDPOINT}/api/users/currentUser`, requestOptions)
          .then(response => response.json())
          .then(result => {
            setUserFetchedDetails(result)
            // dispatch({type:"USER_SAVED", payload: result});
           
            // console.log(result)
          })
          .catch(error => console.log('error', error));

      }

      fetchUser()
    },[])
    

    // console.log(userDetails)
  return (
    <div style={{width:'80%', margin:'100px auto'}} className='profileDetailsContainer'>
 {loading ? <Loader /> : null}
    {/* popup */}
    { showPopupModal && 
   <div className='popContainer'>
       
        <div className='innerPopContainer'>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className='popClose' />
            <div className='flex gap-2 flex-col'>
            <Player
          src={loaderSRC}
          className="player"
          loop
          autoplay
           style={{ height: '50px', width: '50px' }}
          
          />
            <p className='popText'>{popUp}</p>
            </div>
           
            <button className='popButton' onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}

          <div className='profileLeft'>
          <ProfileSummary />
          </div>
       
       
        <div className='profileRight' >
       

          <form onSubmit={handleFormSubmit} className='flex flex-col pt-8'>
          <div className='flex flex-col items-center justify-center'>
            <div
           
            style={{position:'relative',width:150, height:150,borderRadius:'100%', borderBottom:'2px solid skyBlue'}} >
         {profileImage instanceof Blob ? (
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '100%',
                  }}
                  src={URL.createObjectURL(profileImage)}
                  alt="profile image"
                />
              ) : (
                <img
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '100%',
                  }}
                  src={profileImg}
                  alt="profile image"
                />
              )}
            <FaCamera onClick={handleCameraUpload} style={{position:'absolute', bottom:0, right:20, cursor:'pointer', fontSize:30}} />
            <input style={{display:'none'}} type="file" onChange={handleImageChange} ref={cameraRef}/>
            </div>
         
          </div>

          <label className='text-2xl font-bold pb-1 text-green-900 mt-5' htmlFor="fullname">{t('profilePageName')}</label>
        <input 
        onChange={(e)=>setFullname(e.target.value)}
         value={fullname} 
         style={{textTransform: 'uppercase'}}
         className='h-10 p-2 bg-gray-50' type='text' placeholder={userFetchedDetails?.name} name='fullname' />
        <label className='text-2xl font-bold pb-1 text-green-900 mt-5' htmlFor="email">{t('profileEmail')}</label>
        <input disabled className='h-10 p-2 bg-gray-300' type='text' placeholder={userFetchedDetails?.email}  name='email'/>
        <label className='text-2xl font-bold pb-1 text-green-900 mt-5' htmlFor="phoneNumber">{t('profilePhone')}</label>
        <input
         onChange={(e)=>setPhone(e.target.value)}
        value={phone}
         className='h-10 p-2 bg-gray-50' type='number' placeholder={userFetchedDetails?.phone ? "***********" : "Phone Number"} name='phoneNumber'/>
        <label className='text-2xl font-bold pb-1 text-green-900 mt-5' htmlFor="address">{t('profileAddress')}</label>
        <input 
         onChange={(e)=>setAddress(e.target.value)}
        value={address}
         className='h-10 p-2 bg-gray-50' type='text' placeholder={userFetchedDetails?.address ? "***********" : "Address"} name='address'/>
        <label className='text-2xl font-bold pb-1 text-green-900 mt-5' htmlFor="city">{t('profileCity')}</label>
        <input
         onChange={(e)=>setCity(e.target.value)}
        value={city}
         className='h-10 p-2 bg-gray-50' type='text' placeholder={userFetchedDetails?.city ? "***********" : "City"} name='city'/>
        <label className='text-2xl font-bold pb-1 text-green-900 mt-5' htmlFor="state">{t('profileState')}</label>
        <input 
         onChange={(e)=>setStateOrigin(e.target.value)}
        value={stateOrigin} 
        className='h-10 p-2 bg-gray-50' type='text' placeholder={userFetchedDetails?.state ? "***********" : "State"} name='state'/>
        <label className='text-2xl font-bold pb-1 text-green-900 mt-5' htmlFor="postalCode">{t('profilePostal')}</label>
        <input 
         onChange={(e)=>setPostalCode(e.target.value)}
        value={postalCode} 
        className='h-10 p-2 bg-gray-50' type='text' placeholder={userFetchedDetails.postal_code ? "***********" : "Postal Code"} name='postalCode'/>
        <label className='text-2xl font-bold pb-1 text-green-900 mt-5' htmlFor="idCard">{t('profileID')}</label>
        <input 
         onChange={handleValidIDChange}
        className="block w-full text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" type='file' placeholder='Upload a Valid ID' name='idCard'/>
              <button className='btnn'>{t('profileUpdate')}</button>
                    

          </form>
   
        </div>

    </div>
  )
}

export default ProfileDetails
