import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import ProfileDetails from '../components/ProfileDetails'
import Sidebar from '../components/sidebar/Sidebar'
import './portfolio.css'

const page = () => {
  return (
    <div className='portfolio-container'>
          <Sidebar />
    <div className="portfolio-innercontainer">
    
    <DashboardNavbar />
      <ProfileDetails />
     </div>
    </div>
  )
}

export default page
