import { createContext, useEffect, useReducer } from "react";


// JSON.parse(localStorage.getItem('withdrawal')) ||
const INITIAL_STATE ={
    withdrawal:  null,
    loading: false,
    error: null
};

export const WithdrawalContext = createContext(INITIAL_STATE);


const WithdrawalReducer = (state, action)=>{
    switch (action.type){
        case "WITHDRAWAL_START":
            return{
                withdrawal:null,
                loading:true,
                error:null,
            };
            case "WITHDRAWAL_SUCCESS":
                return{
                    withdrawal:action.payload,
                    loading:false,
                    error:null,
                };
             case "WITHDRAWAL_FAIL":
                 return{
                        withdrawal:null,
                        loading:false,
                        error:action.payload,
                    };
        
         default:
             return state;
    }
};

export const WithdrawalContextProvider = ({children})=>{
    const [state, dispatch] = useReducer(WithdrawalReducer, INITIAL_STATE);
    useEffect(()=>{
        // localStorage.setItem('withdrawal', JSON.stringify(state.withdrawal))
    },[state.withdrawal])
    return (
        <WithdrawalContext.Provider
        value={{
            withdrawal: state.withdrawal,
            loading: state.loading,
            error: state.error,
            dispatch
        }}
        >
            {children}
        </WithdrawalContext.Provider>
    )
}