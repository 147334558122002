import React, { useEffect, useState } from 'react'
import styles from './fundWallet.module.css'
import Loader from './Loader'
import withdrawalImage from '../assets/withdrawal.jpg'
import { useSelector, useDispatch } from 'react-redux'
import { selectUserInfo} from '../slices/authSlice';
import { FaRegTimesCircle } from 'react-icons/fa'
import { setFundWalletAmount } from '../slices/fundWalletSlice'
import { useTranslation } from 'react-i18next'
import {useNavigate} from 'react-router-dom'
// 
const FundWalletColumn = () => {
    const [amount, setAmount] = useState('');
    const [loading, setLoading] = useState(true);
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
    const userInfo = useSelector(selectUserInfo)
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const {t} = useTranslation()

    // useEffect(()=>{
    //     const fetchCount = async()=>{

    //         var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

    // var requestOptions = {
    //   method: 'GET',
    //   headers: myHeaders,
    //   redirect: 'follow'
    // };

  //   fetch(`${API_ENDPOINT}/api/count`, requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //      setCount(result.count)
     
  //       console.log(result)
  //     })
  //     .catch(error => console.log('error', error));

  // }

  // fetchCount()
  //   },[])

    function handleChange(e) {
        setAmount(e.target.value)
        console.log(amount)

      }



    const handleSubmitAmount =async(e)=>{
      e.preventDefault()
      const dispatchedAmount = {amount:amount}
     if(amount){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
 
 
       var formdata = new FormData();
       formdata.append("amount", amount);

       var requestOptions = {
         method: 'POST',
         headers: myHeaders,
         body: formdata,
         redirect: 'follow'
       };
       
       fetch(`${API_ENDPOINT}/api/plisio/fundWallet`, requestOptions)
         .then(response => response.json())
         .then(result =>{
             console.log(result)
             if(result.message === 'Successful payment'){
              dispatch(setFundWalletAmount(dispatchedAmount))
              window.location.href = `${result.plisioData.data.invoice_url}`;
              // window.open(result.plisioData.data.invoice_url, '_blank');
              // navigate(`/awaitingPayment`);
                 setLoading(false)
                 return
             } else {
               setPopUp(`${t('fundWalletPop')}`)
               setShowPopupModal(true)
               setLoading(false)
               return
             }
            
        
         })
         .catch(error => console.log('error', error));


     } else {
      setPopUp(`${t('fundWalletPop2')}`)
      setShowPopupModal(true)
     
     }
 
      console.log(amount)
    }

    useEffect(() => {
     
      setTimeout(() => {
        setLoading(false);
      }, 3000); 
    }, []);

    const handleClosePopup =()=>{
      setShowPopupModal(false)
       setPopUp('')
      }
    

  return (
    <div style={{width:'100%',display:'flex', alignItems:'center', justifyContent:'center', paddingTop:50, margin:'auto'}}>
    {loading ? <Loader /> : null}

              {/* popup */}
   { showPopupModal &&  
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}

      <div className={styles.downlineTransferContainer}>
  
        <div className={styles.left}>
          <img  src={withdrawalImage} alt="image" />
        </div>
  
      <form className={styles.right}>
            {/* <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>Withdrawal</h1> */}
            {/* <label className={styles.emailText} htmlFor="email">Enter Amount</label> */}
            <input
            onChange={handleChange}
             style={{ height:'50px', width:'100%',outline:'none', marginBottom:'20px'}} placeholder={t('loanPageAmount')} 
             className='bg-sky-100 mt-2 p-2' type="number" name='amount' />
            {/* <input style={{ height:'50px', width:'100%',outline:'none'}} placeholder='Wallet Address' className='bg-sky-100 mt-2 p-2' type="text" name='address' /> */}
            {/* <input style={{ height:'50px', width:'100%',outline:'none'}} placeholder='Wallet Type' className='bg-sky-100 mt-2 p-2 mb-5' type="text" name='crypto' /> */}
           
            <button onClick={handleSubmitAmount} className={styles.loginButton} type="submit">{t('walletsPageFundWallet')}</button>
          </form>
  
      </div>
       
  
  
     {/* { showModal &&  <div className={styles.modal}>
       < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
        <div style={{width:'300px'}} className='flex gap-3 my-11 bg-sky-200 mx-11 p-5 items-center justify-center'>
      
          <form style={{width:'300px'}} className='flex flex-col gap-3 items-center justify-center m-auto' action="">
            <label className={styles.emailText} htmlFor="email">Enter Wallet</label>
            <input style={{backgroundColor:'white', height:'40px', width:'100%'}} type="email" name='email' />
            <button className={styles.loginButton} type="submit">Submit</button>
         
  
          </form>
        </div>
  
        </div> } */}
       
      </div>
  )
}

export default FundWalletColumn
