import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUserInfo } from '../slices/authSlice'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './transfersColumn.module.css'
import OtpInput from 'react-otp-input';
import { FaRegTimesCircle } from 'react-icons/fa'
import Loader from './Loader'
import techHandImage from '../assets/transferImg.jpg'

const TransferPortfolioComponent = () => {
    const [destinationEmail, setDestinationEmail] = useState('')
  const [amount, setAmount] = useState(0)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const userInfo = useSelector(selectUserInfo)
  const [popUp, setPopUp] = useState('')
  const [showPopupModal, setShowPopupModal] = useState(false)
  const [transferOtp, setTransferOtp] = useState('')
  const [transferID, setTransferID] = useState('')
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
  const [showOTPModal, setShowOTPModal] = useState(false)
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('')

  const [userPortfolios, setUserPortfolios] = useState([])
  const [portfolioID, setPortfolioID] = useState('')

  const {t} = useTranslation()


  const handlePortfolioChange = (e) => {
    const { value } = e.target;

    // console.log(value)
    
   setPortfolioID(value)
  };

//   console.log(portfolioID)

  useEffect(()=>{

   const fetchUserPortfolios =async()=>{


    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      
      fetch(`${API_ENDPOINT}/api/portfolios/${userInfo.user._id}`, requestOptions)
        .then(response => response.json())
        .then(result =>{
          console.log(result.portfolios)
          setUserPortfolios(result.portfolios)

     
         setLoading(false)
        })
        .catch(error => console.log('error', error));

   }

   fetchUserPortfolios()
  }, [])

  const handleMakeTransfer =(e)=>{
    e.preventDefault()

    setLoading(true)

    if(!destinationEmail || !portfolioID || amount == 0){
        setPopUp(`Fill in the details to proceed`)
          setShowPopupModal(true)
          return
    }

     // check if email exists
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);


    setLoading(true)
    var formdata = new FormData();
   
    formdata.append("email", destinationEmail);
   

    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
  
    fetch(`${API_ENDPOINT}/api/auth/verify-login-email`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result.message)
        if(result.message === 'Email exits'){
            console.log('Email exists')
            // return
         
        }else if(result.message === 'Email exits but not yet verified'){
          setError(`${t('LoginResponse1')}`)
          setPopUp(`${t('LoginResponse1A')}`)
          setShowPopupModal(true)
          return
        }  else {
          setError(`${t('LoginResponse2')}`)
          setPopUp(`${t('LoginResponse2A')}`)
          setShowPopupModal(true)
          return
          
        }
        setLoading(false)
        // console.log(result)
      })
      .catch(error => console.log('error', error))
      
   

                //  submit transfer initialization

                formdata.append("portfolioID", portfolioID);
                formdata.append("amount", amount);


    if(amount === 0){

      setPopUp('Type an amount')
      setShowPopupModal(true)
      setLoading(false)
      return
     }


   if(amount){
    // var formdata = new FormData();
    // formdata.append("amount", amount);
    // formdata.append("portfolioID", 'toAnotherUser');
    // formdata.append("email", destinationEmail);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${API_ENDPOINT}/api/transfers/initiate-transfer-portfolio`, requestOptions)
      .then(response => response.json())
      .then(result =>{
        console.log(result)
        if(result.message === 'Transfer Portfolio initialized' ){
          setLoading(false)
           console.log(result)
            setTransferOtp(result.transfer.transferToken)
            setTransferID(result.transfer._id)
            setShowOTPModal(true)
           const form = e.target;
           form.reset()

         
        }
        else {
          setPopUp(`${t('transferFromMainWalletFail4')}`)
          setShowPopupModal(true)
        }
       setLoading(false)
      })
      .catch(error => console.log('error', error));


  
 
  
   }else{
    setPopUp(`${t('transferFromMainWalletFail5')}`)
    setShowPopupModal(true)
    setLoading(false)
    return
   }
  }


  const handleChangeOtp = async(value) => {
    // console.log(value);
    setOtp(value)
      // Ensure that the latest value is used
      if (value.length === 4) {
        // verifyToken(prevOtp);
       
        setLoading(true)
        // console.log(value)
        if(Number(value) === transferOtp){

          console.log('tokens match')

              var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);


             var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      // body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${API_ENDPOINT}/api/transfers/transfer-portfolio-account/${transferID}`, requestOptions)
      .then(response => response.json())
      .then(result =>{
        console.log(result)
        if(result.message === 'Transfer Portfolio initialized Successfully' ){
          setLoading(false)
           console.log(result)
        //    setPopUp(`${t('transferToPopupSuccess3')} ${destinationEmail}`)
           setPopUp(`Transfer initialized successfully, Wait for the receiver to Accept before the agreed sum can be transfered to you for the transfer to be completed`)
           setShowPopupModal(true)
           

           setTimeout(()=> navigate('/dashboard'),3000)
        }
        else {
          setPopUp(`${t('transferFromMainWalletFail4')}`)
          setShowPopupModal(true)
        }
       setLoading(false)
      })
      .catch(error => console.log('error', error));


         
          // console.log(value)
        }else{
          setPopUp(`${t('LoginResponse3A')}`)
          setShowPopupModal(true)
          setLoading(false)
          return
        }
        setLoading(false)

      }
      return value;

  };


  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }
  


  useEffect(() => {
   
    setTimeout(() => {
      setLoading(false);
    }, 3000); 
  }, []);


  return (
    <>

    {loading ? <Loader /> : null}
    
    {/* popup */}
    { showPopupModal && 
    <div className={styles.popContainer}>
    
    <div className={styles.innerPopContainer}>
    < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
     <p className={styles.popText}>{popUp}</p>
    <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
    </div>
    
    
    </div>
    }
    {/* popup ends */}
        
        
    
        <div className={styles.transferImagesContainer}>
    
                <img
                src={techHandImage}
                alt='welcome image'
                quality={100}
               className={styles.transferHeroImage}
         
                />
                <div className={styles.transferContainer}>
    
                <div style={{maxHeight:'max-content', marginTop:'150px'}} className='flex gap-3 my-11 mx-11 p-5 items-center '>
              
                <form onSubmit={handleMakeTransfer} style={{width:'300px', margin:'auto'}} className='flex flex-col gap-3 '>
                { !portfolioID && <h1 className='text-2xl font-bold pb-6 pt-2 text-center text-white sm:text-4xl'>Transfer Portfolio</h1>}
                 
                 {portfolioID && <>
                 
                 {
                    
                    userPortfolios.filter(port=> port._id == portfolioID)  
                    .map(portfolio=>(
                        <div key={portfolio._id} className='bg-gray-100 p-2'>

                            <h1 className='text-black text-xl font-bold text-center m-2'>Portfolio details</h1>
                            <h1 className='text-black flex items-center justify-between'> <strong className='flex-1 text-sm'>Portfolio Amount:</strong> <span className='text-lg flex-1 bg-green-300 p-2 mb-1'> ${portfolio.amount}</span></h1>
                            <p className='text-black flex items-center justify-between'> <strong className='flex-1 text-sm'>Portfolio Current Value: </strong> <span className='text-lg flex-1 bg-green-300 p-2 mb-1' >${Math.round(portfolio.balance)}</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio Creation Date:</strong>  <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>{new Date(portfolio.createdAt).toLocaleDateString()} Valid for 9 MONTHS</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio Type:</strong>  <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>{portfolio.type}</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio ROI:</strong>  <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>{portfolio.percentage}</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio Daily Profit:</strong> <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>${Math.round((portfolio.percentage * portfolio.amount)/100)} / DAY</span></p>

                        </div>
                    ))
                 }
                 </>
                 }

                 {userPortfolios.length !== 0 && <> <select onChange={handlePortfolioChange} name="portfolios" id=""  style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:10, outline:'none'}}>
                        <option value="">Select Portfolio...</option>
                        {userPortfolios.map(portfolio=>(
                              <option key={portfolio._id} value={portfolio._id}>Portfolio 1 ({portfolio.amount})</option>
                        ))}
                      
                   
                    </select>
                    </>
                    }

                  <input
                  onChange={(e)=>setDestinationEmail(e.target.value)}
                   style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:10, outline:'none'}}
                    placeholder='Receivers Email'
                   type="email" name='email' />
                  <input
                  onChange={(e)=>setAmount(e.target.value)}
                   style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:10, outline:'none'}}
                    placeholder={t('purchasePortfolioPageAmount')}
                   type="number" name='amount' />
                 
                  <button className={styles.loginButton} type="submit">{t('transferFromMainWalletButton')}</button>
                
                </form>
              </div>
        
           {/* { showModal &&  <div className={styles.modal}>
             < FaRegTimesCircle onClick={()=>setShowModal(false)} className={styles.close} />
              <div className='flex gap-3 my-11 bg-sky-200 mx-11 p-5 items-center'>
                <div>
                <img
                  src={robot}
                  style={{ width: 600, height: 400, zIndex:10, objectFit:'contain' }} // optional
                  alt='welcome image' 
                />
                </div>
                <form style={{width:'300px'}} className='flex flex-col gap-3 ' action="">
                  <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>Transfers</h1>
                  <label className={styles.emailText} htmlFor="email">Amount</label>
                  <input style={{backgroundColor:'white', height:'40px', width:'100%'}} type="email" name='email' />
                 
                  <button className={styles.loginButton} type="submit">Transfer</button>
                
                </form>
              </div>
        
              </div> } */}
                  </div>
    
    
          {showOTPModal && <div className={styles.modal}>
    
    <div style={{marginTop:200}} className='flex flex-col gap-3 my-11 bg-sky-100/50 mx-11 p-7 items-center backdrop-filter backdrop-blur-lg bg-opacity-30'>
    
    
        <h2 className='text-2xl mb-7 text-center font-bold text-white'>{t('forgetPasswordVerifyTokenTitle')}</h2>
        <OtpInput
          containerStyle={{ margin: 20 }}
          inputStyle={{ width: 60, height: 60, borderRadius: 10, margin: 10, backgroundColor:'white' }}
          shouldAutoFocus
          value={otp}
          inputType="tel"
          onChange={handleChangeOtp}
          numInputs={4}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          // renderInput={(props, index) => (
          //   <input
          //     {...props}
          //     onFocus={(e) => {
          //       // Move cursor to the end on focus
          //       e.target.setSelectionRange(e.target.value.length, e.target.value.length);
          //     }}
          //   />
          // )}
        />
    
        </div>
    
    </div> 
        }
                
              
            </div>
            </>
  )
}

export default TransferPortfolioComponent
