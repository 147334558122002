import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAnimationEnabled: JSON.parse(localStorage.getItem('isAnimationEnabled')) || false,
};

export const animationSlice = createSlice({
  name: 'animation',
  initialState,
  reducers: {
    enableAnimation: (state) => {
      state.isAnimationEnabled = true;
      // Save to localStorage
      localStorage.setItem('isAnimationEnabled', JSON.stringify(true));
    },

    disableAnimation: (state) => {
      state.isAnimationEnabled = false;
      // Save to localStorage
      localStorage.setItem('isAnimationEnabled', JSON.stringify(false));
    },
  },
});

export const { enableAnimation, disableAnimation } = animationSlice.actions;

export const selectAnimationStatus = (state) => state.animation.isAnimationEnabled;

export default animationSlice.reducer;