import React from 'react'
import './heroColumn.css'
import  video1 from '../../assets/video1.mp4'
import homeImage from '../../assets/horse.jpg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const HeroColumn = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()


  const handleRedirect=()=>{
    navigate('/login')
  }
  const handleRedirectSurvey=()=>{
    navigate('/survey')
  }


  return (
    <div className='hero-container'>
      
      {/* <video   src={video1}
        type="video/mp4"
        height={550}
        loop
        controls={false}
        muted
        autoPlay /> */}
    
          <img
        src={homeImage}
        alt='welcome image'
        quality={100}
       className='heroImage'

        />
       
      <div className="hero-content">
      <h2 style={{fontSize:'10px'}} className='text-green-800 font-bold bg-white p-2'>CROWNSTREET HOLDINGS</h2>
        <h1 >{t('hero')}</h1>
        <button onClick={handleRedirect}>{t('heroAction')}</button>
        <button onClick={handleRedirectSurvey}>Take Survey</button>
      </div>

    </div>
  )
}

export default HeroColumn
