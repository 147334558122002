import React, { useEffect, useState } from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import EachReferralColumn from '../components/EachReferralColumn'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'
import EmptyContainer from '../components/EmptyContainer';
import Sidebar from '../components/sidebar/Sidebar';
import './portfolio.css'
import { useTranslation } from 'react-i18next'

const Referral = () => {
  const userInfo = useSelector(selectUserInfo)
  const [allReferrals, setAllReferrals] = useState([])
  const [emptyRef, setEmptyRef] = useState([])

  const ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

  const userID = userInfo.user._id

  const {t} = useTranslation()


  useEffect(()=>{
    const fetchUserReferrals =async()=>{

      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
      fetch(`${ENDPOINT}/api/referrals/${userInfo.user._id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setAllReferrals(result.referrals)
          console.log(result)})
        .catch(error => console.log('error', error));
    }

    fetchUserReferrals()
  },[])
  // console.log(allReferrals)
  // {t('loanPagePopup3')}
  return (
    <div className='portfolio-container'>
         <Sidebar />
    <div className="portfolio-innercontainer">
    
    <DashboardNavbar />
      {
        allReferrals?.length === 0 
        ? <EmptyContainer emptyTitle={t('referralPageEmptyTitle')} emptyDetails={t('referralPageEmptyDetails')} />
        :
        <div style={{ height:'400px'}} className='flex gap-5 flex-wrap m-12 items-center justify-center'>
        {
          allReferrals.map((referral, index)=>(
            <EachReferralColumn key={index} referral={referral}/>
          ))
        }
         
        
      </div>
      }
     
     </div>
   
    </div>
  )
}

export default Referral
