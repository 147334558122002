import React, { useState, useEffect } from 'react'
import styles from './transfersColumn.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import techHandImage from '../assets/transferImg.jpg'
import { IoArrowUndo } from "react-icons/io5";
import {useLocation, useNavigate} from 'react-router-dom'
import Loader from './Loader'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const TransferToWalletAmountComponent = () => {
    const [showModal, setShowModal] = useState(false)
    const location = useLocation();
    const {state} = location;
    const [debitWalletType, setDebitWalletType ] = useState('')
    const [amount, setAmount] = useState(0)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const userInfo = useSelector(selectUserInfo)
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
    const [userPortfolios, setUserPortfolios] = useState([])
  const [filteredPortfolio, setFilteredPortfolio] = useState([])
    const {t} = useTranslation()

    useEffect(()=>{
      setDebitWalletType(state.selectedOption)
    },[])
  

    console.log(debitWalletType)


    const handleMakeTransfer =(e)=>{
      e.preventDefault()

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userInfo.token}`);

      if(debitWalletType === 'bonusWallet' || debitWalletType === 'referralCommission'){

        if(amount){
          var formdata = new FormData();
          formdata.append("amount", amount);
          formdata.append("debitWallet", debitWalletType);
          
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
          };
          
          fetch(`${API_ENDPOINT}/api/transfers/make-transfer`, requestOptions)
            .then(response => response.json())
            .then(result =>{
              if(result.message === 'Transfers successful' ){
                setLoading(false)
                 console.log(result)
                 setPopUp(`${t('transferToPopupSuccess1')}`)
                 setShowPopupModal(true)
              }
              else {
                setPopUp(`${t('transferToPopupFail2')}`)
                setShowPopupModal(true)
              }
             setLoading(false)
            })
            .catch(error => console.log('error', error));
    
        
         }

      }else {
        if(amount){
          var formdata = new FormData();
          formdata.append("amount", amount);
          formdata.append("debitWallet", "portfolio");
          
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
          };
          
          fetch(`${API_ENDPOINT}/api/transfers/transfer-from-portfolio-to-main/${debitWalletType}`, requestOptions)
            .then(response => response.json())
            .then(result =>{
              console.log(result)
              if(result.message === 'Transfers successful' ){
                setLoading(false)
                 console.log(result)
                 setPopUp(`${t('transferToPopupSuccess1')}`)
                 setShowPopupModal(true)

                 setTimeout(()=>navigate('/chooseTransfers'), 3000)
              }
              else {
                setPopUp(`${t('transferToPopupFail2')}`)
                setShowPopupModal(true)
              }
             setLoading(false)
            })
            .catch(error => console.log('error', error));
    
        
         }
      }

    }

    // useEffect(()=>{

      // const filterPortfolios=()=>{

        // if(debitWalletType !== 'bonusWallet' || debitWalletType !== 'referralCommission'){
          const filtered = userPortfolios.filter(port=>port._id == debitWalletType)
          // setFilteredPortfolio(filtered);
   
        //  }

      // }

      // filterPortfolios()
  
    // }, [debitWalletType])

    // console.log(filteredPortfolio)
    console.log(userPortfolios)


    useEffect(()=>{

      const fetchUserPortfolios =async()=>{
   
   
       var myHeaders = new Headers();
       myHeaders.append("Authorization", `Bearer ${userInfo.token}`);
   
       var requestOptions = {
           method: 'GET',
           headers: myHeaders,
           redirect: 'follow'
         };
         
         fetch(`${API_ENDPOINT}/api/portfolios/${userInfo.user._id}`, requestOptions)
           .then(response => response.json())
           .then(result =>{
             console.log(result.portfolios)
             setUserPortfolios(result.portfolios)
   
        
            setLoading(false)
           })
           .catch(error => console.log('error', error));
   
      }
   
      fetchUserPortfolios()
     }, [])

    const handleClosePopup =()=>{
      setShowPopupModal(false)
       setPopUp('')
      }
    


    useEffect(() => {
     
      setTimeout(() => {
        setLoading(false);
      }, 3000); 
    }, []);

  return (
    <>
    
    

    <div className={styles.transferImagesContainer}>
      {loading ? <Loader /> : null}

            {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}

    <img
            src={techHandImage}
            alt='welcome image'
            quality={100}
           className={styles.transferHeroImage}
    
            />
            <div className={styles.transferContainer}>

            <div style={{maxHeight:'max-content'}} className='flex gap-3 my-11 mx-11 p-5 items-center '>
          
            <form onSubmit={handleMakeTransfer} style={{width:'300px', margin:'auto'}} className='flex flex-col gap-3 ' action="">
            {/* <h1 className='text-2xl font-bold pb-6 pt-8 text-center text-white sm:text-4xl'>{t('chooseTransfersToMain')}</h1> */}
          

          {/* show the selected portfolio details if any */}
            {filtered.length !== 0 ? <div>

              { filtered.map(portfolio=>(
                        <div key={portfolio._id} className='bg-gray-100 p-2'>

                            <h1 className='text-black text-xl font-bold text-center m-2'>Portfolio details</h1>
                            <h1 className='text-black flex items-center justify-between'> <strong className='flex-1 text-sm'>Portfolio Amount:</strong> <span className='text-lg flex-1 bg-green-300 p-2 mb-1'> ${portfolio.amount}</span></h1>
                            <p className='text-black flex items-center justify-between'> <strong className='flex-1 text-sm'>Portfolio Current Value: </strong> <span className='text-lg flex-1 bg-green-300 p-2 mb-1' >${Math.round(portfolio.balance)}</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio Creation Date:</strong>  <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>{new Date(portfolio.createdAt).toLocaleDateString()} Valid for 9 MONTHS</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio Type:</strong>  <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>{portfolio.type}</span></p>
                            {portfolio.type === 'hybrid' ?   <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Daily Wallet:</strong>  <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>${portfolio.hybridDaily}</span></p> : null}
                            {portfolio.type === 'hybrid' ?   <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Compounding Wallet:</strong>  <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>${portfolio.hybridCompounding}</span></p> : null}
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio ROI:</strong>  <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>{portfolio.percentage}</span></p>
                            <p className='text-black flex items-center justify-between'><strong  className='flex-1 text-sm'>Portfolio Daily Profit:</strong> <span  className='text-lg flex-1 bg-green-300 p-2 mb-1'>${Math.round((portfolio.percentage * portfolio.amount)/100)} / DAY</span></p>

                        </div>
                    ))}

            </div> : null}

              <input style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:10, outline:'none'}}
                placeholder={t('purchasePortfolioPageAmount')}
                onChange={(e)=>setAmount(e.target.value)}
               type="number" name='amount' />
             
              <button className={styles.loginButton} type="submit">{t('transferToWalletButton')}</button>
            
            </form>
          </div>
    
              </div>
            
          
        </div>
        </>
  )
}

export default TransferToWalletAmountComponent
