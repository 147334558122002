import React from 'react'
import DashboardNavbar from '../components/DashboardNavbar'
import DashboardSidebar from '../components/DashboardSidebar'
import ActivePortfolioComponents from '../components/ActivePortfolioComponents'
import Sidebar from '../components/sidebar/Sidebar'
import { useTranslation } from 'react-i18next'

const page = () => {


  return (
    <div className='portfolio-container'>
        <Sidebar />
    <div className="portfolio-innercontainer">
    
    <DashboardNavbar />
      <ActivePortfolioComponents />

     </div>
    </div>
  )
}

export default page
