import React, { useEffect, useState } from 'react'
import Loader from './Loader';
import styles from './allWallets.module.css'
import { FaWallet } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import {  useSelector } from 'react-redux'
import { selectFetchedUserInfo } from '../slices/userSlice'
import { selectUserInfo } from '../slices/authSlice';
import { useTranslation } from 'react-i18next'


const AllWallets = () => {
    const [loading, setLoading] = useState(true);
    const fetchedUserInfo = useSelector(selectFetchedUserInfo)
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'
    const [fetchedPortfolio, setFetchedPortfolio] = useState([])
    const userInfo = useSelector(selectUserInfo)
    console.log(fetchedUserInfo)

    const {t} = useTranslation()

    useEffect(() => {
      const fetchUserPortfolio = async () => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${userInfo.token}`);
  
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };
  
        try {
          const response = await fetch(`${API_ENDPOINT}/api/portfolios/${userInfo.user._id}`, requestOptions);
          const result = await response.json();
          // console.log('called again')
          setFetchedPortfolio(result.portfolios)
          console.log(result);
        } catch (error) {
          console.log('error', error);
        }
      };
  
      // Initial fetch
      fetchUserPortfolio();
  
      // Set up interval for fetching every 10 seconds
      const intervalId = setInterval(fetchUserPortfolio, 10000);
  
      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }, [userInfo.token, API_ENDPOINT]); // Add dependencies as needed
  
  
  
    // Calculate the sum of balance for entries with type equal to "daily"
  const sumOfDailyBalance = fetchedPortfolio
  .filter((entry) => entry.type === "daily")
  .reduce((accumulator, entry) => accumulator + entry.balance, 0);
  
  console.log("Sum of balance for daily type:", sumOfDailyBalance);
  
  
  const sumOfCompoundingBalance = fetchedPortfolio
  .filter((entry) => entry.type === "compounding")
  .reduce((accumulator, entry) => accumulator + entry.balance, 0);
  
  console.log("Sum of balance for daily type:", sumOfCompoundingBalance);
  

  
    useEffect(() => {
   
        setTimeout(() => {
          setLoading(false);
        }, 3000); 
      }, []);
  return (
    <div className={styles.allWalletsContainer}>
         {loading ? <Loader /> : null}

       
          <div style={{width:'250px', margin:'120px auto'}}>
          <Link to='/fundWallet' >
          <button className={styles.glowOnHover}>{t('walletsPageFundWallet')}</button>
          </Link>
          </div>
         
      
      <div className={styles.walletsInnerContainer}>

      <div className={styles.mainWallet}>
            <h1 className='text-lg font-bold'>{t('walletsPageMainWallet')}</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number(fetchedUserInfo?.mainWallet) > 0 ? fetchedUserInfo?.mainWallet : 0 }</h4>
            <div  style={{bottom:'-10px'}}  className='flex gap-2 items-center justify-center absolute'>
            <Link to='/transfers'>
            <button  className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>{t('walletsPageTransfer')} </button>
            </Link>
            <Link to='/withdrawal'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>{t('walletsPageWithdraw')} </button>
            </Link>
            <Link to='/reInvest'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>{t('walletsPageReinvest')}</button>
            </Link>
            </div>

        </div>

        <div className={styles.eachWallet}>
            <h1 className='text-lg font-bold'>{t('walletsPageDailyWallet')}</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number((fetchedUserInfo?.dailyWalletTotal)) > 0 ? Math.round((fetchedUserInfo?.dailyWalletTotal)) : 0 }</h4>
            <Link style={{position:'absolute',bottom:'-10px'}} to='/transferToWallet'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>{t('walletsPageTransferToMain')} <FaArrowRight /> </button>
            </Link>
        </div>

        <div className={styles.eachWallet}>
            <h1 className='text-lg font-bold'>{t('walletsPageCompoundingWallet')}</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number(sumOfCompoundingBalance) > 0 ? Math.round(sumOfCompoundingBalance) : 0 }</h4>
            {/* <Link style={{position:'absolute',bottom:'-10px'}} to='/chooseTransfers'> */}
            {/* <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>Transfer to Main Wallet <FaArrowRight /> </button> */}
            {/* </Link> */}
        </div>
        <div className={styles.eachWallet}>
            <h1 className='text-lg font-bold text-center'>{t('walletsPageReferralWallet')}</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number(fetchedUserInfo?.referralCommissionWalletTotal) > 0 ? Math.round(fetchedUserInfo?.referralCommissionWalletTotal) : 0 }</h4>
            <Link style={{position:'absolute',bottom:'-10px'}} to='/transferToWallet'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>{t('walletsPageTransferToMain')} <FaArrowRight /> </button>
            </Link>
        </div>
        <div className={styles.eachWallet}>
            <h1 className='text-lg font-bold text-center'>{t('walletsPageDownlineWallet')}</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number(fetchedUserInfo?.downlineCommissionWalletTotal) > 0 ? Math.round(fetchedUserInfo?.downlineCommissionWalletTotal) : 0 }</h4>
            <Link style={{position:'absolute',bottom:'-10px'}} to='/transferToDownline'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>{t('walletsPageTransferToDownline')} <FaArrowRight /> </button>
            </Link>
        </div>
        <div className={styles.eachWallet}>
            <h1 className='text-lg font-bold'>{t('walletsPageBonusWallet')}</h1>
            <FaWallet className='text-4xl text-sky-800' />
            <h4 className='text-3xl'>${Number(fetchedUserInfo?.bonusWalletTotal) > 0 ? Math.round(fetchedUserInfo?.bonusWalletTotal) : 0 }</h4>
            <Link  style={{position:'absolute',bottom:'-10px'}} to='/transferToWallet'>
            <button className='flex items-center gap-2 text-sm bg-green-200 p-2 hover:bg-white hover:text-sky-800'>{t('walletsPageTransferToMain')}<FaArrowRight /> </button>
            </Link>
        </div>
       

      </div>
    </div>
  )
}

export default AllWallets
