import React, { useState } from 'react'
import './faqComponent.css'
import { BiSolidDownArrow } from 'react-icons/bi'
import { TiArrowSortedUp } from "react-icons/ti";
import { useTranslation } from 'react-i18next'


const FaqComponent = () => {
    const [showFaqOne, setShowFaqOne] = useState({})
    const [isLoading, setIsLoading] = useState(true);

    const {t} = useTranslation()


const faqArray = [
    {
        id: 1,
        question: `${t('faqQuestion1')}`,
        answer: `${t('faqAnswer1')}`,
    },
    {
        id: 2,
        question: `${t('faqQuestion2')}`,
        answer: `${t('faqAnswer2')}`,
    },
    {
        id: 3,
        question: `${t('faqQuestion3')}`,
        answer: `${t('faqAnswer3')}`,
    },
    {
        id: 4,
        question: `${t('faqQuestion4')}`,
        answer: `${t('faqAnswer4')}`,
    },
    {
        id: 5,
        question: `${t('faqQuestion5')}`,
        answer: `${t('faqAnswer5')}`,
    },
    {
        id: 6,
        question: `${t('faqQuestion6')}`,
        answer: `${t('faqAnswer6')}`,
    },
    {
        id: 7,
        question: `${t('faqQuestion7')}`,
        answer: `${t('faqAnswer7')}`,
      },
      {
        id: 8,
        question: `${t('faqQuestion8')}`,
        answer: `${t('faqAnswer8')}`,
      },
      {
        id: 9,
        question: `${t('faqQuestion9')}`,
        answer: `${t('faqAnswer9')}`,
      },
      {
        id: 10,
        question: `${t('faqQuestion10')}`,
        answer: `${t('faqAnswer10')}`,
      },
      {
        id: 11,
        question: `${t('faqQuestion11')}`,
        answer: `${t('faqAnswer11')}`,
      },
      {
        id: 12,
        question: `${t('faqQuestion12')}`,
        answer: `${t('faqAnswer12')}`,
      },
      {
        id: 13,
        question: `${t('faqQuestion13')}`,
        answer: `${t('faqAnswer13')}`,
      },
      {
        id: 14,
        question: `${t('faqQuestion14')}`,
        answer: `${t('faqAnswer14')}`,
      },
      {
        id: 15,
        question: `${t('faqQuestion15')}`,
        answer: `${t('faqAnswer15')}`,
      },
      {
        id: 16,
        question: `${t('faqQuestion16')}`,
        answer: `${t('faqAnswer16')}`,
      },
      {
        id: 17,
        question: `${t('faqQuestion17')}`,
        answer: `${t('faqAnswer17')}`,
      },
      {
        id: 18,
        question: `${t('faqQuestion18')}`,
        answer: `${t('faqAnswer18')}`,
      },
      {
        id: 19,
        question: `${t('faqQuestion19')}`,
        answer: `${t('faqAnswer19')}`,
      },
      {
        id: 20,
        question: `${t('faqQuestion20')}`,
        answer: `${t('faqAnswer20')}`,
      },
      {
        id: 21,
        question: `${t('faqQuestion21')}`,
        answer: `${t('faqAnswer21')}`,
      },
      {
        id: 22,
        question: `${t('faqQuestion22')}`,
        answer: `${t('faqAnswer22')}`,
      },
      {
        id: 23,
        question: `${t('faqQuestion23')}`,
        answer: `${t('faqAnswer23')}`,
      },
      {
        id: 24,
        question: `${t('faqQuestion24')}`,
        answer: `${t('faqAnswer24')}`,
      },
      {
        id: 25,
        question: `${t('faqQuestion25')}`,
        answer: `${t('faqAnswer25')}`,
      },
     
      {
        id: 26,
        question: `${t('faqQuestion26')}`,
        answer: `${t('faqAnswer26')}`,
      },
      {
        id: 27,
        question: `${t('faqQuestion27')}`,
        answer: `${t('faqAnswer27')}`,
      },
      {
        id: 28,
        question: `${t('faqQuestion28')}`,
        answer: `${t('faqAnswer28')}`,
      },
      {
        id: 29,
        question: `${t('faqQuestion29')}`,
        answer: `${t('faqAnswer29')}`,
      },
      {
        id: 30,
        question: `${t('faqQuestion30')}`,
        answer: `${t('faqAnswer30')}`,
      },
      {
        id: 31,
        question: `${t('faqQuestion31')}`,
        answer: `${t('faqAnswer31')}`,
      },
      {
        id: 32,
        question: `${t('faqQuestion32')}`,
        answer: `${t('faqAnswer32')}`,
      },
      {
        id: 33,
        question: `${t('faqQuestion33')}`,
        answer: `${t('faqAnswer33')}`,
      },
      {
        id: 34,
        question: `${t('faqQuestion34')}`,
        answer: `${t('faqAnswer34')}`,
      },
      {
        id: 35,
        question: `${t('faqQuestion35')}`,
        answer: `${t('faqAnswer35')}`,
      },
      {
        id: 36,
        question: `${t('faqQuestion36')}`,
        answer: `${t('faqAnswer36')}`,
      },
      {
        id: 37,
        question: `${t('faqQuestion37')}`,
        answer: `${t('faqAnswer37')}`,
      },
      {
        id: 38,
        question: `${t('faqQuestion38')}`,
        answer: `${t('faqAnswer38')}`,
      },
      {
        id: 39,
        question: `${t('faqQuestion39')}`,
        answer: `${t('faqAnswer39')}`,
      },
      {
        id: 40,
        question: `${t('faqQuestion40')}`,
        answer: `${t('faqAnswer40')}`,
      },
      {
        id: 41,
        question: `${t('faqQuestion41')}`,
        answer: `${t('faqAnswer41')}`,
      },
      {
        id: 42,
        question: `${t('faqQuestion42')}`,
        answer: `${t('faqAnswer42')}`,
      },
      {
        id: 43,
        question: `${t('faqQuestion43')}`,
        answer: `${t('faqAnswer43')}`,
      },
      {
        id: 44,
        question: `${t('faqQuestion44')}`,
        answer: `${t('faqAnswer44')}`,
      },
      {
        id: 45,
        question: `${t('faqQuestion45')}`,
        answer: `${t('faqAnswer45')}`,
      },
      {
        id: 46,
        question: `${t('faqQuestion46')}`,
        answer: `${t('faqAnswer46')}`,
      },
      {
        id: 47,
        question: `${t('faqQuestion47')}`,
        answer: `${t('faqAnswer47')}`,
      },
      {
        id: 48,
        question: `${t('faqQuestion48')}`,
        answer: `${t('faqAnswer48')}`,
      },
      {
        id: 49,
        question: `${t('faqQuestion49')}`,
        answer: `${t('faqAnswer49')}`,
      },
      {
        id: 50,
        question: `${t('faqQuestion50')}`,
        answer: `${t('faqAnswer50')}`,
      },
      {
        id: 60,
        question: `${t('faqQuestion51')}`,
        answer: `${t('faqAnswer51')}`,
      },
      {
        id: 51,
        question: `${t('faqQuestion52')}`,
        answer: `${t('faqAnswer52')}`,
      },
      {
        id: 52,
        question: `${t('faqQuestion53')}`,
        answer: `${t('faqAnswer53')}`,
      },
      {
        id: 53,
        question: `${t('faqQuestion54')}`,
        answer: `${t('faqAnswer54')}`,
      },
      {
        id: 54,
        question: `${t('faqQuestion55')}`,
        answer: `${t('faqAnswer55')}`,
      },
      {
        id: 55,
        question: `${t('faqQuestion56')}`,
        answer: `${t('faqAnswer56')}`,
      },
      {
        id: 56,
        question: `${t('faqQuestion57')}`,
        answer: `${t('faqAnswer57')}`,
      },
      {
        id: 57,
        question: `${t('faqQuestion58')}`,
        answer: `${t('faqAnswer58')}`,
      },
      {
        id: 58,
        question: `${t('faqQuestion59')}`,
        answer: `${t('faqAnswer59')}`,
      },
      {
        id: 59,
        question: `${t('faqQuestion60')}`,
        answer: `${t('faqAnswer60')}`,
      },
];



    const handleClickOne = (id) => {
        // Toggle the state for the clicked ID
        setShowFaqOne((prevState) => ({
          ...prevState,
          [id]: !prevState[id]  // If it's undefined, set it to true (open)
        }));
      };

    

  return (
    <div>
      <h1 style={{margin:'100px auto'}} className='text-2xl p-2 font-bold bg-green-900 text-white w-max text-center'>{t('faqTitle')}</h1>
      {
                faqArray.map(faq=>(
                    <div key={faq.id} className="question-container">

                                     <div className="faq-top" onClick={() => handleClickOne(faq.id)}>
                                        <h2 className='text-green-900'>{faq.question}</h2>
                                        { showFaqOne[faq.id] 
                                        ? <TiArrowSortedUp  style={{ color:'green'}} ></TiArrowSortedUp> 
                                        : <BiSolidDownArrow style={{ color:'green'}} ></BiSolidDownArrow> 
                                        }
                                    </div>
                                    {showFaqOne[faq.id] && <div className="faq-bottom">
                                        <p>{faq.answer}</p>
                                    </div>}

                                        </div>

                ))
            }

    </div>
  )
}

export default FaqComponent
