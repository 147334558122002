import React from 'react'
import './conferenceOneComponent.css'
import conferenceBg from '../assets/conferenceBg.jpg'
import conferenceImg from '../assets/conferenceImg.jpg'

const ConferenceOneComponent = () => {
  return (
    <div className='conferenceContainer'>
      <div className='conferenceBGContainer'>
        <div className='conferenceBGContainer-linear'></div>
        <img src={conferenceBg} alt="Conference Image" />


        <div className='conferenceContent'>

            <div className='conferenceContentImage'>
            <img src={conferenceImg} alt="Conference Image" />
            </div>

            <div className='conferenceContentDetails'>
            <h1 className='bg-white p-2 text-sm font-bold m-2 w-fit'>Immigration Companies Conference Oregon 2022</h1>
            <p className='p-2'>Crownstreet Holdings, a prominent player in the immigration sector with our Global citizenship investment programmes (GCIP), made a significant presence at the Immigration Companies Conference 2022. This prestigious event brought together industry leaders, experts, and stakeholders to discuss the latest trends, challenges, and opportunities in the field of immigration. Crownstreet's participation showcased their commitment to providing comprehensive Citizenship solutions  and our dedication to fostering international connections.</p>
            </div>

            </div>

      </div>

     

    

    </div>
  )
}

export default ConferenceOneComponent
