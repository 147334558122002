import React from 'react';
import './whyUs.css'
import { useTranslation } from 'react-i18next'

const WhyUs = () => {
  const {t} = useTranslation()
  return (
    <div className='why-us-container'>
        <h1>{t('whyCrown')}</h1>
        <p>{t('whyCrownDe')}</p>
      
    </div>
  )
}

export default WhyUs
