import React from 'react'
import './philosophyDetails.css'
import globeImage from '../../assets/globe.jpg'
import multidi from '../../assets/multidi.jpg'
import land from '../../assets/land.jpg'
import risk from '../../assets/risk.jpg'
import efficient from '../../assets/efficient.png'
import { useTranslation } from 'react-i18next'

const PhilosophyDetails = () => {
  const {t} = useTranslation()

  // {t('philosophyPageHero')}
  return ( 
    <div className='philosophy-details-container'>
      <div className="philosophy-details-inner">
        <img src={globeImage} alt="globe-image" />
        <p>{t('philosophyPageParaOne')}</p>
      </div>
      <p className='philosophy-second-paragraph'> {t('philosophyPageParaTwo')}</p>

        <div className='philosophy-middle-container'>
          <h1>{t('philosophyPageSubtitle')}</h1>
            <p>{t('philosophyPageParaThree')}</p>
            <div className="investment-philosophies">

         
              <div className="each-investment-philosophy">
                <img src={efficient} alt="image" />
               <div>
               <h3>{t('philosophyPageSubInnerTitle')}</h3>
               <p>{t('philosophyPageParaInnerOne')}</p>
               </div>
              </div>

              <div className="each-investment-philosophy">
                <img src={land} alt="image" />
               <div>
               <h3>{t('philosophyPageSubInnerTitleTwo')}</h3>
               <p>{t('philosophyPageParaInnerTwo')} </p>
               </div>
              </div>

              <div className="each-investment-philosophy">
                <img src={multidi} alt="image" />
               <div>
               <h3>{t('philosophyPageSubInnerTitleThree')}</h3>
               <p>{t('philosophyPageParaInnerThree')}</p>
               </div>
              </div>


              <div className="each-investment-philosophy">
                <img src={risk} alt="image" />
               <div>
               <h3>{t('philosophyPageSubInnerTitleFour')}</h3>
               <p>{t('philosophyPageParaInnerFour')}</p>
               </div>
              </div>


            </div>
        </div>
    </div>
  )
}

export default PhilosophyDetails
