import React, { useEffect, useState } from 'react'
import styles from './dashboardWithdrawal.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import Loader from './Loader'
import withdrawalImage from '../assets/withdrawal.jpg'
import { selectUserInfo } from '../slices/authSlice';
import { useSelector } from 'react-redux'
import {setFetchedUserInfo, selectFetchedUserInfo } from '../slices/userSlice'
import { useTranslation } from 'react-i18next'
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom'


const DashboardWithdrawal = () => {
    const [showModal, setShowModal] = useState(false)
    const [amount, setAmount] = useState(0)
    const [walletAddress, setWalletAddress] = useState('')
    const [walletType, setWalletType] = useState('')
    const userInfo = useSelector(selectUserInfo)
    const [popUp, setPopUp] = useState('')
    const [showPopupModal, setShowPopupModal] = useState(false)
    const fetchedUser = useSelector(selectFetchedUserInfo)

    const [showOTPModal, setShowOTPModal] = useState(false)
    const [otp, setOtp] = useState('');

    const [withdrawalOtp, setWithdrawalOtp] = useState('')
    const [withdrawalID, setWithdrawalID] = useState('')

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

    const {t} = useTranslation()
    const navigate = useNavigate()


    // const handleShowPasswordForm =(e)=>{
    //   e.preventDefault()
    //   setShowModal(true)
    // }
    const [loading, setLoading] = useState(true);

    useEffect(() => {
     
      setTimeout(() => {
        setLoading(false);
      }, 3000); 
    }, []); 


  

    const handleWithdrawalSubmit=async(e)=>{
      e.preventDefault()
      console.log(amount, walletAddress, walletType)

      if(!amount || !walletAddress || !walletType) {
        setPopUp(`${t('forgetPasswordPasswordPopup')}`)
        setShowPopupModal(true)
        return
      }

      if(fetchedUser?.mainWallet < amount){
        setPopUp(`${t('dashboardWithdrawalPopup1')}`)
        setShowPopupModal(true)
        return
      }

      if(!fetchedUser?.phone && !fetchedUser?.validID?.url){
        setPopUp(`${t('dashboardWithdrawalPopup2')}`)
        setShowPopupModal(true)
        return
      }

     
  
     setLoading(true)

     var myHeaders = new Headers();
     myHeaders.append("Authorization", `Bearer ${userInfo.token}`);


      var formdata = new FormData();
      formdata.append("amount", amount);
      formdata.append("walletAddress", walletAddress);
      formdata.append("walletType", walletType);
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(`${API_ENDPOINT}/api/withdrawal/initialize-withdrawal`, requestOptions)
        .then(response => response.json())
        .then(result =>{
            console.log(result)
            if(result.message === 'Withdrawal successful'){
              setWithdrawalOtp(result.withdrawal.token)
              setShowOTPModal(true)
              setWithdrawalID(result.withdrawal._id)
             
              const form = e.target;
                form.reset()
                setLoading(false)
                return
            } else {
              setPopUp(`${t('dashboardWithdrawalPopup4')}`)
              setShowPopupModal(true)
              setLoading(false)
              return
            }
           
       
        })
        .catch(error => console.log('error', error));
  
    }


  const handleChangeOtp = async(value) => {
    // console.log(value);
    setOtp(value)
      // Ensure that the latest value is used
      if (value.length === 4) {
        // verifyToken(prevOtp);
       
        setLoading(true)
        // console.log(value)
        if(Number(value) === withdrawalOtp){

          console.log('tokens match')

              var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userInfo.token}`);


             var requestOptions = {
      method: 'PATCH',
      headers: myHeaders,
      // body: formdata,
      redirect: 'follow'
    };
    
    fetch(`${API_ENDPOINT}/api/withdrawal/make-withdrawal/${withdrawalID}`, requestOptions)
      .then(response => response.json())
      .then(result =>{
        console.log(result)
        if(result.message === 'Withdrawal successful' ){
          setLoading(false)
           console.log(result)
        setPopUp(`${t('dashboardWithdrawalPopup3')}`)
              setShowPopupModal(true)
           

           setTimeout(()=> navigate('/dashboard'),3000)
        }
        else {
          setPopUp(`${t('dashboardWithdrawalPopup4')}`)
          setShowPopupModal(true)
        }
       setLoading(false)
      })
      .catch(error => console.log('error', error));


         
          // console.log(value)
        }else{
          setPopUp(`${t('LoginResponse3A')}`)
          setShowPopupModal(true)
          setLoading(false)
          return
        }
        setLoading(false)

      }
      return value;

  };




  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }
  

    
  return (
    <div style={{width:'100%',display:'flex', alignItems:'center', justifyContent:'center', paddingTop:100, margin:'50px auto'}}>
  {loading ? <Loader /> : null}

    {/* popup */}
    { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}
    <div className={styles.downlineTransferContainer}>

      <div className={styles.left}>
        <img  src={withdrawalImage} alt="image" />
      </div>

    <form onSubmit={handleWithdrawalSubmit} className={styles.right}>
          {/* <h1 className='text-4xl font-bold pb-6 pt-2 text-center'>Withdrawal</h1> */}
          {/* <label className={styles.emailText} htmlFor="email">Enter Amount</label> */}
          <input 
          onChange={(e)=>setAmount(e.target.value)}
          style={{ height:'50px', width:'100%',outline:'none'}} placeholder={t('purchasePortfolioPageAmount')} className='bg-sky-100 mt-2 p-2' type="number" name='amount' />
          <input
          onChange={(e)=>setWalletAddress(e.target.value)}
          style={{ height:'50px', width:'100%',outline:'none'}} placeholder={t('dashboardWithdrawalWalletAddress')} className='bg-sky-100 mt-2 p-2' type="text" name='address' />
          <input 
          onChange={(e)=>setWalletType(e.target.value)}
          style={{ height:'50px', width:'100%',outline:'none'}} placeholder={t('dashboardWithdrawalWalletType')} className='bg-sky-100 mt-2 p-2 mb-5' type="text" name='crypto' />
         
          <button  className={styles.glowOnHover} type="submit">{t('dashboardWithdrawalButton')}</button>
        </form>

    </div>
     
 

    {showOTPModal && <div className={styles.modal}>
    
    <div style={{marginTop:200}} className='flex flex-col gap-3 my-11 bg-sky-100/50 mx-11 p-7 items-center backdrop-filter backdrop-blur-lg bg-opacity-30'>
    
    
        <h2 className='text-2xl mb-7 text-center font-bold text-white'>{t('forgetPasswordVerifyTokenTitle')}</h2>
        <OtpInput
          containerStyle={{ margin: 20 }}
          inputStyle={{ width: 60, height: 60, borderRadius: 10, margin: 10, backgroundColor:'white' }}
          shouldAutoFocus
          value={otp}
          inputType="tel"
          onChange={handleChangeOtp}
          numInputs={4}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          // renderInput={(props, index) => (
          //   <input
          //     {...props}
          //     onFocus={(e) => {
          //       // Move cursor to the end on focus
          //       e.target.setSelectionRange(e.target.value.length, e.target.value.length);
          //     }}
          //   />
          // )}
        />
    
        </div>
    
    </div> 
        }

 
     
    </div>
  )
}

export default DashboardWithdrawal
