import React, { useState } from 'react'
import styles from './transfersColumn.module.css'
import { FaRegTimesCircle } from 'react-icons/fa'
import techHandImage from '../assets/transferImg.jpg'
import {useLocation, useNavigate} from 'react-router-dom'
import Loader from './Loader'
import { Player } from '@lottiefiles/react-lottie-player';
import inputSRC from '../assets/loadingAnimation.json'
import { useTranslation } from 'react-i18next'

const TransferToDownlineColumn = () => {
  const [showModal, setShowModal] = useState(false)
  const [destinationEmail, setDestinationEmail] = useState('');
  const navigate = useNavigate()
  const [popUp, setPopUp] = useState('')
  const [showPopupModal, setShowPopupModal] = useState(false)
  const [emailSpinner, setEmailSpinner] = useState(false)
  const [loading, setLoading] = useState(false)
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'

  const {t} = useTranslation()


  const handleChange = (event) => {
    setDestinationEmail(event.target.value);
    setEmailSpinner(true)
    console.log(destinationEmail)
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission with the selectedOption value
   
   if(destinationEmail){
    var formdata = new FormData();
  formdata.append("email", destinationEmail);
  
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };

  fetch(`${API_ENDPOINT}/api/auth/verify-email`, requestOptions)
    .then(response => response.json())
    .then(result => {
      console.log(result.message)
      if(result.message === 'Email is available'){

        // setError('Email does not exist.')
        setPopUp(`${t('transferToDownlinePop1')}`)
        setShowPopupModal(true)
        return
        
       
      }else if(result.message === 'Email already taken!'){
        // setShowModal(true)
        navigate('/transferToDownlineAmount',{state:{destinationEmail: destinationEmail}})

      }
      setLoading(false)
      console.log(result)})
    .catch(error => console.log('error', error))
    
    
    setLoading(false)

   
   } else {
    setPopUp(`${t('transferToDownlinePop2')}`)
    setShowPopupModal(true)
   }
  
  };
  
  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }
  
  return (
    <div className={styles.transferImagesContainer}>

       {loading ? <Loader /> : null}
           {/* popup */}
   { showPopupModal && 
   <div className={styles.popContainer}>
       
        <div className={styles.innerPopContainer}>
            < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className={styles.popClose} />
             <p className={styles.popText}>{popUp}</p>
            <button className={styles.popButton} onClick={handleClosePopup}>Ok</button>
        </div>
      
    
    </div>
        }
   {/* popup ends */}

<img
        src={techHandImage}
        alt='welcome image'
        quality={100}
       className={styles.transferHeroImage}

        />
        <div className={styles.transferContainer}>

        {/* <div style={{maxHeight:'max-content'}} className='flex gap-3 my-11 bg-sky-100/50 mx-11 p-5 items-center  backdrop-filter backdrop-blur-lg bg-opacity-50'> */}
        <div style={{maxHeight:'max-content'}} className='flex gap-3 my-11 mx-11 p-5 items-center '>

<form style={{width:'300px', margin:'auto'}} className='flex flex-col gap-3 ' action="">
  <h1 className='text-2xl font-bold pb-6 pt-2 text-center text-white sm:text-4xl'>{t('walletsPageTransferToDownline')}</h1>
  {/* <label className={styles.emailText} htmlFor="email">Destination Email</label> */}
  <div className={styles.inputContainer}>
          <input
             onChange={handleChange}
           style={{backgroundColor:'white', height:'40px', width:'100%',paddingLeft:10, outline:'none'}}
          placeholder={t('transferFromWalletDestinationEmail')}
           type="email" name='email' />
              {emailSpinner && <div>
            <Player
          src={inputSRC}
          className="player"
          loop
          autoplay
           style={{ height: '40px', width: '40px', position:'absolute',bottom:'0px', right:'5px' }}
          
          /> 
            </div>}

            </div>
<button onClick={handleSubmit} className={styles.loginButton} type="submit">{t('purchasePortfolioPageButton')}</button>

</form>
</div>



</div>
</div>
  )
}

export default TransferToDownlineColumn
