import React, { useEffect, useState } from 'react';
import './sidebar.css';
import logo from '../../assets/crownstreetlogo.png'
import { BiSolidDashboard } from 'react-icons/bi'
import { BiSolidBank } from 'react-icons/bi'
import { BiSolidNetworkChart } from 'react-icons/bi'
import { BiSolidRightArrow } from 'react-icons/bi'
import { BiSolidDownArrow } from 'react-icons/bi'
import { BsFingerprint } from 'react-icons/bs'
import { BsFillBoxFill } from 'react-icons/bs'
import { BiMoneyWithdraw } from 'react-icons/bi'
import { SiWebmoney } from 'react-icons/si'
import { AiFillSetting } from 'react-icons/ai'
import { BsFillSendFill } from 'react-icons/bs'
import { CgProfile } from 'react-icons/cg'
import robot from '../../assets/crownstreetmen.png'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import copy from "copy-to-clipboard";
import { IoMdHome } from "react-icons/io"
import { FaRegTimesCircle } from 'react-icons/fa'
import { Player } from '@lottiefiles/react-lottie-player';
import loaderSRC from '../../assets/robotAnimation.json'
import { selectUserInfo } from '../../slices/authSlice';
import { useSelector, useDispatch } from 'react-redux'
import { BiMenu } from 'react-icons/bi';
import { disableAnimation, enableAnimation } from '../../slices/navbarAnimationSlice';
import { useTranslation } from 'react-i18next'


const Sidebar = () => {
  const [openAuth, setOpenAuth] = useState(false)
  const [openReferral, setOpenReferral] = useState(false)
  const [openWithdrawal, setOpenWithdrawal] = useState(false)
  const [openPortfolio, setOpenPortfolio] = useState(false)
  const navigate = useNavigate()
  const [popUp, setPopUp] = useState('')
  const [showPopupModal, setShowPopupModal] = useState(false)
  const dispatch = useDispatch()
const userInfo = useSelector(selectUserInfo)
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://api-panel.crownstreetholdings.com'



const {t} = useTranslation()


  const handleClosePopup =()=>{
    setShowPopupModal(false)
     setPopUp('')
    }

  const copyToClipboard = () => {
    copy(userInfo.user.referral_code);
    // alert(`You have copied "${user.user.referral_code}"`);
    setPopUp(`${t('dashboardSidebarReferralCodePopup')} "${userInfo.user.referral_code}"`)
    setShowPopupModal(true)
    setIsSidebarOpen(false);
};

const copyReferralLinkToClipboard = () => {
  copy(`https://crownstreetholdings.com/register/referral/${userInfo.user.referral_code}`);
  // copy(`${API_ENDPOINT}/api/auth/register/${userInfo.user.referral_code}`);
  // alert(`You have copied "${user.user.referral_code}"`);
  setPopUp(`${t('dashboardSidebarReferralLinkPopup')}`)
  setShowPopupModal(true)
  setIsSidebarOpen(false);
};
 

const handleLogout=async()=>{


  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userInfo.token}`);


  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  fetch(`${API_ENDPOINT}/api/auth/logout`, requestOptions)
    .then(response => response.json())
    .then(result =>{
      // console.log(result)
      if(result.msg === 'user logged out successfully.!'){
        // dispatch({type:"LOGOUT"});

        if (localStorage.getItem("user")) {
          localStorage.removeItem("user");
        }
         navigate('/login')
        //  console.log(result)
      }else{
        console.log('error loging out, try again')
      }
     
    })
    .catch(error => console.log('error', error));

 

}


const CustomNavLink = ({ to, children }) => {
  const location = useLocation();

  // Check if the current path matches the 'to' prop
  const isActive = location.pathname === to;

  return (
    <li className={`flex gap-3 ${isActive ? 'active-link' : ''}`}>
      <Link to={to} className='flex gap-3'>
        {children}
      </Link>
    </li>
  );
};

const [isSidebarOpen, setIsSidebarOpen] = useState(false);


useEffect(()=>{
   window.innerWidth > 700 ? dispatch(enableAnimation())   : dispatch(disableAnimation());
},[])

const handleToggleSidebar = () => {
  setIsSidebarOpen(!isSidebarOpen);

  isSidebarOpen === true ?  dispatch(enableAnimation()) :  dispatch(disableAnimation());
   if( window.innerWidth > 700 ) dispatch(enableAnimation())    
};


  
  // if (screenSize <= 900) setActiveMenu(true)
  return (
    <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>

         {/* popup */}
     { showPopupModal && 
      <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', margin:'auto'}} className='popContainer'>
          
           <div className='innerPopContainer'>
               < FaRegTimesCircle onClick={()=>setShowPopupModal(false)} className='popClose' />
                <p className='popText'>{popUp}</p>
               <button className='popButton' onClick={handleClosePopup}>Ok</button>
           </div>
         
       
       </div>
           }
      {/* popup ends */}

    {/* Add a menu button */}
    <div className="menu-button" >
     { !isSidebarOpen  ? <BiMenu onClick={handleToggleSidebar} style={{ color: 'green', fontSize: 30 }} /> : <FaRegTimesCircle onClick={handleToggleSidebar}  style={{ color: 'green', fontSize: 30 }}/>}
    </div>

    {/* Rest of your existing code */}
    {/* Ensure the rest of your content is inside the sidebar div */}
    <div className='top'>
      <Link to='/' style={{ textDecoration: 'none' }}>
        <img style={{ width: '220px', height: '150px', objectFit: 'contain' }} src={logo} alt='logo' />
      </Link>
    </div>
    <hr />
      <div className='center'>
      <ul style={{height:'80vh'}} className=" text-base-content backdrop-filter backdrop-blur-lg bg-opacity-30">
        {/* Sidebar content here */}

        <CustomNavLink to='/'>
          <IoMdHome style={{ color: 'rgb(3, 88, 21)', fontSize: 23 }} /> {t('dashboardSidebarHome')}
        </CustomNavLink>

        <CustomNavLink to='/dashboard'>
        <BiSolidDashboard style={{ color: 'rgb(3, 88, 21)', fontSize: 23 }} /> {t('dashboardSidebarDashboard')}
      </CustomNavLink>

        <li className='flex gap-4 ' >
          <div 
          // onMouseOver={()=>setOpenPortfolio(true)}
          onClick={()=>setOpenPortfolio(!openPortfolio)}
           className='flex justify-between items-center gap-3'>

          <div className='flex gap-3 items-center'  > 
        <BiMoneyWithdraw  style={{color:'rgb(3, 88, 21)', fontSize:23}} /> {t('dashboardSidebarPortfolio')} </div>
        {!openPortfolio ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>

         { openPortfolio &&  <div className='bg-green-400 pl-7 backdrop-filter backdrop-blur-lg bg-opacity-10'>
         <CustomNavLink to='/portfolios'>
         {t('dashboardSidebarPurchasePortfolio')}
         </CustomNavLink>
         <CustomNavLink to='/activePortfolios'>
         {t('dashboardSidebarActivePortfolios')}
         </CustomNavLink>
         <CustomNavLink to='/expiredPortfolios'>
         {t('dashboardSidebarExpiredPortfolios')}
         </CustomNavLink>
         <CustomNavLink to='/transferPortfolios'>
           Transfer Portfolio
         </CustomNavLink>
         </div>}
       

        <li className='flex gap-4 ' >
          <div
          // onMouseOver={()=>setOpenWithdrawal(true)}
          onClick={()=>setOpenWithdrawal(!openWithdrawal)} 
          className='flex justify-between items-center gap-3'>

          <div className='flex gap-3 items-center'  > 
        <BiMoneyWithdraw  style={{color:'rgb(3, 88, 21)', fontSize:23}} />{t('dashboardSidebarWithdrawal')}   </div>
        {!openWithdrawal ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>

         { openWithdrawal &&  <div className='bg-green-400 pl-7 backdrop-filter backdrop-blur-lg bg-opacity-10'>
         <CustomNavLink to='/withdrawal'>
         {t('dashboardSidebarInitiateWithdrawal')}
         </CustomNavLink>
         <CustomNavLink to='/withdrawalHistory'>
         {t('dashboardSidebarWithdrawalHistory')}
         </CustomNavLink>
         </div>}

         <CustomNavLink to='/accountActivities'>  <BsFillBoxFill style={{color:'rgb(3, 88, 21)', fontSize:23}} /> {t('dashboardSidebarAccountActivities')} </CustomNavLink>
         <CustomNavLink to='/chooseTransfers'> <BsFillSendFill style={{color:'rgb(3, 88, 21)', fontSize:23}} /> {t('dashboardSidebarTransfers')}  </CustomNavLink>
         <CustomNavLink to='/profile'>  <CgProfile style={{color:'rgb(3, 88, 21)', fontSize:23}} /> {t('dashboardSidebarProfile')}  </CustomNavLink>
         <CustomNavLink to='/loan'>  <CgProfile style={{color:'rgb(3, 88, 21)', fontSize:23}} /> {t('dashboardSidebarLoan')}  </CustomNavLink>


        <li className='flex gap-4 ' >
          <div
          // onMouseOver={()=>setOpenReferral(true)}
          onClick={()=>setOpenReferral(!openReferral)}
           className='flex justify-between items-center gap-3'>

          <div className='flex gap-3 items-center'  > 
        <BiSolidNetworkChart  style={{color:'rgb(3, 88, 21)', fontSize:23}} /> {t('dashboardSidebarReferrals')}   </div>
        {!openReferral ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>

         { openReferral &&  <div className='bg-green-400 pl-7 backdrop-filter backdrop-blur-lg bg-opacity-10'>
         <CustomNavLink to='/referal'>  {t('dashboardSidebarAllReferrals')}  </CustomNavLink>
         {/* <CustomNavLink to='/referralBonus'>  Referral Bonus</CustomNavLink> */}
        <li  onClick={copyToClipboard} className='flex gap-4 ' ><div className='flex gap-3'  to='/referal'>  {t('dashboardSidebarReferralCode')} </div> </li>
        <li onClick={copyReferralLinkToClipboard} className='flex gap-4 ' ><div  className='flex gap-3'  to='/referal'>  {t('dashboardSidebarReferralLink')}  </div> </li>
         </div>}
        <li className='flex gap-4 ' >
          <div 
          // onMouseOver={()=>setOpenAuth(true)}
          onClick={()=>setOpenAuth(!openAuth)}
           className='flex justify-between items-center gap-3'>

          <div className='flex gap-3 items-center'  > 
        <BsFingerprint style={{color:'rgb(3, 88, 21)', fontSize:23}} /> {t('dashboardSidebarAuthentication')}   </div>
        {!openAuth ? <BiSolidRightArrow /> : <BiSolidDownArrow />}

          </div>
         
         </li>
       { openAuth &&  <div className='bg-green-400 pl-7 backdrop-filter backdrop-blur-lg bg-opacity-10'>
       <CustomNavLink to='/twoFA'>  {t('dashboardSidebar2FA')}  </CustomNavLink>
       <CustomNavLink to='/updatePassword'>  {t('dashboardSidebarUpdatePassword')}   </CustomNavLink>
         </div>}

        <li onClick={handleLogout} className='flex gap-4 ' ><div className='flex gap-3'  > <AiFillSetting style={{color:'rgb(3, 88, 21)', fontSize:23}} /> {t('dashboardSidebarLogout')}  </div> </li>
      
         
      </ul>
      </div> 
      

   

    </div>
  )
}

export default Sidebar
