
import './philosophy.css'
import Footer from '../components/footer/Footer'
import Navbar from '../components/navbar/Navbar'
import PageColumn from '../components/pageColumn/PageColumn'
import PhilosophyDetails from '../components/philosophyDetails/PhilosophyDetails'
import TopTag from '../components/TopTag'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react';


const Philosophy = () => {
  const {t} = useTranslation()

  useEffect(() => { 
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);


  return (
    <>
      <TopTag />
      <Navbar />
      <div className="philosophy-container">
      <PageColumn highlight={t('philosophyPageHero')} />

      <div className="philosophy-inner-container">
      <PhilosophyDetails/>
      </div>
     
      </div>
     

      <Footer />
    </>
  )
}

export default Philosophy
